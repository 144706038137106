<template>
  <div :class="$t('ManagingYourPortfolio.box-name')">
	<page-navigate></page-navigate>
  <div :class="`${$t('TradingView.tradingView.tvlang')}-font-family`" style="position: relative">
      <section class="tp-new-section1">
        <h1 :class="`tp-new-section1-title ${$t('TradingView.tradingView.tvlang')}-font-family-title`" v-html="$t('ManagingYourPortfolio.screen0.title')"></h1>
        <p class="tp-new-section1-intro">
          {{$t('ManagingYourPortfolio.screen0.desc')}}
        </p>
        <p class="tp-new-section1-line"></p>
      </section>
      <section class="tp-new-section2">  
    <a  
      href="javascript:;"  
      id="tradingproduct-subnav-1"  
      class="button-default sub-nav"  
      :class="{ active: activeIndex === 0 }"  
      @click="handleNavClick(0)"  
    >  
      {{$t('ManagingYourPortfolio.screen1.link1')}}  
    </a>  
    <a  
      href="javascript:;"  
      id="tradingproduct-subnav-2"  
      class="button-default sub-nav"  
      :class="{ active: activeIndex === 1 }"  
      @click="handleNavClick(1)"  
    >  
      {{$t('ManagingYourPortfolio.screen1.link2')}}  
    </a>  
    <a  
      href="javascript:;"  
      id="tradingproduct-subnav-3"  
      class="button-default sub-nav"  
      :class="{ active: activeIndex === 2 }"  
      @click="handleNavClick(2)"  
    >  
      {{$t('ManagingYourPortfolio.screen1.link3')}}  
    </a>  
    <a  
      href="javascript:;"  
      id="tradingproduct-subnav-4"  
      class="button-default sub-nav"  
      :class="{ active: activeIndex === 3 }"  
      @click="handleNavClick(3)"  
    >  
      {{$t('ManagingYourPortfolio.screen1.link4')}}  
    </a>  
  </section>
      <section class="tp-new-section3" data-section="tradingproduct-subnav-1">
        <div class="left">
          <h2 :class="`title ${$t('TradingView.tradingView.tvlang')}-font-family-title`">{{$t('ManagingYourPortfolio.screen2.title')}}</h2>
          <div class="button-boxs">
            <a
              class="button-default"
              :href="'https://edocs-1305385874.cos.ap-hongkong.myqcloud.com/EBC-PRODUCT-GUIDEpdf_' + $t('CFooters.pdfLang') +'.pdf'"
              target="_blank"
            >
            {{$t('ManagingYourPortfolio.public-btn-text1')}}<span class="iconfont icon-pagination-right"></span>
            </a>
          </div>
        </div>
        <div class="right">
          <div class="tp-new-section3-item">
            <div class="item-route">500:1</div>
            <p class="p1">{{$t('ManagingYourPortfolio.screen2.text1')}}</p>
          </div>
          <div class="tp-new-section3-item">
            <div class="item-route">20<span>ms</span></div>
            <p class="p1">{{$t('ManagingYourPortfolio.screen2.text2')}}</p>
          </div>
        </div>
      </section>
      <section class="tp-new-section4">
        <div class="left">
          <h2 :class="`title ${$t('TradingView.tradingView.tvlang')}-font-family-title`" v-html="$t('ManagingYourPortfolio.screen2.title2')">
          </h2>
          <p class="info" v-html="$t('ManagingYourPortfolio.screen2.desc2')">
          </p>
          <div class="button-boxs">
            <router-link class="button-default" to="/AllProducts"
              >{{$t('ManagingYourPortfolio.public-btn-text2')}}</router-link
            >
          </div>
        </div>
        <div class="right">
          <div class="tp-new-index-section1-box">
            <div class="tp-new-index-section1-list">
              <div class="tp-new-index-section1-list-thead">
                <div class="tp-new-index-section1-list-tr">
                  <div class="tp-new-index-section1-list-th">
                    {{$t('ManagingYourPortfolio.screen1.link1')}}
                  </div>
                 
                  <div class="tp-new-index-section1-list-th">{{ $t("Index.买入") }}</div>
                  <div class="tp-new-index-section1-list-th">{{ $t("Index.出售") }}</div>
                  <div class="tp-new-index-section1-list-th">{{ $t("Index.点差") }}</div>
                  <div class="tp-new-index-section1-list-th"></div>
                </div>
              </div>
              <div class="tp-new-index-section1-list-tbody">
												<div class="tp-new-index-section1-list-tr"
													v-for="(item, index) in forexProducts" :key="index">
													<div class="tp-new-index-section1-list-td">
														<template v-if="item.listIndex == 0">
															<img class='pc' :src="getSrc(item.displaySymbol, '.png')"
																alt="" />
															<img class='mobile mobile-icon'
																:src="getSrc(item.displaySymbol, '_m.png')" alt="" />
														</template>
														<template v-if="item.listIndex != 0">
															<img :src="getSrc(item.displaySymbol, '.png')" alt="" />
														</template>

														{{ item.displaySymbol }}
													</div>
                          <div class="tp-new-index-section1-list-td ask-price-value "
														:class="item.askClassName">
														{{ item.ask }}
													</div>
													<div class="tp-new-index-section1-list-td bid-price-value "
														:class="item.bidClassName">
														{{ item.bid }}
													</div>
													
													<div class="tp-new-index-section1-list-td spread-value">{{ item.spread }}
													</div>
													<a class="buy" rel="nofollow"
														href="https://client.ebccrm.com/signup">{{ $t("Index.立即交易")
														}}</a>
												</div>

											</div>
              <div class="buy-box mobile">
                <a class="buy" rel="nofollow" :href="$store.state.khUrl">{{ $t("Index.立即交易")}}</a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="tp-new-section5" data-section="tradingproduct-subnav-2">
        <div class="left">
          <h2 :class="`title ${$t('TradingView.tradingView.tvlang')}-font-family-title`" v-html="$t('ManagingYourPortfolio.screen3.title')"></h2>
          <div class="button-boxs">
            <a
              class="button-default"
              :href="'https://edocs-1305385874.cos.ap-hongkong.myqcloud.com/EBC-PRODUCT-GUIDEpdf_' + $t('CFooters.pdfLang') +'.pdf'"
              target="_blank"
            >
            {{$t('ManagingYourPortfolio.public-btn-text1')}}<span class="iconfont icon-pagination-right"></span>
            </a>
          </div>
        </div>
        <div class="right">
          <div class="tp-new-section5-item">
            <img
              src="../assets/image/trading-product/section5-1.svg"
              alt=""
            />
            <p class="p1">{{$t('ManagingYourPortfolio.screen3.text1')}}</p>
          </div>
          <div class="tp-new-section5-item">
            <img
              src="../assets/image/trading-product/section5-2.svg"
              alt=""
            />
            <p class="p1">{{$t('ManagingYourPortfolio.screen3.text2')}}</p>
          </div>
          <div class="tp-new-section5-item">
            <img
              src="../assets/image/trading-product/section5-3.svg"
              alt=""
            />
            <p class="p1">{{$t('ManagingYourPortfolio.screen3.text3')}}</p>
          </div>
        </div>
      </section>
      <section class="tp-new-section6">
        <div class="left">
          <h2 :class="`title ${$t('TradingView.tradingView.tvlang')}-font-family-title`">
            {{$t('ManagingYourPortfolio.screen3.title2')}}
          </h2>
          <p class="info">{{$t('ManagingYourPortfolio.screen3.desc2')}}</p>
          <div class="button-boxs">
            <router-link class="button-default" to="/AllProducts"
              >{{$t('ManagingYourPortfolio.public-btn-text2')}}</router-link
            >
          </div>
        </div>
        <div class="right">
          <div class="tp-new-index-section1-box">
            <div class="tp-new-index-section1-list">
              <div class="tp-new-index-section1-list-thead">
                <div class="tp-new-index-section1-list-tr">
                  <div class="tp-new-index-section1-list-th">
                    {{$t('ManagingYourPortfolio.screen1.link2')}}
                  </div>
                  
                  <div class="tp-new-index-section1-list-th">{{ $t("Index.买入") }}</div>
                  <div class="tp-new-index-section1-list-th">{{ $t("Index.出售") }}</div>
                  <div class="tp-new-index-section1-list-th">{{ $t("Index.点差") }}</div>
                  <div class="tp-new-index-section1-list-th"></div>
                </div>
              </div>
              <div class="tp-new-index-section1-list-tbody">
												<div class="tp-new-index-section1-list-tr"
													v-for="(item, index) in commoditiesProducts" :key="index">
													<div class="tp-new-index-section1-list-td">
														<template v-if="item.listIndex == 0">
															<img class='pc' :src="getSrc(item.displaySymbol, '.png')"
																alt="" />
															<img class='mobile mobile-icon'
																:src="getSrc(item.displaySymbol, '_m.png')" alt="" />
														</template>
														<template v-if="item.listIndex != 0">
															<img :src="getSrc(item.displaySymbol, '.png')" alt="" />
														</template>

														{{ item.displaySymbol }}
													</div>
                          <div class="tp-new-index-section1-list-td ask-price-value "
														:class="item.askClassName">
														{{ item.ask }}
													</div>
													<div class="tp-new-index-section1-list-td bid-price-value "
														:class="item.bidClassName">
														{{ item.bid }}
													</div>
													
													<div class="tp-new-index-section1-list-td spread-value">{{ item.spread }}
													</div>
													<a class="buy" rel="nofollow"
														href="https://client.ebccrm.com/signup">{{ $t("Index.立即交易")
														}}</a>
												</div>

											</div>
              <div class="buy-box mobile">
                <a class="buy" rel="nofollow" :href="$store.state.khUrl">{{ $t("Index.立即交易")}}</a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="tp-new-section7" data-section="tradingproduct-subnav-3">
        <div class="left">
          <h2 :class="`title ${$t('TradingView.tradingView.tvlang')}-font-family-title`">{{$t('ManagingYourPortfolio.screen4.title')}}</h2>
          <div class="button-boxs">
            <a
              class="button-default"
              :href="'https://edocs-1305385874.cos.ap-hongkong.myqcloud.com/EBC-PRODUCT-GUIDEpdf_' + $t('CFooters.pdfLang') +'.pdf'"
              target="_blank"
            >
            {{$t('ManagingYourPortfolio.public-btn-text1')}}<span class="iconfont icon-pagination-right"></span>
            </a>
          </div>
        </div>
        <div class="right">
          <div class="tp-new-section7-item">
            <img
              src="../assets/image/trading-product/section7-1.svg"
              alt=""
            />
            <p class="p1">{{$t('ManagingYourPortfolio.screen4.text1')}}</p>
          </div>
          <div class="tp-new-section7-item">
            <img
              src="../assets/image/trading-product/section7-2.svg"
              alt=""
            />
            <p class="p1">{{$t('ManagingYourPortfolio.screen4.text2')}}</p>
          </div>
          <div class="tp-new-section7-item">
            <img
              src="../assets/image/trading-product/section7-3.svg"
              alt=""
            />
            <p class="p1">{{$t('ManagingYourPortfolio.screen4.text3')}}</p>
          </div>
          <div class="tp-new-section7-item">
            <img
              src="../assets/image/trading-product/section7-4.svg"
              alt=""
            />
            <p class="p1">{{$t('ManagingYourPortfolio.screen4.text4')}}</p>
          </div>
        </div>
      </section>
      <section class="tp-new-section8">
        <div class="left">
          <h2 :class="`title ${$t('TradingView.tradingView.tvlang')}-font-family-title`">
            {{$t('ManagingYourPortfolio.screen4.title2')}}
          </h2>
          
          <div class="button-boxs">
            <router-link class="button-default" to="/AllProducts"
              >{{$t('ManagingYourPortfolio.public-btn-text2')}}</router-link
            >
          </div>
        </div>
        <div class="right">
          <div class="tp-new-index-section1-box">
            <div class="tp-new-index-section1-list">
              <div class="tp-new-index-section1-list-thead">
                <div class="tp-new-index-section1-list-tr">
                  <div class="tp-new-index-section1-list-th">
                    {{$t('ManagingYourPortfolio.screen1.link3')}}
                  </div>
                  
                  <div class="tp-new-index-section1-list-th">{{ $t("Index.买入") }}</div>
                  <div class="tp-new-index-section1-list-th">{{ $t("Index.出售") }}</div>
                  <div class="tp-new-index-section1-list-th">{{ $t("Index.点差") }}</div>
                  <div class="tp-new-index-section1-list-th"></div>
                </div>
              </div>
              <div class="tp-new-index-section1-list-tbody" data-type="IndexCFDs">
                <div class="tp-new-index-section1-list-tr"
													v-for="(item, index) in indexProducts" :key="index">
													<div class="tp-new-index-section1-list-td">
														<template v-if="item.listIndex == 0">
															<img class='pc' :src="getSrc(item.displaySymbol, '.png')"
																alt="" />
															<img class='mobile mobile-icon'
																:src="getSrc(item.displaySymbol, '_m.png')" alt="" />
														</template>
														<template v-if="item.listIndex != 0">
															<img :src="getSrc(item.displaySymbol, '.png')" alt="" />
														</template>

														{{ item.displaySymbol }}
													</div>
                          <div class="tp-new-index-section1-list-td ask-price-value "
														:class="item.askClassName">
														{{ item.ask }}
													</div>
													<div class="tp-new-index-section1-list-td bid-price-value "
														:class="item.bidClassName">
														{{ item.bid }}
													</div>
													
													<div class="tp-new-index-section1-list-td spread-value">{{ item.spread }}
													</div>
													<a class="buy" rel="nofollow"
														href="https://client.ebccrm.com/signup">{{ $t("Index.立即交易")
														}}</a>
												</div>
              </div>
              <div class="buy-box mobile">
                <a class="buy" rel="nofollow" :href="$store.state.khUrl">{{ $t("Index.立即交易")}}</a>
              </div>
            </div>
          </div>
        </div>
      </section>


      <section class="tp-new-section9" data-section="tradingproduct-subnav-4">
        <div class="left">
          <h2 :class="`title ${$t('TradingView.tradingView.tvlang')}-font-family-title`">{{$t('ManagingYourPortfolio.screen5.title')}}</h2>
          <div class="button-boxs">
            <a
              class="button-default"
              :href="'https://edocs-1305385874.cos.ap-hongkong.myqcloud.com/EBC-PRODUCT-GUIDEpdf_' + $t('CFooters.pdfLang') +'.pdf'"
              target="_blank"
            >
            {{$t('ManagingYourPortfolio.public-btn-text1')}}<span class="iconfont icon-pagination-right"></span>
            </a>
          </div>
        </div>
        <div class="right">
          <div class="tp-new-section9-item">
            <img
              src="../assets/image/trading-product/section9-2.svg"
              alt=""
            />
            <p class="p1" v-html="$t('ManagingYourPortfolio.screen5.text1')"></p>
          </div>
          <div class="tp-new-section9-item">
            <img
              src="../assets/image/trading-product/section9-2.svg"
              alt=""
            />
            <p class="p1" v-html="$t('ManagingYourPortfolio.screen5.text2')"></p>
          </div>
          <div class="tp-new-section9-item">
            <img
              src="../assets/image/trading-product/section9-2.svg"
              alt=""
            />
            <p class="p1" v-html="$t('ManagingYourPortfolio.screen5.text3')"></p>
          </div>
          <div class="tp-new-section9-item">
            <img
              src="../assets/image/trading-product/section9-2.svg"
              alt=""
            />
            <p class="p1" v-html="$t('ManagingYourPortfolio.screen5.text4')"></p>
          </div>
        </div>
      </section>
      <section class="tp-new-section10">
        <div class="left">
          <h2 :class="`title ${$t('TradingView.tradingView.tvlang')}-font-family-title`">
            {{$t('ManagingYourPortfolio.screen5.title2')}}
          </h2>
          
          <div class="button-boxs">
            <router-link class="button-default" to="/AllProducts"
              >{{$t('ManagingYourPortfolio.public-btn-text2')}}</router-link
            >
          </div>
        </div>
        <div class="right">
          <div class="tp-new-index-section1-box">
            <div class="tp-new-index-section1-list">
              <div class="tp-new-index-section1-list-thead">
                <div class="tp-new-index-section1-list-tr">
                  <div class="tp-new-index-section1-list-th">
                    {{$t('ManagingYourPortfolio.screen1.link4')}}
                  </div>
                  
                  <div class="tp-new-index-section1-list-th">{{ $t("Index.买入") }}</div>
                  <div class="tp-new-index-section1-list-th">{{ $t("Index.出售") }}</div>
                  <div class="tp-new-index-section1-list-th">{{ $t("Index.点差") }}</div>
                  <div class="tp-new-index-section1-list-th"></div>
                </div>
              </div>
              <div class="tp-new-index-section1-list-tbody" data-type="StockCFDs">
                <div class="tp-new-index-section1-list-tr"
													v-for="(item, index) in stockProducts" :key="index">
													<div class="tp-new-index-section1-list-td">
														<template v-if="item.listIndex == 0">
															<img class='pc' :src="getSrc(item.displaySymbol, '.png')"
																alt="" />
															<img class='mobile mobile-icon'
																:src="getSrc(item.displaySymbol, '_m.png')" alt="" />
														</template>
														<template v-if="item.listIndex != 0">
															<img :src="getSrc(item.displaySymbol, '.png')" alt="" />
														</template>

														{{ item.displaySymbol }}
													</div>
                          <div class="tp-new-index-section1-list-td ask-price-value "
														:class="item.askClassName">
														{{ item.ask }}
													</div>
													<div class="tp-new-index-section1-list-td bid-price-value "
														:class="item.bidClassName">
														{{ item.bid }}
													</div>
													
													<div class="tp-new-index-section1-list-td spread-value">{{ item.spread }}
													</div>
													<a class="buy" rel="nofollow"
														href="https://client.ebccrm.com/signup">{{ $t("Index.立即交易")
														}}</a>
												</div>
              </div>
              <div class="buy-box mobile">
                <a class="buy" rel="nofollow" :href="$store.state.khUrl">{{ $t("Index.立即交易")}}</a>
              </div>
            </div>
          </div>
        </div>
      </section>


      <ss-contact-us-new mode="all"></ss-contact-us-new>

    </div>
    <page-footer></page-footer>
  </div>
</template>

<script>
import md5 from 'js-md5';
import {
	mapState
} from "vuex";
import { method } from 'lodash';
import { create } from 'js-md5';



// 连接全球热门交易产品 tabs
// 比较大小返回对应类名
function compareValues(value1, value2, className = "") {
	// 尝试将两个值转换为数字
	const num1 = Number(value1);
	const num2 = Number(value2);

	// 检查转换后的值是否都是有效的数字
	if (!isNaN(num1) && !isNaN(num2)) {
		if (num1 >= num2) {
			return "green";
		} else if (num1 < num2) {
			return "red";
		} else {
			// 两者相等的情况
			return className;
		}
	}

	// 如果转换后不是有效数字，则返回空字符串
	return "";
}
// 传入ask,bid,返回点差Spread
function getSpread(t, e) {
	var o = /^\+?[1-9][0-9]*$/,
		r = 0,
		d = 0,
		c = 0;
	return (
		o.test(t) || (d = t.toString().split(".")[1].length),
		o.test(e) || (c = e.toString().split(".")[1].length),
		d > c
			? 1 === d
				? (r = 10 * t - 10 * e)
				: 2 === d
					? (r = 100 * t - 100 * e)
					: 3 === d
						? (r = 1e3 * t - 1e3 * e)
						: 4 === d
							? (r = 1e4 * t - 1e4 * e)
							: 5 === d && (r = 1e5 * t - 1e5 * e)
			: 1 === c
				? (r = 10 * t - 10 * e)
				: 2 === c
					? (r = 100 * t - 100 * e)
					: 3 === c
						? (r = 1e3 * t - 1e3 * e)
						: 4 === c
							? (r = 1e4 * t - 1e4 * e)
							: 5 === c && (r = 1e5 * t - 1e5 * e),
		r.toFixed(0)
	);
}

export default {
	data() {
		return {
      activeIndex: null,  
      navHeight: 0,  
      mainNavHeight: 0,  // 添加主导航高度  
      isScrolling: false,  
      offsetAdjustment: 0,
			forex: {
				"EURUSD": {
					chadian: "",
					bid_price: "0.00",
					ask_price: "0.00",
					num: "",
					percentage: 0
				},
				"XAUUSD": {
					chadian: "",
					bid_price: "0.00",
					ask_price: "0.00",
					num: "",
					percentage: 0
				},

				"U30USD": {
					chadian: "",
					bid_price: "0.00",
					ask_price: "0.00",
					num: "",
					percentage: 0
				},
			},
			XAGUSDask: "",
			EURUSDask: "",
			U30USDDask: "",
			pageIndex: 0,
			// 下标
			listIndex: 0,
			// 衍生品交易 Forex：EUR/USD, GBP/USD, USD/JPY, AUD/USD
			// 贵金属及能源 Commodities：XAG/USD, XAU/USD, XBR/USD, XNG/USD
			// 全球股指CFDs IndexCFDs：SPXUSD, NASUSD, U30USD, D30EUR
			// 美国股票CFDs StockCFDs：Apple, Tesla, Amazon, Microsoft
			allProducts: [
				{
					displaySymbol: "EURUSD",
					ask: "-",
					bid: "-",
					spread: "-",
					bidClassName: "",
					askClassName: "",
					type: "Forex",
					listIndex: 0,
				},
				{
					displaySymbol: "GBPUSD",
					ask: "-",
					bid: "-",
					spread: "-",
					bidClassName: "",
					askClassName: "",
					type: "Forex",
					listIndex: 0,
				},
				{
					displaySymbol: "USDJPY",
					ask: "-",
					bid: "-",
					spread: "-",
					bidClassName: "",
					askClassName: "",
					type: "Forex",
					listIndex: 0,
				},
				{
					displaySymbol: "AUDUSD",
					ask: "-",
					bid: "-",
					spread: "-",
					bidClassName: "",
					askClassName: "",
					type: "Forex",
					listIndex: 0,
				},
				{
					displaySymbol: "XAUUSD",
					ask: "-",
					bid: "-",
					spread: "-",
					bidClassName: "",
					askClassName: "",
					type: "Commodities",
					listIndex: 1,
				},
				{
					displaySymbol: "XAGUSD",
					ask: "-",
					bid: "-",
					spread: "-",
					bidClassName: "",
					askClassName: "",
					type: "Commodities",
					listIndex: 1,
				},
				{
					displaySymbol: "XBRUSD",
					ask: "-",
					bid: "-",
					spread: "-",
					bidClassName: "",
					askClassName: "",
					type: "Commodities",
					listIndex: 1,
				},
				{
					displaySymbol: "XNGUSD",
					ask: "-",
					bid: "-",
					spread: "-",
					bidClassName: "",
					askClassName: "",
					type: "Commodities",
					listIndex: 1,
				},
				{
					displaySymbol: "U30USD",
					ask: "-",
					bid: "-",
					spread: "-",
					bidClassName: "",
					askClassName: "",
					type: "IndexCFDs",
					listIndex: 2,
				},
				{
					displaySymbol: "NASUSD",
					ask: "-",
					bid: "-",
					spread: "-",
					bidClassName: "",
					askClassName: "",
					type: "IndexCFDs",
					listIndex: 2,
				},
				{
					displaySymbol: "SPXUSD",
					ask: "-",
					bid: "-",
					spread: "-",
					bidClassName: "",
					askClassName: "",
					type: "IndexCFDs",
					listIndex: 2,
				},
				{
					displaySymbol: "D30EUR",
					ask: "-",
					bid: "-",
					spread: "-",
					bidClassName: "",
					askClassName: "",
					type: "IndexCFDs",
					listIndex: 2,
				},
				{
					displaySymbol: "AAPL",
					ask: "-",
					bid: "-",
					spread: "-",
					bidClassName: "",
					askClassName: "",
					type: "StockCFDs",
					listIndex: 3,
				},
				{
					displaySymbol: "TSLA",
					ask: "-",
					bid: "-",
					spread: "-",
					bidClassName: "",
					askClassName: "",
					type: "StockCFDs",
					listIndex: 3,
				},
				{
					displaySymbol: "AMZN",
					ask: "-",
					bid: "-",
					spread: "-",
					bidClassName: "",
					askClassName: "",
					type: "StockCFDs",
					listIndex: 3,
				},
				{
					displaySymbol: "MSFT",
					ask: "-",
					bid: "-",
					spread: "-",
					bidClassName: "",
					askClassName: "",
					type: "StockCFDs",
					listIndex: 3,
				},
			]
		}
	},
	computed: {
		showProducts() {
			return this.allProducts.filter(el => {
				return el.listIndex == this.listIndex
			})
		},
    forexProducts() {  
      return this.allProducts.filter(item => item.listIndex === 0)  
    },  
    commoditiesProducts() {  
      return this.allProducts.filter(item => item.listIndex === 1)  
    },  
    indexProducts() {  
      return this.allProducts.filter(item => item.listIndex === 2)  
    },  
    stockProducts() {  
      return this.allProducts.filter(item => item.listIndex === 3)  
    }
	},
	created() {
		this.getPortfolio();
		this.getListData();
	},
  mounted() {  
    this.$nextTick(() => {  
      const subNav = document.querySelector('.tp-new-section2')  
      const mainNav = document.querySelector('#page-navigate')  
      
      this.navHeight = subNav ? subNav.offsetHeight : 0  
      this.mainNavHeight = mainNav ? mainNav.offsetHeight : 0  

      window.addEventListener('scroll', this.handleScroll)  
      window.addEventListener('resize', this.handleResize)  
    }) 
  },
  beforeDestroy() {  
    // 清理事件监听  
    window.removeEventListener('scroll', this.handleScroll)  
    window.removeEventListener('resize', this.handleResize) 
  },
	methods: {
		getSrc(src, end) {
			return require('../assets/image/Home/icons/icon_' + src + end)
		},
		getPortfolio() {
			let time = new Date().getMilliseconds()
			this.$http('/ebc/api/getToken/run', {
				date: time,
				CheckSum: md5(time + "YISENCE")
			}).then(res => {
				let url = "wss://pricecloud.eappservice.com/ws?token=" + res.data.data
				this.init(url)
			})
		},
		//init函数可在页面加载的时候就进行初始化或者根据自己的业务需求在需要打开通讯的时候在进行初始化
		init(url) {
			this.ws = new WebSocket(url)

			//监听是否连接成功
			this.ws.onopen = () => {
				//连接成功则发送一个数据
				this.ws.send('连接成功');
			}

			//接听服务器发回的信息并处理展示
			this.ws.onmessage = (data) => {
				if (data.data == 'Ws connected success！' || data.data == '连接成功') return
				let info = JSON.parse(data.data)
				if (info.tick.length == 0) return
				info.tick.forEach(o => {
					o.chadian = this.getCount(o.ask_price * 1, o.bid_price * 1)
				})
				this.updateTabOneDocument({
					ask: info.tick[0].ask_price,
					bid: info.tick[0].bid_price,
					displaySymbol: info.symbol,
				});
			}

			//监听连接关闭事件
			this.ws.onclose = () => {
				//监听整个过程中websocket的状态
				console.log('ws连接状态：' + this.ws.readyState);
			}

			//监听并处理error事件
			this.ws.onerror = function (error) {
				console.log(error);
			}
		},
		getCount(idx1, idx2) {
			const r = /^\+?[1-9][0-9]*$/; // 正整数
			// 如果判断不符合正则，则不是正整数不能提交
			let num = 0;
			let a1 = 0
			let a2 = 0
			if (!r.test(idx1)) {
				a1 = idx1.toString().split(".")[1].length;
			}
			if (!r.test(idx2)) {
				a2 = idx2.toString().split(".")[1].length;
			}
			if (a1 > a2) {
				if (a1 === 1) {
					num = idx1 * 10 - idx2 * 10
				} else if (a1 === 2) {
					num = idx1 * 100 - idx2 * 100
				} else if (a1 === 3) {
					num = idx1 * 1000 - idx2 * 1000
				} else if (a1 === 4) {
					num = idx1 * 10000 - idx2 * 10000
				} else if (a1 === 5) {
					num = idx1 * 100000 - idx2 * 100000
				}
			} else {
				if (a2 === 1) {
					num = idx1 * 10 - idx2 * 10
				} else if (a2 === 2) {
					num = idx1 * 100 - idx2 * 100
				} else if (a2 === 3) {
					num = idx1 * 1000 - idx2 * 1000
				} else if (a2 === 4) {
					num = idx1 * 10000 - idx2 * 10000
				} else if (a2 === 5) {
					num = idx1 * 100000 - idx2 * 100000
				}
			}
			return num.toFixed(0);
		},
		tear(item) {
			let data = JSON.stringify(item)
			return data.split('.')
		},
		isMobile() {
			let flag = navigator.userAgent.match(
				/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
			);
			return flag;
		},
		handlerUp() {
			if (!this.isMobile()) {
				this.scrollToSection(1)
			}
		},
		handleClick(index, event) {
			if (index == this.listIndex) {
				return;
			}
			this.listIndex = index;
		},
		// 更新单条数据元素
		updateTabOneDocument(newValue) {
			// console.log("updateTabOneDocument", newValue)
			let flag = false;
			let el = {};
			this.allProducts = this.allProducts.map((oldValue) => {
				if (newValue.displaySymbol == oldValue.displaySymbol) {
					flag = true;
					oldValue.askClassName = compareValues(
						newValue.ask,
						oldValue.ask,
						oldValue.askClassName
					);
					oldValue.bidClassName = compareValues(
						newValue.bid,
						oldValue.bid,
						oldValue.bidClassName
					);
					oldValue.ask = newValue.ask;
					oldValue.bid = newValue.bid;
					oldValue.spread = getSpread(oldValue.ask, oldValue.bid);
					el = oldValue;
				}
				return oldValue;
			});

		},
		// 获取历史数据列表
		getListData() {
			$.ajax({
				type: "GET",
				url: "https://www.ebccfd.com/portal/api/getSymbolAll",
				success: (res) => {
					console.log("/api/symbol/all res", res)
					let data = res.rows;
					this.createAllDataMeta(data);
				},
				fail: (err) => {
					console.log("/api/symbol/all err", err)
				}
			})
		},
		// 创建所有数据
		createAllDataMeta(data) {
			data.map((newValue) => {
				this.allProducts = this.allProducts.map((oldValue) => {
					if (newValue.displaySymbol == oldValue.displaySymbol) {
						oldValue.askClassName = compareValues(newValue.ask, oldValue.ask);
						oldValue.bidClassName = compareValues(newValue.bid, oldValue.bid);
						oldValue.ask = newValue.ask;
						oldValue.bid = newValue.bid;
						oldValue.spread = getSpread(oldValue.ask, oldValue.bid);
					}
					return oldValue;
				});
			});
		},

    handleNavClick(index) {  
      this.activeIndex = index  

      const targetSection = document.querySelector(`[data-section="tradingproduct-subnav-${index + 1}"]`)  
      if (targetSection) {  
        const subNav = document.querySelector('.tp-new-section2')  
        const mainNav = document.querySelector('#page-navigate')  
        
        // 计算总偏移量，包括主导航和子导航的高度  
        const totalNavHeight = (mainNav?.offsetHeight || 0) + (subNav?.offsetHeight || 0)  
        
        const targetPosition = targetSection.getBoundingClientRect().top +   
                             window.pageYOffset -   
                             totalNavHeight +  
                             this.offsetAdjustment  

        window.scrollTo({  
          top: targetPosition,  
          behavior: 'smooth'  
        })  
      }  
    },  

    handleScroll() {  
      requestAnimationFrame(() => {  
        const subNav = document.querySelector('.tp-new-section2')  
        const mainNav = document.querySelector('#page-navigate')  
        
        const totalNavHeight = (mainNav?.offsetHeight || 0) + (subNav?.offsetHeight || 0)  
        const totalOffset = totalNavHeight - this.offsetAdjustment  
        const scrollPosition = window.pageYOffset + totalOffset  

        const sections = document.querySelectorAll('[data-section]')  
        let nextSectionIndex = null  

        // 找到当前滚动位置对应的区域索引  
        for (let i = 0; i < sections.length; i++) {  
          const section = sections[i]  
          const rect = section.getBoundingClientRect()  
          const sectionTop = section.offsetTop - totalOffset  

          if (scrollPosition < sectionTop) {  
            nextSectionIndex = i  
            break  
          }  
        }  

        // 如果找到了下一个区域，当前高亮索引应该是上一个区域  
        if (nextSectionIndex !== null) {  
          this.activeIndex = Math.max(0, nextSectionIndex - 1)  
        } else {  
          // 如果没找到下一个区域（已经滚动到最后），高亮最后一个导航  
          this.activeIndex = sections.length - 1  
        }  
      })  
      },  

    handleResize() {  
      // 重新计算两个导航的高度  
      const subNav = document.querySelector('.tp-new-section2')  
      const mainNav = document.querySelector('#page-navigate')  
      
      this.navHeight = subNav ? subNav.offsetHeight : 0  
      this.mainNavHeight = mainNav ? mainNav.offsetHeight : 0  
      
      this.handleScroll()  
    } 
  
	}

}
</script>

<style scoped>
@import url("../assets/css/rem.css");
	@font-face {
  font-family: "Financier Display";
  src: url("../assets/font/FinancierDisplay-Medium.otf") format("opentype");
}
.tp-new-section1 {
  height: 333rem;
  background: url(../assets/image/trading-product/bg1.png);
  background-size: cover;
  padding: 0 235rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 40rem;
}
.tp-new-section1-title {
  color: #fff;
  font-size: 40rem;
  font-style: normal;
  font-weight: 900;
  line-height: 1em;
  letter-spacing: 4rem;
}
.tp-new-section1-intro {
  color: #fff;
  font-size: 18rem;
  font-style: normal;
  font-weight: 400;
  line-height: 28rem;
  max-width: 600rem;
  /* letter-spacing: 2px; */
}
.tp-new-section1-line {
  background: #ff4b4e;
  height: 2px;
  width: 94rem;
}
.tp-new-section2 {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 66rem;
  height: 72rem;
  background: #fff;
  box-shadow: 0 2rem 10rem 0 rgba(61, 1, 1, 0.08);
  position: sticky;
  top: 87rem;
  left: 0;
  z-index: 9;
}
.tp-new-section3,
.tp-new-section5,
.tp-new-section7,
.tp-new-section9 {
  display: flex;
  justify-content: space-between;
  padding: 120rem 235rem;
}

.title {
  color: #a52933;
  font-size: 36rem;
  font-style: normal;
  font-weight: 900;
  line-height: 46rem;
}
.info {
  margin-top: 60rem;
  color: #666;
  font-size: 16rem;
  font-style: normal;
  font-weight: 400;
  line-height: 28rem;
}
.tp-new-section3 .button-boxs,
.tp-new-section5 .button-boxs,
.tp-new-section7 .button-boxs,
.tp-new-section9 .button-boxs {
  margin-top: 165rem;
}
.button-boxs {
  display: flex;
  gap: 10rem;
}
.tp-new-section3 .right,
.tp-new-section5 .right,
.tp-new-section7 .right {
  display: flex;
  justify-content: space-between;
}
.left {
  max-width: 390rem;
}
.right {
  padding-top: 8rem;
  width: 780rem;
}
.tp-new-section3-item .item-route {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 220rem;
  height: 220rem;
  border-radius: 50%;
  border: 1px solid #a52933;
  color: #a52933;
  text-align: center;
  font-family: "Financier Display";
  font-size: 80rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1em;
}
.tp-new-section3-item .item-route span {
  font-size: 50rem;
}
.tp-new-section3-item .p1,
.tp-new-section5-item .p1,
.tp-new-section7-item .p1 {
  margin-top: 25rem;
  color: #666;
  text-align: center;
  font-size: 18rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.4em;
  padding: 0 16rem;
}

.button-default {
  display: inline-flex;
  padding: 13rem 40rem;
  gap: 8rem;
  justify-content: center;
  align-items: baseline;
  flex-shrink: 0;
  border-radius: 2rem;
  background: #fff;
  border: 1px solid #a52933;
  color: #a52933;
  text-align: center;
  font-size: 16rem;
  font-weight: 400;
  transition: all 0.3s;
}
.button-default:hover,
.button-default.active {
  background: #a52933;
  color: #fff;
}
.button-default .iconfont,
.button-primary .iconfont {
  font-size: 12rem;
}

.button-primary {
  display: inline-flex;
  padding: 13rem 40rem;
  gap: 8rem;
  justify-content: center;
  align-items: baseline;
  flex-shrink: 0;
  border-radius: 2rem;
  color: #fff;
  border: 1px solid #a52933;
  background: #a52933;
  text-align: center;
  font-size: 16rem;
  font-weight: 400;
  transition: all 0.3s;
}
.button-primary:hover{
  background: #9F734C;
  border-color: #9F734C;
  color: #fff;
}
.button-primary-active {
  color: #a52933;
  background: #fff;
}
.tp-new-section4,
.tp-new-section6,
.tp-new-section8,
.tp-new-section10 {
  display: flex;
  justify-content: space-between;
  padding: 120rem 235rem;
  background: linear-gradient(
    90deg,
    rgba(246, 246, 246, 0.5) 0%,
    rgba(217, 217, 217, 0) 100%
  );
}
.tp-new-section4 .button-boxs,
.tp-new-section6 .button-boxs,
.tp-new-section8 .button-boxs,
.tp-new-section10 .button-boxs {
  margin-top: 100rem;
}

.tp-new-index-section1-box {
  display: flex;
  flex-direction: column;
}

.tp-new-index-section1-list {
  width: 100%;
  min-height: 392rem;
  border-radius: 10rem;
  background: #fff;
  box-shadow: 0rem 10rem 18rem 0rem rgba(0, 0, 0, 0.05);
  padding: 0 60rem 8rem;
}
.tp-new-index-section1-list-tr > div:nth-child(1) {
  color: #9f734c;
  font-size: 20rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1em;
}
.tp-new-index-section1-list-thead{
  margin-bottom: 12rem;
}
.tp-new-index-section1-list-thead .tp-new-index-section1-list-tr {
  display: flex;
  color: #b2afad;
  font-size: 14rem;
  font-weight: 400;
  line-height: 1em;
  padding-top: 30rem;
  padding-bottom: 5rem;
}
.tp-new-index-section1-list-tr + .tp-new-index-section1-list-tr {
  border-top: 1px solid #e6ddcf;
}
.tp-new-index-section1-list-tr > div:nth-child(1) {
  width: 22.9%;
}
.tp-new-index-section1-list-tr > div:nth-child(2) {
  width: 22%;
  text-align: center;
}
.tp-new-index-section1-list-tr > div:nth-child(3) {
  width: 21.7%;
  text-align: center;
}
.tp-new-index-section1-list-tr > div:nth-child(4) {
  width: 17.2%;
  text-align: center;
}
.tp-new-index-section1-list-tr > a,
.tp-new-index-section1-list-tr > div:nth-child(5) {
  width: 15.9%;
}
.tp-new-index-section1-list-tbody .tp-new-index-section1-list-tr {
  display: flex;
  align-items: center;
  color: #6a6967;
  font-family: Gilroy;
  font-size: 22rem;
  font-weight: 400;
  line-height: 33rem;
  padding: 24rem 0;
}
.tp-new-index-section1-list-tbody img {
  width: 36rem;
  height: 36rem;
}
.mobile-icon {
  width: 38rem !important;
  height: 25rem !important;
}
.tp-new-index-section1-list-tbody .tp-new-index-section1-list-tr > div:nth-child(1) {
  display: flex;
  gap: 12rem;
  align-items: center;
  color: #5d2333;
  font-family: Gilroy;
  font-size: 20rem;
  font-weight: 700;
  line-height: 27rem;
}

.buy {
  display: flex;
  width: 100%;
  height: 100%;
  /* padding: 8px 23px 10px 24px; */
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 4rem;
  border: 1px solid #9f734c;
  color: #9f734c;
  font-size: 14rem;
  font-weight: 500;
  transition: all 0.4s;
}
.buy:hover {
  color: #fff;
  background-color: #9f734c;
}
.tp-new-section5-item,
.tp-new-section7-item {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.tp-new-section5-item img {
  width: 190rem;
  height: 190rem;
}
.tp-new-section7-item img {
  width: 145rem;
  height: 145rem;
}
.tp-new-section5-item{
  width: 33%;
}
.tp-new-section7-item{
  width: 25%;
}

.tp-new-section9 .right {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    background-image: url('../assets/image/trading-product/section9-1.svg');  
    background-position: center;  
    background-size: cover;
    background-repeat: no-repeat; 
}
.tp-new-section9-item{
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  padding: 50rem 0;
}
.tp-new-section9-item img{
  width: 52rem;
  height: 52rem;
}
.tp-new-section9-item p{
  color: #666;
  text-edge: cap;
  font-size: 20rem;
  font-style: normal;
  font-weight: 400;
  line-height: 25rem;
  margin-top: 20rem;
}



.green {
  color: #51ad25 !important;
}
.red {
  color: #ff4b4e !important;
}

.tp-new-section3-item{
  text-align: center;
}

.tp-new-section9-item p{
  padding: 0 8%;
  text-align: center;
  font-size: 18rem;
}

.en-trading-body .en-font-family-title{
  font-weight: 500;
  letter-spacing: 0px;
}
.en-trading-body .tp-new-section9-item{
  padding: 60rem 0;
}


.en-trading-body .tp-new-section1-intro{
  max-width: 680rem;
}
.en-trading-body .button-boxs{
      flex-wrap: wrap;
}
.en-trading-body .left {
  max-width: 530rem;
}
.kr-font-family-title, .kr-font-family .kr-font-family-title{
  font-weight: 700;
  letter-spacing: 0px;
}
.th-font-family-title{
  font-weight: 800;
  letter-spacing: 0px;
}
.vi-font-family-title{
  letter-spacing: 0px;
}




@media (max-width: 1200px) {
  .tp-new-section2{
    display: none;
  }
}

@media (max-width: 996px) {
  
  .tp-new-section1 {
    height: auto;
    background: url(../assets/image/trading-product/bg1-m.jpg);
    background-size: cover;
    padding: 120px 8%;
    text-align: center;
  }
  .tp-new-section1-title{
    font-size: 38px;
  }
  .tp-new-section1-intro{
    font-size: 16px;
    line-height: 2;
    margin-bottom: 20px;
    margin-top: 10px;
  }
  .tp-new-section1-line{
    margin: 0 auto;
    width: 100px;
  }
  .tp-new-section1-intro{
    max-width: none;
  }
  .left{
    width: 100%;
    max-width: 100%;
  }
  .right{
    width: 100%;
    max-width: 100%;
  }
  .tp-new-section3, .tp-new-section5, .tp-new-section7, .tp-new-section9{
    padding: 70px 8%;
    display: block;
    position: relative;
    padding-bottom: 240px;
  }
  .title{
    font-size: 28px;
    text-align: center;
    line-height: 1.4;
  }
  .tp-new-section3-item .item-route{
    width: 140px;
    height: 140px;
    font-size: 54px;
    margin-top: 60px;
  }
  .tp-new-section3-item .item-route span{
    font-size: 38px;
  }
  .tp-new-section3 .button-boxs, .tp-new-section5 .button-boxs, .tp-new-section7 .button-boxs, .tp-new-section9 .button-boxs {
    position: absolute;
    bottom: 70px;
    display: block;
    width: 84%;
    text-align: center;
  }
  .tp-new-section3-item .p1, .tp-new-section5-item .p1, .tp-new-section7-item .p1{
    font-size: 16px;
    margin-top: 20px;
    padding: 0 7%;
  }
  .button-default,.button-primary{
    width: 90%;
    font-size: 16px;
    padding: 14px;
  }
  .button-default{
    margin-top: 0px;
    margin-bottom: 16px;
  }
  .button-default .iconfont, .button-primary .iconfont{
    font-size: 14px;
  }
  .tp-new-section4, .tp-new-section6, .tp-new-section8, .tp-new-section10{
    padding: 70px 8%;
    display: block;
    position: relative;
    padding-bottom: 190px;
  }
  .info{
    font-size: 16px;
    line-height: 1.5;
    text-align: center;
  }
  .tp-new-section4 .button-boxs, .tp-new-section6 .button-boxs, .tp-new-section8 .button-boxs, .tp-new-section10 .button-boxs{
    position: absolute;
    bottom: 70px;
    display: block;
    width: 84%;
    text-align: center;
  }
  .tp-new-index-section1-list-tr > div:nth-child(1){
    font-size: 14px;
  }
  .tp-new-index-section1-list-th:last-child {  
    display: none !important;  
  }  

  .tp-new-index-section1-list-tr .buy:last-child {  
      display: none !important;  
  }  
  .buy {
        height: 28px;
        width: 103px;
        font-size: 14px;
        /* display: inline-flex
; */
    }
  .tp-new-index-section1-list {
    padding: 0 16px 32px;
  }
  .tp-new-index-section1-list-tbody .tp-new-index-section1-list-tr {
    font-size: 16px;
    padding: 12px 0;
    align-items: flex-end;
    line-height: 1em;
  }
  .tp-new-index-section1-list-th,
  .tp-new-index-section1-list-td {
    text-align: center;
  }

  .tp-new-index-section1-list-tbody .tp-new-index-section1-list-tr > div:nth-child(1) {
    flex-direction: column;
    align-items: flex-start;
    font-size: 16px;
    gap: 2px;
    line-height: 1em;
  }
  .tp-new-index-section1-list-tr > div:nth-child(1) {
    width: 28%;
    text-align: left;
    line-height: 1.4;
  }
  .tp-new-index-section1-list-tr > div:nth-child(2) {
    width: 28%;
  }
  .tp-new-index-section1-list-tr > div:nth-child(3) {
    width: 28%;
  }
  .tp-new-index-section1-list-tr > div:nth-child(4) {
    width: 16%;
  }
  .tp-new-index-section1-list-tr > a,
  .tp-new-index-section1-list-tr > div:nth-child(5) {
    display: none;
    width: 0;
  }
  .buy-box {
    display: flex !important;
    justify-content: flex-end;
    margin-top: 16px;
  }
  .tp-new-index-section1-list-thead .tp-new-index-section1-list-tr{
    font-size: 14px;
    line-height: 2.2;
  }
  .tp-new-index-section1-box{
    margin-top: 60px;
  }
  .tp-new-section5 .right,.tp-new-section7 .right{
    flex-wrap: wrap;
  }
  .tp-new-section5-item,.tp-new-section7-item{
    width: 50%;
    margin-top: 40px;
  }
  .tp-new-section5-item img,.tp-new-section7-item img{
    width: 120px;
    height: 120px;
  }
  .tp-new-section5-item:nth-child(1){
    width: 100%;
  }
  .tp-new-section9-item p{
    font-size: 14px;
      padding: 0 12%;
  line-height: 1.4;
  color: #666666;
  }
  .tp-new-section9-item img{
    width: 50px;
    height: 50px;
  }
  .tp-new-section9{
    padding: 70px 2% 240px;
  }
  .tp-new-section9 .button-boxs{
    width: 96%;
  }
  .tp-new-section9 .button-default, .tp-new-section9 .button-primary{
    width: 70%;
  }
  .tp-new-section9 .right{
    margin-top: 50px;
  }
  .tp-new-section9-item{
    padding: 60px 0;
  }
  .tp-new-section9 .right {
    background-image: url(../assets/image/trading-product/section9-1-m.svg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
  

  .en-trading-body .tp-new-section1-title{
    font-size: 32px;
    line-height: 1.3;
  }
  .en-trading-body .tp-new-section9-item{
    padding: 60rem 0;
  }
  
    .en-trading-body .tp-new-section1-intro{
      max-width: none;
    }
    .en-trading-body .left{
      width: 100%;
        max-width: 100%;
    }
}
</style>
