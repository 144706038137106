<template>
    <div :class="[$t('common.fontFamily'), $t('common.lang'), $t('common.type')]">
        <page-navigate></page-navigate>
        <div :id="'section1-' + $t('common.lang')" class="acticity-list-session1 section1"
            @click="toPath({ url: '/million-dollar-challenge-2' })">
        </div>
        <div class="acticity-list-session2">
            <div class="activity-list-top-menu-box">
                <div class="activity-list-top-menu">
                    <div v-for="(menu, index) in $t('activityList.activityListMetaData')" :key="index"
                        class="activity-list-top-menu-item "
                        :class="[$t('common.fontFamilyTitle'),{ 'activity-list-top-menu-item-selected': index == currentmenu }]"
                        @click="handleCheckoutMenu(index)">
                        {{ menu.label }}
                    </div>
                </div>
            </div>
            <div class="activity-list-wrapper">
                <div class="activity-list-time-line-wrapper pc">
                    <div class="activity-list-time-line-list">
                        <div class="activity-list-time-line-list-inner">
                            <template v-for="(item, index) in currentData">
                                <a class="activity-list-time-line-link"
                                    :class="{ 'activity-list-time-line-link-active': computedActivity(index) }"
                                    href="javascript:void(0)" v-if="item.time" @click="scrollTo('#tag' + item.id)"><span
                                        class="iconfont icon-Vector"></span>{{ item.time }}</a>
                            </template>
                        </div>
                    </div>
                </div>
                <div class="activity-list-banner-box">

                    <div class="activity-list-banner-list">
                        <div class="activity-list-banner-item" v-for="(item, index) in currentData" :key="index"
                            :id="'tag' + item.id" @click="toPath(item)">
                            <img class="activity-list-banner-item-img" :src="item.img" alt="" />
                            <div class="activity-list-banner-item-intro">
                                <div>
                                    <div class="activity-list-banner-item-title" :class="$t('common.fontFamilyTitle')">
                                        {{ item.title }}
                                    </div>
                                    <div class="activity-list-banner-item-subtitle">
                                        {{ item.subtitle }}
                                    </div>
                                </div>
                                <a v-if="item.url" href="javascript:;" class="activity-list-banner-item-more">
                                    {{ $t("activityList.了解更多") }}<span class="iconfont icon-chevron-right"></span>
                                </a>
                                <a v-else class="activity-list-banner-item-more" style="color: #aaa;"
                                    href="javascript:;">{{
                                        $t("activityList.敬请期待") }}</a>
                            </div>
                        </div>
                    </div>
                    <div class="activity-list-pagination">
                        <div class="pagination-left" @click="handlePrev">
                            <span class="iconfont icon-pagination-left"></span>
                        </div>
                        <div class="pagination-pages">
                            <div class="pagination-page-number"
                                :class="{ 'pagination-page-number-selected': index + 1 == currentpage }"
                                v-for="(item, index) in pages" :key="index" @click="changePage(index)">
                                {{ index + 1 }}
                            </div>
                        </div>
                        <div class="pagination-right" @click="handleNext">
                            <span class="iconfont icon-pagination-right"></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="slogan-box">
            <div>{{ $t("activityList.每个认真交易的人") }}</div>
            <div>Where your goals begin</div>
        </div>
        <page-footer></page-footer>
    </div>
</template>
<script>
let PAGE_NAVIGATE_HEIGHT = 86;
function throttle(func, delay) {
    var timer = null;
    return function () {
        var that = this;
        var args = arguments;
        if (!timer) {
            timer = setTimeout(function () {
                func.call(that, args);
                timer = null;
            }, delay);
        }
    };
}
/**
 * 获取路由中的路由参数
 * @param urls
 * @returns {{}}
 */
function getAllUrlParams(urls) {
    var url = urls || location.href;
    // 用JS拿到URL，如果函数接收了URL，那就用函数的参数。如果没传参，就使用当前页面的URL
    var queryString = url ? url.split("?")[1] : window.location.search.slice(1);
    // 用来存储我们所有的参数
    var obj = {};
    // 如果没有传参，返回一个空对象
    if (!queryString) {
        return obj;
    }
    // stuff after # is not part of query string, so get rid of it
    queryString = queryString.split("#")[0];
    // 将参数分成数组
    var arr = queryString.split("&");
    for (var i = 0; i < arr.length; i++) {
        // 分离成key:value的形式
        var a = arr[i].split("=");
        // 将undefined标记为true
        var paramName = a[0];
        var paramValue = typeof a[1] === "undefined" ? true : a[1];
        if (paramName.match(/\[(\d+)?\]$/)) {
            // 如果paramName不存在，则创建key
            var key = paramName.replace(/\[(\d+)?\]/, "");
            if (!obj[key]) obj[key] = [];
            // 如果是索引数组 e.g. colors[2]
            if (paramName.match(/\[\d+\]$/)) {
                // 获取索引值并在对应的位置添加值
                var index = /\[(\d+)\]/.exec(paramName)[1];
                obj[key][index] = paramValue;
            } else {
                // 如果是其它的类型，也放到数组中
                obj[key].push(paramValue);
            }
        } else {
            // 处理字符串类型
            if (!obj[paramName]) {
                // 如果如果paramName不存在，则创建对象的属性
                obj[paramName] = paramValue;
            } else if (obj[paramName] && typeof obj[paramName] === "string") {
                // 如果属性存在，并且是个字符串，那么就转换为数组
                obj[paramName] = [obj[paramName]];
                obj[paramName].push(paramValue);
            } else {
                // 如果是其它的类型，还是往数组里丢
                obj[paramName].push(paramValue);
            }
        }
    }
    return obj;
}
export default {
    data() {
        return {
            currentmenu: 0,
            currentpage: 1,
            pageSize: 10,
            total: 0,
            scrollTop: 0,
            lineList: [],
            init: false,
        };
    },
    computed: {
        dataMeta() {
            return this.$t("activityList.activityListMetaData");
        },
        pages() {
            let pages = Math.ceil(this.total / this.pageSize);
            if (pages <= 0) {
                return 1;
            } else {
                return pages;
            }
        },
        currentData() {
            return this.dataMeta[this.currentmenu].list.slice(
                (this.currentpage - 1) * this.pageSize,
                this.currentpage * this.pageSize
            );
        },
    },
    created() { },
    mounted() {
        let params = getAllUrlParams(window.location.href);
        if (params.index) {
            this.handleCheckoutMenu(params.index);
        } else {
            this.handleCheckoutMenu(0);
        }
        this.scrollEventListener();
    },
    filters: {},
    methods: {
        toPath(item) {
            if (item.url) {
                if (item.target == "_blank") {
                    window.open(item.url, item.target);
                } else {
                    window.location.href = item.url;
                }
            }
        },
        handleCheckoutMenu(index) {
            if (index == this.currentmenu && this.init) {
                return;
            }
            window.scrollTo({ top: 0, behavior: 'smooth' });
            this.init = true;
            this.currentmenu = index;
            this.currentpage = 1;
            this.total = this.dataMeta[this.currentmenu].list.length;
            this.getTopList();
        },
        changePage(index) {
            index = index + 1;
            if (this.currentpage == index) {
                return;
            }
            this.currentpage = index;
            this.scrollTo(".activity-list-banner-box");
        },
        handlePrev() {
            if (this.currentpage <= 1) {
                return;
            }
            this.currentpage--;
            this.scrollTo(".activity-list-banner-box");
        },
        handleNext() {
            if (this.currentpage >= this.pages) {
                return;
            }
            this.currentpage++;
            this.scrollTo(".activity-list-banner-box");
        },
        scrollTo(elementName) {
            const targetElement = document.querySelector(elementName);
            window.scrollTo({
                top: targetElement.offsetTop - PAGE_NAVIGATE_HEIGHT,
                behavior: "smooth",
            });
        },
        scrollEventListener() {
            let _this = this;
            window.addEventListener(
                "scroll",
                throttle(() => {
                    _this.scrollTop = document.documentElement.scrollTop;
                }, 300)
            );
        },
        computedActivity(index) {
            if (index == 0) {
                return this.scrollTop < this.lineList[1];
            } else if (index === this.lineList.length - 1) {
                // 最后一个元素的范围是 [lineList[index], Infinity)
                return this.scrollTop >= this.lineList[index];
            } else {
                return (
                    this.scrollTop < this.lineList[index + 1] &&
                    this.scrollTop >= this.lineList[index]
                );
            }
        },
        getTopList() {
            if (this.currentmenu == this.dataMeta.length - 1) {
                setTimeout(() => {
                    this.$nextTick(() => {
                        let arr = this.currentData.map((el) => {
                            return (
                                document.getElementById("tag" + el.id).offsetTop -
                                PAGE_NAVIGATE_HEIGHT - 1
                            );
                        });
                        this.lineList = arr;
                    });
                })

            }
        },
    },
}
</script>
<style scoped>
@import url("../assets/css/rem.css");

@font-face {
    font-family: "Financier Display";
    src: url("../assets/font/FinancierDisplay-Bold.otf") format("opentype");
}



.acticity-list-session1 {
    position: relative;
    height: 350px;
    background-image: url(../assets/image/activityList/bg.png);
    background-repeat: no-repeat;
    background-size: cover;
}

.section1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    min-height: 350rem;
    padding: 20rem 0;
    /* background-image: url(../assets/image/activityList/bg_1.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center; */
    cursor: pointer;
    gap: 20rem;
}

#section1-cn {
    background-image: url(../assets/image/activityList/bg_2.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

#section1-zh {
    background-image: url(../assets/image/activityList/bg_2_zh.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

#section1-vi {
    background-image: url(../assets/image/activityList/bg_2_vi.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

#section1-th {
    background-image: url(../assets/image/activityList/bg_2_th.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

#section1-en {
    background-image: url(../assets/image/activityList/bg_2_en.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

#section1-ko {
    background-image: url(../assets/image/activityList/bg_2_kr.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

#section1-jp {
    background-image: url(../assets/image/activityList/bg_2_jp.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.section1 h1 {
    display: flex;
    justify-content: center;
    color: #fff;
    text-align: center;
    text-shadow: 0rem 3.415rem 2.277rem rgba(0, 0, 0, 0.45);
    font-size: 55rem;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    letter-spacing: 0.558rem;
    gap: 4rem;
}

.en .section1 h1 {
    font-size: 48rem;
}

.section1 h1 .ebc {
    font-size: 78.447rem;
    letter-spacing: 0.784rem;
    font-family: "Financier Display";
}


.subtitle {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12rem;
    color: #fff;
    text-align: center;
    font-size: 32rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1em;
    letter-spacing: 0.48rem;
    opacity: 0.7;
}

.en .subtitle {
    font-size: 20rem;
    font-weight: 500;
}

.title-icon {
    width: 30rem;
    height: 30rem;
}

.subtitle-line-left {
    height: 1px;
    max-width: 400rem;
    min-width: 30rem;
    flex: 1;
    background: linear-gradient(to left,
            rgba(255, 255, 255, 0.7),
            rgba(255, 255, 255, 0));
}

.subtitle .icon-Vector {
    font-size: 24rem;
}

.subtitle-line-right {
    height: 1px;
    flex: 1;
    max-width: 400rem;
    min-width: 30rem;
    background: linear-gradient(to right,
            rgba(255, 255, 255, 0.7),
            rgba(255, 255, 255, 0));
}

.acticity-list-session1-inner {
    margin: 0 auto;
    height: 100%;
    max-width: 1440px;
    display: flex;
    gap: 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.acticity-list-session1-title {
    color: #fff;
    text-align: center;
    font-size: 50px;
    font-style: normal;
    font-weight: 900;
    line-height: 120%;
}

.acticity-list-session1-subtitle {
    color: #fff;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 350;
    line-height: 140%;
}

.activity-list-wrapper {
    margin: 0 auto;
    max-width: 1440px;
    padding: 68px 260px 80px 40px;
}

.ar .activity-list-wrapper {
    padding: 68px 40px 80px 260px;
}

.activity-list-wrapper {
    /* position: relative; */
    width: 100%;
    display: flex;
}

.activity-list-time-line-wrapper {
    position: sticky;
    margin-top: 90px;
    margin-left: 50px;
    top: 200px;
    width: 190px;
    height: 100%;
    flex-shrink: 0;
}

.activity-list-time-line-list-inner {
    display: flex;
    flex-direction: column;
    gap: 30px;
    max-width: 250px;
}

.activity-list-time-line-list-inner::before {
    position: absolute;
    left: 0;
    top: 10px;
    content: "";
    width: 1px;
    height: calc(100% - 20px);
    background-color: #e6ddcf;
}

.activity-list-time-line-link {
    position: relative;
    padding-left: 20px;
    color: #5d2333;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    z-index: 999;
}

.activity-list-time-line-link:hover {
    color: #a52933;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 21px;
}

.activity-list-time-line-link .icon-Vector {
    position: absolute;
    left: -7px;
    top: 0;
    color: #e6ddcf;
}

.activity-list-time-line-link:hover .icon-Vector {
    color: #ff4b4e;
}

.activity-list-time-line-link-active {
    color: #a52933;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 21px;
}

.activity-list-time-line-link-active .icon-Vector {
    color: #ff4b4e;
}

.activity-list-banner-box {
    width: calc(100% - 240px);
    /* height: 2000px; */
    flex-shrink: 0;
    /* background-color: azure; */
}

.activity-list-top-menu-box {
    position: sticky;
    top: 88rem;
    background: #fff;
    box-shadow: 0px 2px 10px 0px rgba(61, 1, 1, 0.08);
    z-index: 9;
}

.activity-list-top-menu {
    max-width: 1400px;
    padding: 0 260px;
    margin: 0 auto;
    display: flex;
    justify-content: center;

    /* margin-bottom: 15px; */
}

.activity-list-top-menu-item {
    cursor: pointer;
    padding: 25px 10px;
    text-align: center;
    color: #b2afad;
    font-size: 26px;
    font-style: normal;
    font-weight: 800;
    line-height: 120%;
    width: 33.3%;
    /* border- */
    /* border-bottom: 1px solid #9f734c; */
    transition: color 0.4s;
}

.activity-list-top-menu-item:hover,
.activity-list-top-menu-item-selected {
    color: #A52933;
    padding-bottom: 24px;
    border-bottom: 2px solid #A52933;
}

.activity-list-banner-item {
    padding-top: 40px;
}

.activity-list-banner-item-img {
    /* margin-top: 40px; */
    /* height: 330px; */
    width: 100%;
    object-fit: cover;
}

.activity-list-banner-item-intro {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 30px 34px;
    background: #f9f6f2;
}

.activity-list-banner-item-title {
    color: #232222;
    font-size: 30px;
    font-style: normal;
    font-weight: 800;
    line-height: 120%;
    max-width: 500px;
}

.activity-list-banner-item-subtitle {
    margin-top: 17px;
    color: #6a6967;
    font-size: 18px;
    font-style: normal;
    font-weight: 350;
    line-height: 140%;
    max-width: 500px;
}

.activity-list-banner-item-more {
    display: flex;
    flex-shrink: 0;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    color: #9f734c;
    transition: all 0.4s;
}

.activity-list-banner-item-more .icon-chevron-right {
    color: #9f734c;
    font-size: 18px;
    margin-left: 10px;
    transition: all 0.4s;
}

.activity-list-banner-item-more:hover,
.activity-list-banner-item-more:hover .icon-chevron-right {
    color: #a52933;
}

/* 翻页 */
.activity-list-pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 50px;
    margin-top: 80px;
}

.activity-list-pagination .icon-pagination-left,
.activity-list-pagination .icon-pagination-right {
    cursor: pointer;
    font-size: 40px;
    color: #a52933;

    padding: 0 10px;
}

.pagination-pages {
    display: flex;
    align-items: center;
    gap: 20px;
}

.pagination-page-number {
    cursor: pointer;
    width: 40px;
    height: 40px;
    font-family: Gilroy;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    text-align: center;
    line-height: 40px;
    color: #a52933;
    /* background-color: #fff; */
    border-radius: 50%;
    transition: all 0.27s;
}

.pagination-page-number:hover,
.pagination-page-number-selected {
    background-color: #a52933;
    color: #fff;
    overflow: hidden;
}

.slogan-box {
    display: flex;
    flex-direction: column;
    gap: 16px;
    background: #f9f6f2;
    padding: 28px;
    color: #3d0101;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
}

@media (max-width: 996px) {
    .section1 {
    height: 250rem;
  }
    .section1 h1 {
        font-size: 39rem;
        line-height: 48rem;
    }

    .en .section1 h1 {
        font-size: 31rem;
    }

    .section1 h1 .ebc {
        font-size: 55rem;
        line-height: 55rem;
    }

    .subtitle {
        margin-top: 4rem;
        font-size: 24rem;
        font-weight: 500;
    }

    .en .subtitle {
        font-size: 16rem;
    }

    .cn .acticity-list-session1-title {
        font-size: 50px;
    }

    .acticity-list-session1-title {
        font-size: 40px;
    }

    .acticity-list-session1 {
        padding: 0 24px;
    }

    .activity-list-wrapper {
        padding: 44px 24px;
    }

    .activity-list-banner-box {
        width: 100%;
    }

    .activity-list-banner-item-img {
        width: 100%;
        height: auto;
    }

    .activity-list-top-menu-box {
        top: 80px;
    }

    .activity-list-top-menu {
        /* top: 80px; */
        padding: 0;
    }

    .cn .activity-list-top-menu-item {
        font-size: 18px;
        width: 33.3%;
    }

    .activity-list-top-menu-item {
        font-size: 16px;
        width: 50%;
    }

    .activity-list-banner-item-intro {
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
    }

    .activity-list-banner-item-title {
        font-size: 22px;
    }

    .activity-list-banner-item-subtitle {
        margin-top: 20px;
        font-size: 16px;
    }

    .activity-list-banner-item-more {
        font-size: 16px;
    }

    .activity-list-pagination {
        gap: 40px;
    }

    .pagination-pages {
        gap: 14px;
    }

    .activity-list-pagination .icon-pagination-left,
    .activity-list-pagination .icon-pagination-right {
        font-size: 28px;
    }

    .pagination-page-number {
        width: 28px;
        height: 28px;
        line-height: 28px;
        font-size: 14px;
    }
}
</style>