<template>
	<div :class="[$t('common.fontFamily'), $t('common.lang'), $t('common.type')]">
		<page-navigate></page-navigate>
		<section class="section1">
			<h1 :class="$t('common.fontFamilyTitle')">{{ $t("AllProducts.全部交易产品") }}</h1>
			<div class="mt-60">
				<ProductModule></ProductModule>
			</div>
		</section>
		<ss-contact-us-new mode="all"></ss-contact-us-new>
		<page-footer></page-footer>
	</div>
</template>

<script>
export default {
	data() {
		return {

		}
	},
	created() { },
	methods: {

	}
}
</script>

<style lang="scss" scoped>
.section1 h1 {
	color: #a52933;
	text-align: center;
	font-size: 36rem;
	font-style: normal;
	font-weight: 900;
	line-height: 1em;
}

.section1 {
	padding: 60rem 150rem 120rem;
}

.mt-60 {
	margin-top: 60rem;
}

@media (max-width: 996px) {
	.section4 h1 {
		font-size: 40rem;
	}

	.section1 {
		padding: 100rem 28rem;
	}
}
</style>
