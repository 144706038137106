<template>
  <div class="copytrading-body cn" :class="$t('from0tohero.box-name')">
    <page-navigate></page-navigate>

    <div class="copytrading-fth1">
      <div class="container">
        <p :class="`p1 ${$t('TradingView.tradingView.tvlang')}-font-family-title`">{{ $t('from0tohero.pages1.title') }}
        </p>
        <p class="p2">{{ $t('from0tohero.pages1.tips') }}</p>
        <p class="copytrading-fth-line"></p>
      </div>
    </div>

    <div class="copytrading-fth2" id="fth2" ref="navRef">
      <a href="javascript:;" id="copytrading-subnav-1" class="button-default sub-nav"
        :class="{ active: activeButtonIndex === 0 }" @click="navigateToSection(0, 'copytrading-subnav-1')">
        {{ $t('from0tohero.pages1.text') }}
      </a>
      <a href="javascript:;" id="copytrading-subnav-2" class="button-default sub-nav"
        :class="{ active: activeButtonIndex === 1 }" @click="navigateToSection(1, 'copytrading-subnav-2')">
        {{ $t('from0tohero.pages1.text1') }}
      </a>
      <a href="javascript:;" id="copytrading-subnav-3" class="button-default sub-nav"
        :class="{ active: activeButtonIndex === 2 }" @click="navigateToSection(2, 'copytrading-subnav-3')">
        {{ $t('pay.交易领薪计划') }}
      </a>
      <a href="javascript:;" id="copytrading-subnav-4" class="button-default sub-nav"
        :class="{ active: activeButtonIndex === 3 }" @click="navigateToSection(3, 'copytrading-subnav-4')">
        {{ $t('from0tohero.pages1.text3') }}
      </a>
      <a id="transmit5" rel="nofollow" :href="$store.state.khUrl" target="_blank">
        {{ $t('from0tohero.pages1.text4') }}
      </a>
    </div>

    <div class="copytrading-fth3" id="fth3" data-section="copytrading-subnav-1">
      <div class="container">
        <p :class="`copytrading-public-title ${$t('TradingView.tradingView.tvlang')}-font-family-title`">{{
          $t('from0tohero.pages2.title') }}</p>
        <p class="copytrading-public-line"></p>
        <div class="row">
          <div class="col-md-2" v-for="(item, index) in $t('from0tohero.pages2.list')">
            <p class="p1">{{ index + 1 }}</p>
            <p :class="`p2 ${$t('TradingView.tradingView.tvlang')}-font-family-title`" v-html="item.tit"></p>
            <p class="p3" v-html="item.tips"></p>
          </div>
        </div>
        <div class="copytrading-link"><a class="copytrading-public-link" rel="nofollow" :href="$store.state.khUrl"
            target="_blank">{{ $t('from0tohero.pages2.btn') }}</a></div>
      </div>
    </div>


    <div class="copytrading-fth4" id="fth4" data-section="copytrading-subnav-2">
      <div class="container">
        <p :class="`copytrading-public-title ${$t('TradingView.tradingView.tvlang')}-font-family-title`">{{
          $t('from0tohero.pages3.title') }}</p>
        <p class="copytrading-public-line"></p>
        <div class="row r1 signal_list">
          <swiper class="signal_list" :options="signalSwiperOptions" ref="signalSwiper">

            <swiper-slide class="col-md-3 list swiper-slide" v-for="(item, index) in infoData" :key="index"
              v-if="index < 4">
              <p class="profile-name">{{ item.accountName.slice(0, 1) }}</p>
              <img src="../assets/image/copytrading/profile-bg.svg" alt="">
              <p class="p1">{{ item.accountName }}</p>
              <p class="p3">{{ (item.maxProfit).toFixed(2) }}%</p>
              <p class="p4">{{ $t('from0tohero.pages3.text') }}</p>
              <div class="row r2">
                <div class="col-md-4">
                  <p class="sp1">{{ (item.maxDrawdown).toFixed(2) }}%</p>
                  <p class="sp2">{{ $t('from0tohero.pages3.text1') }}</p>
                </div>
                <div class="col-md-4">
                  <p class="sp1">{{ item.sharpeRatio.toFixed(2) }}</p>
                  <p class="sp2">{{ $t('from0tohero.pages3.text2') }}</p>
                </div>
                <div class="col-md-4">
                  <p class="sp1">{{ item.recoveryFactor }}</p>
                  <p class="sp2">{{ $t('from0tohero.pages3.text3') }}</p>
                </div>
              </div>
              <p class="p5"><a rel="nofollow" :href="$store.state.khUrl" target="_blank">{{
                $t('from0tohero.pages3.text5') }}</a></p>
            </swiper-slide>

            <!-- <swiper-slide class="col-md-3 list" v-for="(item, index) in infoData" :key="index"  
                    v-if="index < 4">
                    <p class="profile-name">{{ item.signalName.slice(0, 1) }}</p>
                    <img src="../assets/image/copytrading/profile-bg.svg" alt="">
                    <p class="p1">{{ item.signalName }}</p>
                    <p class="p3">{{ (item.growth * 100).toFixed(2) }}%</p>
                    <p class="p4">{{ $t('from0tohero.pages3.text') }}</p>
                    <div class="row r2">
                        <div class="col-md-4">
                            <p class="sp1">{{ (item.drawdown * 100).toFixed(2) }}%</p>
                            <p class="sp2">{{ $t('from0tohero.pages3.text1') }}</p>
                        </div>
                        <div class="col-md-4">
                            <p class="sp1">{{ item.sharpRatio.toFixed(2) }}</p>
                            <p class="sp2">{{ $t('from0tohero.pages3.text2') }}</p>
                        </div>
                        <div class="col-md-4">
                            <p class="sp1">{{ (item.plr * 100).toFixed(2) }}%</p>
                            <p class="sp2">{{ $t('from0tohero.pages3.text3') }}</p>
                        </div>
                    </div>
                    <p class="p5"><a rel="nofollow" :href="$store.state.khUrl" target="_blank">{{ $t('from0tohero.pages3.text5') }}</a></p>
                </swiper-slide>  -->

          </swiper>
        </div>
        <div class="copytrading-link"><a class="copytrading-public-link" rel="nofollow" :href="$store.state.khUrl"
            target="_blank">{{ $t('from0tohero.pages3.btn') }}</a></div>
      </div>
    </div>


    <div class="copytrading-pay-session2 pay-session2" data-section="copytrading-subnav-3">
      <div class="container">
        <p :class="`copytrading-public-title ${$t('TradingView.tradingView.tvlang')}-font-family-title`">{{
          $t('pay.交易领薪计划') }}</p>
        <p class="copytrading-public-line"></p>
        <div class="pc">
          <div class="pay-session2-list">
            <div class="pay-session2-card pay-session2-card-light">
              <div class="pay-session2-card-content">
                <h2>
                  <span>{{ $t('pay.传奇交易员') }}</span>
                </h2>
                <p class="p1">
                  <span class="number">18</span>
                  <span class="unit">%</span>
                </p>
                <p class="p2">{{ $t('pay.年化资金管理费') }}</p>
              </div>
              <div class="pay-session2-card-bottom">
                <div class="pay-session2-card-bottom-item">
                  <span>>$1,000,000 </span>
                  <span>{{ $t('pay.管理资金量') }} </span>
                </div>
                <div class="pay-session2-card-bottom-line"></div>
                <div class="pay-session2-card-bottom-item">
                  <span>>200 </span>
                  <span>{{ $t('pay.跟随账户数') }} </span>
                </div>
              </div>

              <div class="pay-session2-card-content-icon">
                <img src="../assets/image/pay/session2_card_icon1.png" alt="" />
              </div>
            </div>
            <div class="pay-session2-card">
              <div class="pay-session2-card-content">
                <h2>
                  <span>{{ $t('pay.明星交易员') }}</span>
                </h2>
                <p class="p1">
                  <span class="number">15</span>
                  <span class="unit">%</span>
                </p>
                <p class="p2">{{ $t('pay.年化资金管理费') }}</p>
              </div>
              <div class="pay-session2-card-bottom">
                <div class="pay-session2-card-bottom-item">
                  <span>>$500,000 </span>
                  <span>{{ $t('pay.管理资金量') }} </span>
                </div>
                <div class="pay-session2-card-bottom-line"></div>
                <div class="pay-session2-card-bottom-item">
                  <span>>100 </span>
                  <span>{{ $t('pay.跟随账户数') }} </span>
                </div>
              </div>

              <div class="pay-session2-card-content-icon">
                <img src="../assets/image/pay/session2_card_icon2.png" alt="" />
              </div>
            </div>
            <div class="pay-session2-card">
              <div class="pay-session2-card-content">
                <h2>
                  <span>{{ $t('pay.冠军交易员') }}</span>
                </h2>
                <p class="p1">
                  <span class="number">10</span>
                  <span class="unit">%</span>
                </p>
                <p class="p2">{{ $t('pay.年化资金管理费') }}</p>

              </div>
              <div class="pay-session2-card-bottom">
                <div class="pay-session2-card-bottom-item">
                  <span>>$100,000 </span>
                  <span>{{ $t('pay.管理资金量') }} </span>


                </div>
                <div class="pay-session2-card-bottom-line"></div>
                <div class="pay-session2-card-bottom-item">
                  <span>>50 </span>
                  <span>{{ $t('pay.跟随账户数') }} </span>
                </div>
              </div>

              <div class="pay-session2-card-content-icon">
                <img src="../assets/image/pay/session2_card_icon3.png" alt="" />
              </div>
            </div>
            <div class="pay-session2-card">
              <div class="pay-session2-card-content">
                <h2>
                  <span>{{ $t('pay.大师交易员') }}</span>
                </h2>
                <p class="p1">
                  <span class="number">8</span>
                  <span class="unit">%</span>
                </p>
                <p class="p2">{{ $t('pay.年化资金管理费') }}</p>
              </div>
              <div class="pay-session2-card-bottom">
                <div class="pay-session2-card-bottom-item">
                  <span>>$50,000 </span>
                  <span>{{ $t('pay.管理资金量') }} </span>
                </div>
                <div class="pay-session2-card-bottom-line"></div>
                <div class="pay-session2-card-bottom-item">
                  <span>>30 </span>
                  <span>{{ $t('pay.跟随账户数') }} </span>
                </div>
              </div>

              <div class="pay-session2-card-content-icon">
                <img src="../assets/image/pay/session2_card_icon4.png" alt="" />
              </div>
            </div>
            <div class="pay-session2-card">
              <div class="pay-session2-card-content">
                <h2>
                  <span>{{ $t('pay.精英交易员') }}</span>
                </h2>
                <p class="p1">
                  <span class="number">5</span>
                  <span class="unit">%</span>
                </p>
                <p class="p2">{{ $t('pay.年化资金管理费') }}</p>
              </div>
              <div class="pay-session2-card-bottom">
                <div class="pay-session2-card-bottom-item">
                  <span>>$20,000 </span>
                  <span>{{ $t('pay.管理资金量') }} </span>
                </div>
                <div class="pay-session2-card-bottom-line"></div>
                <div class="pay-session2-card-bottom-item">
                  <span>≥10</span>
                  <span>{{ $t('pay.跟随账户数') }} </span>
                </div>
              </div>

              <div class="pay-session2-card-content-icon">
                <img src="../assets/image/pay/session2_card_icon5.png" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div class="mobile">
          <div class="pay-session2-list">
            <swiper ref="reward-list" :options="options">

              <swiper-slide>
                <div class="pay-session2-card pay-session2-card-light">
                  <div class="pay-session2-card-content">
                    <h2>
                      <span>{{ $t('pay.传奇交易员') }}</span>
                    </h2>
                    <p class="p1">
                      <span class="number">18</span>
                      <span class="unit">%</span>
                    </p>
                    <p class="p2">{{ $t('pay.年化资金管理费') }}</p>
                  </div>
                  <div class="pay-session2-card-bottom">
                    <div class="pay-session2-card-bottom-item">
                      <span>>$1,000,000 </span>
                      <span>{{ $t('pay.管理资金量') }} </span>
                    </div>
                    <div class="pay-session2-card-bottom-line"></div>
                    <div class="pay-session2-card-bottom-item">
                      <span>>200 </span>
                      <span>{{ $t('pay.跟随账户数') }} </span>
                    </div>
                  </div>

                  <div class="pay-session2-card-content-icon">
                    <img src="../assets/image/pay/session2_card_icon1.png" alt="" />
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide>
                <div class="pay-session2-card">
                  <div class="pay-session2-card-content">
                    <h2>
                      <span>{{ $t('pay.明星交易员') }}</span>
                    </h2>
                    <p class="p1">
                      <span class="number">15</span>
                      <span class="unit">%</span>
                    </p>
                    <p class="p2">{{ $t('pay.年化资金管理费') }}</p>
                  </div>
                  <div class="pay-session2-card-bottom">
                    <div class="pay-session2-card-bottom-item">
                      <span>>$500,000 </span>
                      <span>{{ $t('pay.管理资金量') }} </span>
                    </div>
                    <div class="pay-session2-card-bottom-line"></div>
                    <div class="pay-session2-card-bottom-item">
                      <span>>100 </span>
                      <span>{{ $t('pay.跟随账户数') }} </span>
                    </div>
                  </div>

                  <div class="pay-session2-card-content-icon">
                    <img src="../assets/image/pay/session2_card_icon2.png" alt="" />
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide>
                <div class="pay-session2-card">
                  <div class="pay-session2-card-content">
                    <h2>
                      <span>{{ $t('pay.冠军交易员') }}</span>
                    </h2>
                    <p class="p1">
                      <span class="number">10</span>
                      <span class="unit">%</span>
                    </p>
                    <p class="p2">{{ $t('pay.年化资金管理费') }}</p>
                  </div>
                  <div class="pay-session2-card-bottom">
                    <div class="pay-session2-card-bottom-item">
                      <span>>$100,000 </span>
                      <span>{{ $t('pay.管理资金量') }} </span>
                    </div>
                    <div class="pay-session2-card-bottom-line"></div>
                    <div class="pay-session2-card-bottom-item">
                      <span>>50 </span>
                      <span>{{ $t('pay.跟随账户数') }} </span>
                    </div>
                  </div>

                  <div class="pay-session2-card-content-icon">
                    <img src="../assets/image/pay/session2_card_icon3.png" alt="" />
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide>
                <div class="pay-session2-card">
                  <div class="pay-session2-card-content">
                    <h2>
                      <span>{{ $t('pay.大师交易员') }}</span>
                    </h2>
                    <p class="p1">
                      <span class="number">8</span>
                      <span class="unit">%</span>
                    </p>
                    <p class="p2">{{ $t('pay.年化资金管理费') }}</p>
                  </div>
                  <div class="pay-session2-card-bottom">
                    <div class="pay-session2-card-bottom-item">
                      <span>>$50,000 </span>
                      <span>{{ $t('pay.管理资金量') }} </span>
                    </div>
                    <div class="pay-session2-card-bottom-line"></div>
                    <div class="pay-session2-card-bottom-item">
                      <span>>30 </span>
                      <span>{{ $t('pay.跟随账户数') }} </span>
                    </div>
                  </div>

                  <div class="pay-session2-card-content-icon">
                    <img src="../assets/image/pay/session2_card_icon4.png" alt="" />
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide>
                <div class="pay-session2-card">
                  <div class="pay-session2-card-content">
                    <h2>
                      <span>{{ $t('pay.精英交易员') }}</span>
                    </h2>
                    <p class="p1">
                      <span class="number">5</span>
                      <span class="unit">%</span>
                    </p>
                    <p class="p2">{{ $t('pay.年化资金管理费') }}</p>
                  </div>
                  <div class="pay-session2-card-bottom">
                    <div class="pay-session2-card-bottom-item">
                      <span>>$20,000 </span>
                      <span>{{ $t('pay.管理资金量') }} </span>
                    </div>
                    <div class="pay-session2-card-bottom-line"></div>
                    <div class="pay-session2-card-bottom-item">
                      <span>≥10</span>
                      <span>{{ $t('pay.跟随账户数') }} </span>
                    </div>
                  </div>

                  <div class="pay-session2-card-content-icon">
                    <img src="../assets/image/pay/session2_card_icon5.png" alt="" />
                  </div>
                </div>
              </swiper-slide>
            </swiper>
          </div>
        </div>
        <div class="copytrading-link"><a class="copytrading-public-link" rel="nofollow" :href="$store.state.khUrl"
            target="_blank">{{ $t('Index.了解更多') }}</a></div>
      </div>
    </div>

    <div class="copytrading-pay-session3 pay-session3" data-section="copytrading-subnav-4">
      <div class="container">
        <p :class="`copytrading-public-title ${$t('TradingView.tradingView.tvlang')}-font-family-title`">{{
          $t('from0tohero.pages5.title') }}</p>
        <p class="copytrading-public-line"></p>
        <div class="pc">
          <div class="pay-session3-list">
            <div class="pay-session3-li">
              <div class="pay-session3-li-top">{{ $t('pay.精英/大师交易员') }}</div>
              <div class="pay-session3-li-content">
                <div class="pay-session3-li-content-bg1">
                  <p class="pay-session3-li-content-text">{{ $t('pay.7×24小时优先客服') }}</p>
                  <p class="pay-session3-li-content-text">
                    {{ $t('pay.金融投资经典书籍礼遇') }}
                  </p>
                </div>
              </div>
            </div>
            <div class="pay-session3-li">
              <div class="pay-session3-li-top pay-session3-li-top2">
                {{ $t('pay.冠军交易员') }}
              </div>
              <div class="pay-session3-li-content">
                <div class="pay-session3-li-content-bg2">
                  <p class="pay-session3-li-content-text">{{ $t('pay.7×24小时优先客服') }}</p>
                  <p class="pay-session3-li-content-text">
                    {{ $t('pay.金融投资经典书籍礼遇') }}
                  </p>
                  <p class="pay-session3-li-content-text pay-session3-li-content-text-light">
                    {{ $t('pay.专属VPS赞助') }}
                  </p>
                  <p class="pay-session3-li-content-text pay-session3-li-content-text-light">
                    {{ $t('pay.订单流软件1年使用权') }}
                  </p>
                  <p class="pay-session3-li-content-text pay-session3-li-content-text-light">
                    {{ $t('pay.EBC定制大礼包') }}
                  </p>
                </div>
              </div>
            </div>
            <div class="pay-session3-li">
              <div class="pay-session3-li-top pay-session3-li-top3">
                {{ $t('pay.明星交易员') }}
              </div>
              <div class="pay-session3-li-content">
                <div class="pay-session3-li-content-bg3">
                  <p class="pay-session3-li-content-text">{{ $t('pay.7×24小时优先客服') }}</p>
                  <p class="pay-session3-li-content-text">
                    {{ $t('pay.金融投资经典书籍礼遇') }}
                  </p>
                  <p class="pay-session3-li-content-text">{{ $t('pay.专属VPS赞助') }}</p>
                  <p class="pay-session3-li-content-text">
                    {{ $t('pay.订单流软件1年使用权') }}
                  </p>
                  <p class="pay-session3-li-content-text">{{ $t('pay.EBC定制大礼包') }}</p>
                  <p class="pay-session3-li-content-text pay-session3-li-content-text-light">
                    {{ $t('pay.信号优先置顶展示') }}
                  </p>
                  <p class="pay-session3-li-content-text pay-session3-li-content-text-light">
                    {{ $t('pay.受邀参观海外办公室') }}
                  </p>
                  <p class="pay-session3-li-content-text pay-session3-li-content-text-light">
                    {{ $t('pay.巴萨球星联名礼包') }}
                  </p>
                  <p class="pay-session3-li-content-text pay-session3-li-content-text-light">
                    {{ $t('pay.巴萨赛场传奇之旅') }}
                  </p>
                </div>
              </div>
            </div>
            <div class="pay-session3-li">
              <div class="pay-session3-li-top pay-session3-li-top4">
                <span>{{ $t('pay.传奇交易员') }}</span>

              </div>
              <div class="pay-session3-li-content">
                <div class="pay-session3-li-content-bg4">
                  <p class="pay-session3-li-content-text">{{ $t('pay.7×24小时优先客服') }}</p>
                  <p class="pay-session3-li-content-text">
                    {{ $t('pay.金融投资经典书籍礼遇') }}
                  </p>
                  <p class="pay-session3-li-content-text">{{ $t('pay.专属VPS赞助') }}</p>
                  <p class="pay-session3-li-content-text">
                    {{ $t('pay.订单流软件1年使用权') }}
                  </p>
                  <p class="pay-session3-li-content-text">{{ $t('pay.EBC定制大礼包') }}</p>
                  <p class="pay-session3-li-content-text">{{ $t('pay.信号优先置顶展示') }}</p>
                  <p class="pay-session3-li-content-text">{{ $t('pay.受邀参观海外办公室') }}</p>
                  <p class="pay-session3-li-content-text">{{ $t('pay.巴萨球星联名礼包') }}</p>
                  <p class="pay-session3-li-content-text pay-session3-li-content-text-light">
                    {{ $t('pay.巴萨赛场传奇之旅') }}
                  </p>
                  <p class="pay-session3-li-content-text pay-session3-li-content-text-light">
                    {{ $t('pay.品牌战略合作') }}
                  </p>
                  <p class="pay-session3-li-content-text pay-session3-li-content-text-light">
                    {{ $t('pay.全球金融资源管家服务') }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mobile">
          <div class="pay-session3-list">
            <swiper ref="reward-list" :options="options1">
              <swiper-slide>
                <div class="pay-session3-li">
                  <div class="pay-session3-li-top pay-session3-li-top4">
                    <span>{{ $t('pay.传奇交易员') }}</span>
                  </div>
                  <div class="pay-session3-li-content">
                    <div class="pay-session3-li-content-bg4">
                      <p class="pay-session3-li-content-text">
                        {{ $t('pay.7×24小时优先客服') }}
                      </p>
                      <p class="pay-session3-li-content-text">
                        {{ $t('pay.金融投资经典书籍礼遇') }}
                      </p>
                      <p class="pay-session3-li-content-text">{{ $t('pay.专属VPS赞助') }}</p>
                      <p class="pay-session3-li-content-text">
                        {{ $t('pay.订单流软件1年使用权') }}
                      </p>
                      <p class="pay-session3-li-content-text">
                        {{ $t('pay.EBC定制大礼包') }}
                      </p>
                      <p class="pay-session3-li-content-text">
                        {{ $t('pay.信号优先置顶展示') }}
                      </p>
                      <p class="pay-session3-li-content-text">
                        {{ $t('pay.受邀参观海外办公室') }}
                      </p>
                      <p class="pay-session3-li-content-text">
                        {{ $t('pay.巴萨球星联名礼包') }}
                      </p>
                      <p class="pay-session3-li-content-text pay-session3-li-content-text-light">
                        {{ $t('pay.巴萨赛场传奇之旅') }}
                      </p>
                      <p class="pay-session3-li-content-text pay-session3-li-content-text-light">
                        {{ $t('pay.品牌战略合作') }}
                      </p>
                      <p class="pay-session3-li-content-text pay-session3-li-content-text-light">
                        {{ $t('pay.全球金融资源管家服务') }}
                      </p>
                    </div>
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide>
                <div class="pay-session3-li">
                  <div class="pay-session3-li-top pay-session3-li-top3">
                    {{ $t('pay.明星交易员') }}
                  </div>
                  <div class="pay-session3-li-content">
                    <div class="pay-session3-li-content-bg3">
                      <p class="pay-session3-li-content-text">
                        {{ $t('pay.7×24小时优先客服') }}
                      </p>
                      <p class="pay-session3-li-content-text">
                        {{ $t('pay.金融投资经典书籍礼遇') }}
                      </p>
                      <p class="pay-session3-li-content-text">{{ $t('pay.专属VPS赞助') }}</p>
                      <p class="pay-session3-li-content-text">
                        {{ $t('pay.订单流软件1年使用权') }}
                      </p>
                      <p class="pay-session3-li-content-text">
                        {{ $t('pay.EBC定制大礼包') }}
                      </p>
                      <p class="pay-session3-li-content-text pay-session3-li-content-text-light">
                        {{ $t('pay.信号优先置顶展示') }}
                      </p>
                      <p class="pay-session3-li-content-text pay-session3-li-content-text-light">
                        {{ $t('pay.受邀参观海外办公室') }}
                      </p>
                      <p class="pay-session3-li-content-text pay-session3-li-content-text-light">
                        {{ $t('pay.巴萨球星联名礼包') }}
                      </p>
                      <p class="pay-session3-li-content-text pay-session3-li-content-text-light">
                        {{ $t('pay.巴萨赛场传奇之旅') }}
                      </p>
                    </div>
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide>
                <div class="pay-session3-li">
                  <div class="pay-session3-li-top pay-session3-li-top2">
                    {{ $t('pay.冠军交易员') }}
                  </div>
                  <div class="pay-session3-li-content">
                    <div class="pay-session3-li-content-bg2">
                      <p class="pay-session3-li-content-text">
                        {{ $t('pay.7×24小时优先客服') }}
                      </p>
                      <p class="pay-session3-li-content-text">
                        {{ $t('pay.金融投资经典书籍礼遇') }}
                      </p>
                      <p class="pay-session3-li-content-text pay-session3-li-content-text-light">
                        {{ $t('pay.专属VPS赞助') }}
                      </p>
                      <p class="pay-session3-li-content-text pay-session3-li-content-text-light">
                        {{ $t('pay.订单流软件1年使用权') }}
                      </p>
                      <p class="pay-session3-li-content-text pay-session3-li-content-text-light">
                        {{ $t('pay.EBC定制大礼包') }}
                      </p>
                    </div>
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide>
                <div class="pay-session3-li">
                  <div class="pay-session3-li-top">{{ $t('pay.精英/大师交易员') }}</div>
                  <div class="pay-session3-li-content">
                    <div class="pay-session3-li-content-bg1">
                      <p class="pay-session3-li-content-text">
                        {{ $t('pay.7×24小时优先客服') }}
                      </p>
                      <p class="pay-session3-li-content-text">
                        {{ $t('pay.金融投资经典书籍礼遇') }}
                      </p>
                    </div>
                  </div>
                </div>
              </swiper-slide>
              <!-- 导航按钮 -->
              <div class="swiper-button-prev my-prev" slot="button-prev"></div>
              <div class="swiper-button-next my-next" slot="button-next"></div>
              <!-- 如果需要分页器 -->
              <!-- <div class="swiper-pagination my-pagination" slot="pagination"></div> -->
            </swiper>
          </div>
        </div>
        <div class="copytrading-link"><a class="copytrading-public-link" rel="nofollow" :href="$store.state.khUrl"
            target="_blank">{{ $t('from0tohero.pages5.btn') }}</a></div>
      </div>
    </div>




    <div class="copytrading-QA">
      <p :class="`copytrading-public-title ${$t('TradingView.tradingView.tvlang')}-font-family-title`">{{
        $t('from0tohero.QA.title') }}</p>
      <p class="copytrading-public-line"></p>
      <div class="problem-box">
        <div class="vision-item__line d-block d-md-none"></div>
        <div id="accordionExample" class="accordion">
          <div id="accordionFlushExample" class="accordion accordion-flush">
            <div class="accordion-item" v-for="index in 6" :key="index - 1">
              <h2 :id="'flush-heading' + (index - 1)" class="accordion-header">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  :data-bs-target="'#flush-collapse' + (index - 1)" aria-expanded="false"
                  :aria-controls="'flush-collapse' + (index - 1)">
                  {{ $t(`from0tohero.QA.Q${index}`) }}
                </button>
              </h2>
              <div :id="'flush-collapse' + (index - 1)" class="accordion-collapse collapse cont-info"
                :aria-labelledby="'flush-heading' + (index - 1)" data-bs-parent="#accordionFlushExample">
                <div class="accordion-body">
                  {{ $t(`from0tohero.QA.A${index}`) }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>




    <ss-contact-us-new mode="all"></ss-contact-us-new>


    <page-footer></page-footer>
  </div>
</template>
<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
export default {
  components: {
    Swiper,
    SwiperSlide
  },
  metaInfo() {
    return {
      title: this.seoInfo.title || '',
      meta: [ // set meta
        {
          name: 'keyWords',
          content: this.seoInfo.keyWords || ''
        },
        {
          name: 'description',
          content: this.seoInfo.description || ''
        }
      ]
    }
  },
  data() {
    return {
      seoInfo: "",
      infoData: "",
      activeButtonIndex: 0,
      navHeight: 0,
      stickyTop: 87,
      lastScrollTime: 0,
      signalSwiperOptions: {
        slidesPerView: 1.2,
        spaceBetween: 8,
        freeMode: true,
        breakpoints: {
          995: {
            slidesPerView: 4.15,
            spaceBetween: 0,
            freeMode: false,
            allowTouchMove: false,
            enabled: false
          }
        }
      },
      paySession2Options: {
        slidesPerView: 1.3,
        spaceBetween: 8,
        freeMode: true,
      },
      paySession3Options: {
        slidesPerView: 1.3,
        spaceBetween: 8,
        freeMode: true,
      },
      swiperInstance: null,
      options: {
        slidesPerView: 1.5,
        spaceBetween: 15,
        freeMode: true,
      },
      options1: {
        loop: true, // 循环模式选项

        // 如果需要分页器
        pagination: {
          el: ".swiper-pagination",
          bulletActiveClass: "my-bullet-active",
          clickable: true,
        },

        // 如果需要前进后退按钮
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      }
    }
  },
  created() {
    this.$getSeoInfo({
      lang: this.$t("common.requestLang"),//this.$store.state.Lan,
      path: "/from0tohero"
    }).then(res => {
      this.seoInfo = res
    })
    this.getData()
  },
  mounted() {
    // 初始化导航高度  
    this.$nextTick(() => {
      const nav = document.getElementById('fth2');
      this.navHeight = nav ? nav.getBoundingClientRect().height : 0;

      // 添加滚动监听  
      window.addEventListener('scroll', this.throttledScrollHandler);

      // 添加窗口大小变化监听  
      window.addEventListener('resize', this.handleResize);

      // 页面加载后触发一次滚动检测  
      setTimeout(() => {
        this.scrollHandler();
      }, 100);
    });
  },
  beforeDestroy() {
    // 移除事件监听器  
    window.removeEventListener('scroll', this.throttledScrollHandler);
    window.removeEventListener('resize', this.handleResize);
  },
  destroyed() {
    window.removeEventListener('scroll', this.scroll, false)
  },
  mounted() {
    // 初始化导航高度  
    this.$nextTick(() => {
      const nav = document.getElementById('fth2');
      this.navHeight = nav ? nav.getBoundingClientRect().height : 0;

      // 添加滚动监听  
      window.addEventListener('scroll', this.throttledScrollHandler);

      // 添加窗口大小变化监听  
      window.addEventListener('resize', this.handleResize);

      // 页面加载后触发一次滚动检测  
      setTimeout(() => {
        this.scrollHandler();
      }, 100);
    });
  },

  beforeDestroy() {
    // 移除事件监听器  
    window.removeEventListener('scroll', this.throttledScrollHandler);
    window.removeEventListener('resize', this.handleResize);
  },

  methods: {
    // 节流函数  
    throttle(func, delay) {
      return (...args) => {
        const now = Date.now();
        if (now - this.lastScrollTime >= delay) {
          this.lastScrollTime = now;
          return func(...args);
        }
      };
    },

    // 滚动处理函数  
    scrollHandler() {
      const nav = document.getElementById('fth2');
      if (!nav) return;

      const navTop = nav.getBoundingClientRect().top;
      const offset = navTop <= this.stickyTop ? this.stickyTop + this.navHeight : this.navHeight;
      const scrollPosition = window.scrollY + offset;

      let foundActive = false;

      // 获取所有带data-section属性的区域  
      const sections = document.querySelectorAll('[data-section]');
      const buttons = document.querySelectorAll('.button-default.sub-nav');

      sections.forEach((section, index) => {
        if (!section || index >= buttons.length) return;

        const sectionTop = section.getBoundingClientRect().top + window.scrollY;
        const sectionBottom = sectionTop + section.offsetHeight;
        const buffer = 5;

        if (scrollPosition >= sectionTop - buffer && scrollPosition < sectionBottom) {
          if (!foundActive) {
            this.activeButtonIndex = index;
            foundActive = true;
          }
        }
      });

      // 处理页面底部  
      const lastSection = sections[sections.length - 1];
      if (lastSection) {
        const lastSectionBottom = lastSection.getBoundingClientRect().top + window.scrollY + lastSection.offsetHeight;
        const windowBottom = window.scrollY + window.innerHeight;

        if (windowBottom >= lastSectionBottom - 10) {
          this.activeButtonIndex = buttons.length - 1;
        }
      }
    },

    // 导航点击处理  
    navigateToSection(index, id) {
      this.activeButtonIndex = index;

      const targetSection = document.querySelector(`[data-section="${id}"]`);
      if (!targetSection) return;

      const nav = document.getElementById('fth2');
      if (!nav) return;

      const navTop = nav.getBoundingClientRect().top;
      const stickyOffset = navTop <= this.stickyTop ? this.navHeight + this.stickyTop : 0;
      const targetOffset = targetSection.getBoundingClientRect().top + window.scrollY - stickyOffset;

      // 临时移除滚动监听  
      window.removeEventListener('scroll', this.throttledScrollHandler);

      // 滚动到目标位置  
      window.scrollTo({
        top: targetOffset,
        behavior: 'smooth'
      });

      // 延迟重新添加滚动监听  
      setTimeout(() => {
        window.addEventListener('scroll', this.throttledScrollHandler);
      }, 800);
    },

    // 处理窗口大小变化  
    handleResize() {
      const nav = document.getElementById('fth2');
      this.navHeight = nav ? nav.getBoundingClientRect().height : 0;
      this.scrollHandler();
    },

    getData() {
      $.ajax({
        type: "GET",
        url: "https://api.eappback.com/crm/openApi/community/getRatingsList",
        // url: "https://socialtradingwebapi.eappservice.com/api/listSignalIndicators",
        success: (res) => {
          this.infoData = res.data
        }
      })
    },
  },
  computed: {
    // 创建节流后的滚动处理器  
    throttledScrollHandler() {
      return this.throttle(this.scrollHandler, 100);
    },
    isMobile() {
      return this.windowWidth < 600
    }
  },
}
</script>
<style lang="scss" scoped>
/* @import url("../assets/css/rem2.css"); */
html {
  font-size: 0.052083vw;
}

@media (max-width: 996px) {
  html {
    font-size: 0.21vw !important;
  }
}

@font-face {
  font-family: "Financier Display Light";
  src: url("../assets/font/FinancierDisplay-Light.otf") format("opentype");
}

@font-face {
  font-family: "Financier Display Bold";
  src: url("../assets/font/FinancierDisplay-Bold.otf") format("opentype");
}

.copytrading-fth1 .p1 {
  font-size: 38px;
  color: #FFFFFF;
  font-weight: 700;
  letter-spacing: 4px;
}

.copytrading-fth1 .p2 {
  font-size: 16px;
  color: #e6e6e6;
  letter-spacing: 2px;
  margin-top: 16px;
}

.copytrading-fth1 .p3 {
  letter-spacing: 2px;
  opacity: 0.2;
  font-family: 'Baskerville Old Face';
  font-size: 46px;
  margin-top: 24px;
  color: #FFFFFF;
}

.copytrading-fth1 img {
  margin-top: 20px;
  width: 300px;
}

.copytrading-fth2 {
  background: #fff;
  box-shadow: 0px 2px 10px rgba(61, 1, 1, 0.08);
  height: 76px;
  text-align: center;
  padding-top: 30px;
}

.copytrading-fth2 a {
  padding: 8px 24px;
  margin: 0px 12px;
  border: 1px solid #4D0101;
  color: #4D0101;
  font-size: 16px;
}

/*.copytrading-fth2 a:hover{
  color: #ffffff;
  background: #3D0101;
}*/
.copytrading-public-title {
  font-size: 38px;
  font-weight: 600;
  color: #3D0101;
  text-align: center;
  letter-spacing: 2px;
  margin-top: 70px;
  margin-bottom: 60px;
}

.copytrading-public-line {
  width: 60px;
  border: 1px solid #949699;
  margin: 30px auto;
}

.copytrading-public-link {
  background: #3d0101;
  padding: 10px 26px;
  color: #fff;
  font-size: 16px;
}

.copytrading-public-link img {
  width: 7px;
  margin-left: 12px;
}

.copytrading-public-link:hover {
  color: #ffffff;
  background: #AF8147;
}

.copytrading-body .copytrading-link {
  margin-top: 50rem;
  text-align: center;
}


.copytrading-link {
  text-align: center;
  margin-top: 70px;
}

.copytrading-body .copytrading-link {
  margin-top: 50rem;
  text-align: center;
}


.copytrading-fth3 {}

.copytrading-fth3 .row {
  background: linear-gradient(90deg, rgba(148, 150, 153, 0) 0%, rgba(148, 150, 153, 0.06) 92.82%);
  padding: 110px 0 90px;
  margin-top: 55px;
}

.copytrading-fth3 .row div {
  position: relative;
  text-align: center;
  width: 20%;
}

.copytrading-fth3 .p1 {
  font-size: 230px;
  font-family: 'Baskerville Old Face';
  font-weight: 400;
  text-align: center;
  color: #3D0101;
  opacity: 0.1;
  position: absolute;
  top: 48px;
  left: 50%;
  transform: translate(-50%, -50%);
}

.copytrading-fth3 .p2 {
  font-size: 24px;
  line-height: 36px;
  font-weight: 600;
  letter-spacing: 2px;
  color: #3d0101;
  margin-top: 82px;
}

.copytrading-fth3 .p3 {
  color: #666666;
  font-size: 14px;
  font-weight: 400;
  line-height: 26px;
  margin-top: 20px;
}

.copytrading-fth4 {
  margin-top: 150px;
}

.copytrading-fth4 .r1 {
  margin-top: 55px;
}

.copytrading-fth4 .row .list {
  text-align: center;
  background: linear-gradient(0deg, rgba(148, 150, 153, 0.06) 0%, rgba(148, 150, 153, 0.02) 100%);
  padding: 30px;
  margin: 0 0.5%;
  width: 22%;
}

.copytrading-fth4 .row .list img {
  width: 45%;
  border-radius: 50%;
}

.copytrading-fth4 .row .list .p1 {
  font-size: 18px;
  color: #000000;
  margin-top: 12px;
}

.copytrading-fth4 .row .list .p2 {
  color: #949699;
  font-size: 14px;
  margin-top: 8px;
}

.copytrading-fth4 .row .list .p3 {
  color: #8D0101;
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 1px;
  margin-top: 18px;
}

.copytrading-fth4 .row .list .p4 {
  color: #949699;
  font-size: 14px;
  margin-top: 6px;
}

.copytrading-fth4 .row .list .sp1 {
  color: #3D0101;
  font-size: 18px;
  font-weight: 600;
}

.copytrading-fth4 .row .list .sp2 {
  color: #949699;
  font-size: 14px;
  margin-top: 12px;
}

.copytrading-fth4 .r2 {
  margin-top: 30px;
}

.copytrading-fth4 .r2 div {
  padding: 0;
}

.copytrading-fth4 .list .p5 {
  margin-top: 50px;
  margin-bottom: 18px;
}

.copytrading-fth4 .list .p5 span {
  font-size: 14px;
  color: #3D0101;
  margin-right: 36px;
}

.copytrading-fth4 .list .p5 a {
  padding: 8px 20px;
  color: #3D0101;
  background: #AF8147;
  font-size: 16px;
  border-radius: 2px;
}

.copytrading-fth4 .list .p5 a:hover {
  color: #AF8147;
  background: #3D0101;
}

.copytrading-fth5 {
  margin-top: 150px;
}

.copytrading-fth5 .row {
  margin-top: 60px;
}

.copytrading-fth5 .row div {
  background: linear-gradient(0deg, rgba(148, 150, 153, 0.06) 0%, rgba(148, 150, 153, 0.02) 100%);
  margin-left: 1.2%;
  margin-right: 1.2%;
  text-align: center;
  padding-top: 30px;
  padding-bottom: 30px;
  height: auto;
  width: 17.5%;
}

.copytrading-fth5 .row .p1 {
  font-size: 18px;
  color: #3D0101;
  font-weight: 700;
}

.copytrading-fth5 .row .chess {
  width: 36%;
  margin-top: 40px;
}

.copytrading-fth5 .row .p2 {
  display: inline-block;
  padding: 0px 16px;
  margin-top: 30px;
  width: 100%;
}

.copytrading-fth5 .row .p2 .s1 {
  font-size: 14px;
  color: #3D0101;
  float: left;
  font-weight: 400;
}

.copytrading-fth5 .row .p2 .s2 {
  float: right;
  color: #3D0101;
  letter-spacing: 1px;
  font-size: 14px;
  font-weight: 700;
}

.copytrading-fth5 .row .p3 {
  display: inline-block;
  padding: 0px 16px;
  margin-top: 14px;
  width: 100%;
}

.copytrading-fth5 .row .p3 .s1 {
  font-size: 14px;
  color: #3D0101;
  float: left;
  font-weight: 400;
}

.copytrading-fth5 .row .p3 .s2 {
  float: right;
  color: #3D0101;
  letter-spacing: 1px;
  font-size: 14px;
  font-weight: 700;
}

.copytrading-fth5 .row .p4 {
  color: #AF8147;
  letter-spacing: 1px;
  font-size: 18px;
  font-weight: 700;
  margin-top: 30px;
}

.copytrading-fth5 .row .p4 img {
  width: 12px;
}

.copytrading-fth5 .row div .tip1 {
  font-size: 13px;
  margin-top: 16px;
}

.copytrading-fth5 .row div:nth-child(1) {
  margin-top: 100px;
}

.copytrading-fth5 .row div:nth-child(2) {
  margin-top: 80px;
}

.copytrading-fth5 .row div:nth-child(3) {
  margin-top: 60px;
}

.copytrading-fth5 .row div:nth-child(4) {
  margin-top: 40px;
}

.copytrading-fth5 .row div:nth-child(5) {
  margin-top: 0px;
  border: 2px solid #8D0101;
}

.copytrading-fth5 .row div:nth-child(5) .p1,
.copytrading-fth5 .row div:nth-child(5) .tip1,
.copytrading-fth5 .row div:nth-child(5) .p4 {
  color: #8D0101;
}

.copytrading-fth5 .row div:nth-child(1) .chess {
  width: 30%;
}

.copytrading-fth5 .row div:nth-child(2) .chess {
  width: 27%;
  margin-bottom: 8px;
  margin-top: 44px;
}

.copytrading-fth5 .row div:nth-child(3) .chess {
  width: 34%;
  margin-top: 44px;
  margin-bottom: 15px;
}

.copytrading-fth5 .row div:nth-child(4) .chess {
  width: 39%;
  margin-top: 46px;
  margin-bottom: 26px;

}

.copytrading-fth5 .row div:nth-child(5) .chess {
  width: 35%;
  margin-top: 23px;
}

.copytrading-fth6 {
  margin-top: 160px;
  margin-bottom: 160px;
}

.copytrading-fth6 .row {
  margin-top: 80px;
}

.copytrading-fth6 table {
  width: 88%;
  text-align: center;
  margin: 0 auto;
}

.copytrading-fth6 table thead th {
  color: #000000;
  letter-spacing: 1px;
  font-size: 20px;
  font-weight: 700;
  position: relative;
  height: 82px;
  line-height: 82px;
}

.copytrading-fth6 table thead th img {
  width: 37%;
  position: absolute;
  left: 31%;
  top: -18px;
}

.copytrading-fth6 table thead th:nth-child(1) {
  width: 13%;
}

.copytrading-fth6 table tbody tr {
  height: 50px;
  line-height: 50px;
}

.copytrading-fth6 table tbody tr td {
  position: relative;
}

.copytrading-fth6 table tbody tr .img1 {
  width: 12%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.copytrading-fth6 table tbody tr .img2 {
  width: 10%;
  left: 49%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
}

.copytrading-fth6 table tbody tr:nth-child(odd) {
  background: rgba(148, 150, 153, 0.04);
}

.copytrading-fth6 table tbody tr:nth-child(even) {
  height: 70px;
  line-height: 70px;
}

.copytrading-fth6 table tbody td {
  color: #3D0101;
  font-size: 16px;
  font-weight: 700;
}

.copytrading-fth6 table tbody tr:last-child td:first-child {
  line-height: 30px;
}

.copytrading-fth6 table th:last-child {
  color: #8D0101;
}

.reward-list-m {
  display: none;
}

.copytrading-fth6 table thead th br {
  display: none;
}

.copytrading-fth2-lock {
  position: fixed;
  right: 0;
  left: 0;
  top: 76.5px;
  z-index: 999;
}

.profile-name {
  font-size: 40px;
  color: #ffffff;
  position: absolute;
  top: 19%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#transmit5 img {
  width: 7px;
  margin-left: 10px;
}











.fca7 {
  background: url(/ebc-static/image/fca44.jpg) 100%/auto 100% no-repeat;
  height: 880px;
  background-position: center center;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.fca7 .d1 .p1 {
  font-size: 36px;
  line-height: 58px;
  color: #e5e5e5;
  width: 70%;
  margin-left: 12%;
  font-weight: 700;
  margin-top: 320px;
  letter-spacing: 2px;
}

.fca7 .d1 .p2 {
  color: #FFFFFF;
  font-size: 20px;
  letter-spacing: 2px;
  margin: 40px 0;
  margin-left: 12%;
}

.fca7 .d1 .p2 em {
  width: 60px;
  height: 1px;
  display: inline-block;
  border-bottom: 1px #ffffff solid;
  float: left;
}

.fca7 .d1 .p2 span {
  margin: 0 12px;
  float: left;
}

.fca7 .d1 .p3 {
  margin-left: 12%;
  letter-spacing: 2px;
  color: #FFFFFF;
  font-size: 16px;
  float: left;
  margin-top: 48px;
  width: 100%;
}

.fca7 .d2 {
  margin-top: 160px;
}



.fca7 .form-btn {
  padding: 6px 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f5f5f5;
  font-size: 18px;
  font-weight: 700;
  position: absolute;
  bottom: 90px;
  cursor: pointer;
  color: #4D0101;
}

.fca7 .form-btn span {
  margin-right: 10px;
  display: inline-block;
  line-height: 2;
  /* border-bottom: 1px solid #8b8b8b; */
}

.fca7 .form-box {
  width: 640px;
  height: 630px;
  position: absolute;
  top: 150px;
  right: 15%;
  padding: 3px 71px 68px 200px;
  background: linear-gradient(90deg, rgba(61, 1, 1, 0.08) 0%, rgba(45, 1, 1, 0.81) 100%);
}

.fca7 .form-box:after {
  content: "";
  position: absolute;
  right: 0;
  bottom: 190px;
  left: 0;
  /* border: 1px solid #7d1614; */
  opacity: .5
}

.fca7 .form-box .form-control {
  height: 42px;
  padding: inherit
}

.fca7 .form-box .testing {
  margin-top: 40px;
  display: flex;
  align-items: center
}

.fca7 .form-box .testing__title {
  font-size: 24px
}

.fca7 .form-box .testing__kind {
  padding-left: 10px
}

.fca7 .form-box .testing__kind .input {
  border: 1px solid #ced4da;
  font-size: 1rem;
  font-weight: 400;
  line-height: 40px;
  color: #212529;
  background-color: #fff;
  border-radius: .25rem;
  width: 50px;
  height: 40px;
  text-align: center;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

.fca7 .col-md-3 {
  padding: 0 5px
}

.fca7 .select-label {
  cursor: pointer;
  display: flex;
  font-size: 15px;
  align-items: center;
  justify-content: center;
  /* background-color: #fff; */
  border-radius: 21px;
  height: 42px;
  border: 1px solid #ddd;
  color: #fff;
}

.fca7 .select-label--active {
  background-color: #ffffff;
  color: #3D0101;
  border-color: #ffffff;
}

.fca7 .form-label {
  font-size: 16px;
  margin-top: 40px;
  /* font-weight: 700; */
  color: #ffffff;
  letter-spacing: 2px;
}

.fca7 .form-title {
  margin-top: 20px;
  font-size: 36px;
  font-weight: 600;
  color: #333;
  line-height: 1.5
}

.fca7 .mt110 {
  margin-top: 80px
}

.fca7 .form-inquiry {
  height: 50px;
  width: 125px;
  background-color: #f5f5f5;
  padding: 15px 0 0 10px
}

.fca7 .form-inquiry__en {
  font-size: 12px;
  color: #666
}

.fca7 .form-inquiry__text {
  margin-top: 8px;
  color: #333;
  font-size: 20px;
  font-weight: 700
}

.form-control {
  color: #fff;
  background-color: rgba(255, 255, 255, 0);
  border: 1px solid #ffffff;
  border-radius: 0px;
}

#verify {
  width: 92%;
}

.fca7 #contact-submit img {
  width: 9px;
  margin-top: 2px;
}

.rotate {
  transition: all 0.2s ease-in-out;
  transform: rotate(-90deg);
}

.rotate-leave {
  transition: all 0.2s ease-in-out;
  transform: rotate(+90deg);
}








@media(min-width:2100px) {
  .fca7 .d1 .p1 {
    font-size: 44px;
    margin-top: 55%;
    width: 82%;
  }

  .fca7 .form-box {
    right: 22%;
    top: 22%;
  }
}


@media(min-width:2000px) {
  .fca1 {
    height: 29vh;
    padding-top: 11vh;
  }

  .fca7 .form-box {
    right: 18%;
    top: 22%;
  }

  .fca2 .container,
  .fca3 .container,
  .fca4 .container,
  .fca5 .container,
  .fca6 .container {
    max-width: 70%;
  }

  .c-footer .container-xxl {
    max-width: 70%;
  }
}

@media(max-width:1600px) {
  .copytrading-fth2-lock {
    top: 76.5px;
  }
}

@media(max-width:1400px) {
  .copytrading-fth2-lock {
    top: 76.5px;
  }

  .fca7 .form-box {
    right: 8%;
  }

  .fca2 .d1 {
    padding-left: 9%;
  }

  .profile-name {
    font-size: 38px;
    top: 17.5%;
  }
}

@media(max-width:1300px) {
  .fca7 .form-box {
    right: 6%;
  }

  .fca3 p {
    margin-top: 100px;
  }

  .fca3 span {
    margin: 0px 52px 0 52px;
  }

  .fca2 p {
    width: 100%;
  }

  .fca3 .d2 {
    padding-left: 2%;
  }

  .copytrading-fth4 .container {
    max-width: 1100px;
  }

  .profile-name {
    font-size: 30px;
    top: 17.5%;
  }

  .copytrading-fth4 .row .list .p3 {
    font-size: 24px;
  }

  .copytrading-fth4 .row .list .sp1 {
    font-size: 16px;
  }

  .copytrading-fth4 .row .list .sp2 {
    font-size: 13px;
  }

  .copytrading-fth4 .list .p5 a {
    font-size: 14px;
  }
}

@media(max-width:1200px) {
  .fca3 .img1 {
    width: 180px
  }

  .fca3 .img2 {
    width: 190px;
    margin-top: 16px;
  }

  .fca3 span {
    margin: 0px 44px 0 40px;
  }

}

@media(max-width:995px) {
  .swiper-wrapper {
    height: auto !important;
  }

  .copytrading-fth1 .p1 {
    padding: 0 5%;
  }

  .copytrading-fth4 .row .list img {
    width: 100px;
    height: 100px;
  }

  .profile-name {
    font-size: 40px;
    top: 19.5%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .copytrading-fth1 {
    background: url(/ebc-static/image/fth-bg-m.jpg) 100%/auto 100% no-repeat;
    padding-top: 105px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    height: 286px;
  }

  .copytrading-fth1 .p3 {
    font-size: 28px;
    opacity: 0.3;
  }

  .copytrading-fth1 .p1 {
    font-size: 30px;
  }

  .copytrading-fth2 {
    display: none;
  }

  .copytrading-public-title {
    font-size: 26px;
    margin-bottom: 20px;
  }

  .copytrading-public-title:nth-child(1) {
    margin-top: 40px;
  }

  .copytrading-fth3 .row {
    background: none;
    padding-top: 0px;
    padding-bottom: 0px;
    margin-top: 0px;
  }

  .copytrading-link {
    margin-top: 50px;
  }

  .copytrading-fth3 .row div {
    margin: 22px 0;
  }

  .copytrading-fth3 .row div:nth-child(1) {
    width: 100%;
  }

  .copytrading-fth3 .row div:nth-child(2),
  .copytrading-fth3 .row div:nth-child(3),
  .copytrading-fth3 .row div:nth-child(4),
  .copytrading-fth3 .row div:nth-child(5) {
    width: 50%;
  }

  .copytrading-fth3 .p1 {
    font-size: 150px;
  }

  .copytrading-fth3 .p2 {
    font-size: 22px;
    line-height: 33px;
    margin-top: 61px;
  }

  .copytrading-fth3 .p3 {
    line-height: 24px;
    font-size: 14px;
    margin-top: 14px;
  }

  .copytrading-fth4 {
    margin-top: 100px;
  }

  .copytrading-fth4 .r1 {
    overflow: hidden;
    margin-top: 40px;
    padding: 0px 38px 0 0;
  }

  .copytrading-fth4 .r2 .col-md-4 {
    width: 33%;
  }

  .copytrading-fth5 {
    margin-top: 100px;
  }

  .copytrading-fth5 .row div {
    width: 93%;
    margin: 0 auto;
  }

  .copytrading-fth5 .row {
    margin-top: 0px;
  }

  .copytrading-fth5 .row div .col-sm-6 {
    width: 50%;
  }

  .copytrading-fth5 .row div .sr-row {
    padding: 0;
    margin: 0;
    background: none;
    margin-top: 0px !important;
  }

  .copytrading-fth5 .row div .sr-row div {
    background: none;
    padding: 0;
    margin: 0;
  }

  .copytrading-fth5 .row .p4 {
    margin-top: 10px;
  }

  .copytrading-fth5 .row .chess {
    margin-top: 30px !important;
  }

  .copytrading-fth5 .row .p1 {
    margin-top: 10px;
  }

  .copytrading-fth5 .row .p2 {
    margin-top: 50px;
  }

  .copytrading-fth5 .row .p2 .s1 {
    font-size: 16px;
  }

  .copytrading-fth5 .row .p2 .s2 {
    font-size: 16px;
  }

  .reward-list-pc {
    display: none;
  }

  .reward-list-m {
    display: block;
  }

  .copytrading-fth5 .row div:nth-child(1) {
    margin-top: 20px;
  }

  .copytrading-fth5 .row div:nth-child(2) {
    margin-top: 20px;
  }

  .copytrading-fth5 .row div:nth-child(3) {
    margin-top: 20px;
  }

  .copytrading-fth5 .row div:nth-child(4) {
    margin-top: 20px;
  }

  .copytrading-fth5 .row div:nth-child(5) {
    margin-top: 20px;
  }

  .reward-list-m div {
    background: linear-gradient(0deg, rgba(148, 150, 153, 0.06) 0%, rgba(148, 150, 153, 0.02) 100%);
  }

  .reward-list-m .sr-row div:nth-child(1) {
    width: 40% !important;
  }

  .reward-list-m .sr-row div:nth-child(2) {
    width: 60% !important;
  }

  .copytrading-fth5 .row div .tip1 {
    font-size: 14px;
    line-height: 20px;
    margin-top: 4px;
  }

  .copytrading-fth5 .row div:nth-child(5) .p1 {
    margin-top: 60px;
  }

  .reward-list-m .col-md-2 {
    padding-top: 18px !important;
    padding-bottom: 18px !important;
  }

  .copytrading-fth6 table thead th {
    font-size: 14px;
    line-height: 18px;
  }

  .copytrading-fth6 table thead th br {
    display: block;
  }

  .copytrading-fth6 table thead th img {
    width: 76%;
    left: 11%;
    top: -15px;
  }

  .copytrading-fth6 table thead th:nth-child(1) {
    width: 26%;
  }

  .copytrading-fth6 table tbody td {
    font-size: 13px;
    line-height: 10px;
  }

  .copytrading-fth6 table {
    width: 92%;
  }

  .copytrading-fth6 table thead th {
    height: 56px;
  }

  .copytrading-fth6 table thead th:nth-child(1) div {
    position: relative;
    top: 6%;
  }

  .copytrading-fth6 table tbody tr .img1 {
    width: 40%;
  }

  .copytrading-fth6 table tbody tr .img2 {
    width: 33%;
    left: 45%;
  }

  .copytrading-fth6 table tbody td {
    font-size: 13px;
    line-height: 50px;
  }

  .copytrading-fth6 table tbody tr:nth-child(even) td {
    line-height: 60px;
  }

  .copytrading-fth6 table tbody tr:nth-child(even) {
    height: 60px;
    line-height: 60px;
  }

  .copytrading-fth6 table tbody tr:last-child td:first-child {
    line-height: 18px;
    padding-top: 7px;
  }

  .copytrading-fth6 {
    margin-top: 100px;
    margin-bottom: 80px;
  }

  .copytrading-fth6 .row {
    margin-top: 50px;
  }

  .fca7 .d1 .p3 {
    width: 100%;
    margin: 0 auto;
    text-align: center;
  }

  .copytrading-fth5 .row .p2 {
    margin-top: 40px;
  }

  .copytrading-fth5 .row .p2 .s1 {
    font-size: 14px;
  }

  .copytrading-fth5 .row .p2 .s2 {
    font-size: 14px;
  }

  .copytrading-fth5 .row div:nth-child(1) .chess,
  .copytrading-fth5 .row div:nth-child(2) .chess,
  .copytrading-fth5 .row div:nth-child(3) .chess,
  .copytrading-fth5 .row div:nth-child(4) .chess,
  .copytrading-fth5 .row div:nth-child(5) .chess {
    margin-bottom: 4px;
  }

  .copytrading-fth5 .row div:nth-child(5) .p1 {
    margin-top: 54px;
  }

  .copytrading-fth5 .sr-row {
    width: 100% !important;
  }

  .copytrading-fth4 .row .list .p3 {
    font-size: 26px;
  }

  .copytrading-fth4 .row .list .sp1 {
    font-size: 16px;
  }

  .copytrading-fth4 .row .list .sp2 {
    font-size: 12px;
    margin-top: 6px;
  }






  .fca3 .d2 {
    padding-left: 8%;
  }

  .fca1 {
    background: url(/ebc-static/image/fca1-m.jpg) 100%/auto 100% no-repeat;
    height: 280px;
    background-position: center center;
    padding-top: 118px;
  }

  .fca1 p {
    font-size: 28px;
  }

  .fca1 img {
    margin-top: 22px;
    width: 150px;
  }

  .fca2 {
    margin-top: 35px;
    margin-bottom: 66px;
  }

  .fca2 .d1 {
    padding: 46px 0px;
    padding-left: 10%;
  }

  .fca2 .img1 {
    margin-top: 4px;
    width: 32%;
  }

  .fca2 .img2 {
    width: 18%;
    margin-top: 2px;
  }

  .fca2 span {
    margin: 0px 18% 0 8%;
    height: 58px;
  }

  .fca2 p {
    width: 100%;
    padding: 0px 6%;
    margin-top: 22px;
  }

  .fca3 {
    margin-top: 35px;
  }

  .fca3 .d1 {
    padding: 46px 0px;
    padding-left: 10%;
  }

  .fca3 .img1 {
    margin-top: 4px;
    width: 32%;
  }

  .fca3 .img2 {
    width: 43%;
    margin-top: 6px;
  }

  .fca3 span {
    margin: 0px 8% 0 8%;
    height: 58px;
  }

  .fca3 p {
    width: 100%;
    padding: 0px 6% 0 0%;
    margin-top: 22px;
  }

  .fca4 {
    margin: 20px 0;
  }

  .fca4 .title {
    font-size: 22px;
    line-height: 40px;
    padding: 0 6%;
    margin-bottom: 26px;
  }

  .fca4 .row div {
    margin: 20px 0;
    text-align: left;
  }

  .fca4 .row div img {
    float: left;
    width: 50px;
    margin-left: 4%;
    margin-top: 12px;
  }

  .fca4 .row div p {
    float: left;
    margin-top: 0px;
    width: 64%;
    margin-left: 12%;
  }

  .fca4 .row div p br {
    display: none;
  }

  .fca4 .row {
    padding: 16px 7%;
  }


}

@media(max-width:406px) {
  .fca4 .title {
    font-size: 20px;
    line-height: 40px;
    padding: 0 3%;
  }

  .fca5 .title {
    font-size: 20px;
    line-height: 40px;
    padding: 0 3%;
  }

  .fca6 .title {
    font-size: 20px;
    line-height: 40px;
    padding: 0 7%;
  }

  .fca7 .d1 .p1 {
    font-size: 26px;
    line-height: 40px;
    text-align: center;
    width: 90%;
  }

  .copytrading-fth5 .row .p4 {
    font-size: 16px;
  }

  .copytrading-fth5 .row div .tip1 {
    font-size: 12px;
    line-height: 16px;
  }
}

@media(max-width:385px) {
  .copytrading-fth3 .p3 {
    font-size: 13px;
    line-height: 22px;
  }

  .copytrading-fth6 table tbody td {
    font-size: 12px;
  }

  .copytrading-fth4 .list .p5 a {
    padding: 8px 16px;
    font-size: 14px;
  }
}



@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

::v-deep .my-bullet-active {
  opacity: 0.6;
}

.flex-bewteen {
  display: flex;
  justify-content: space-between;
}

.pc {
  display: block;
}

.mobile {
  display: none;
}

.pay-session1-box {
  /* margin-top: 30rem; */
  background: #0a0202;
}

.pay-session1 {
  margin: 0 auto;
  min-height: 656rem;
  max-width: 1440rem;
  background-image: url(../assets/image/pay/session1_bg.png);
  background-size: auto 100%;
  background-position: right;
  background-repeat: no-repeat;
  padding: 150rem 0 150rem 64rem;
}

.pay-session1-title {
  font-size: 56rem;
  font-style: normal;
  font-weight: 900;
  line-height: 150%;
  background: linear-gradient(90deg, #e8d6b0 0%, #d1ad77 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* .en .pay-session1-title {
  font-size: 60rem;
} */
.pay-session1-detail {
  margin-top: 10rem;
  color: #fff;
  font-size: 21rem;
  font-style: normal;
  font-weight: 350;
  line-height: 150%;
  max-width: 80%;
}

.pay-session1-list {
  margin-top: 30rem;
  color: #fff;
  font-size: 26rem;
  font-style: normal;
  font-weight: 400;
  line-height: 235%;
}

.pay-session1-li {
  display: flex;
  align-items: center;
}

.pay-session1-li>span+span {
  margin-left: 16rem;
}

.pay-session1-li :first-child {
  font-size: 18rem;
  color: #af8147;
  flex-shrink: 0;
}

.btn-go {
  margin-top: 35rem;
  display: inline-flex;
  padding: 12rem 24rem;
  justify-content: center;
  align-items: baseline;
  gap: 8rem;
  flex-shrink: 0;
  border-radius: 8rem;
  background: linear-gradient(90deg, #e8d7b1 0%, #d0ab75 100%);
  min-width: 230rem;
  color: #010101;
  /* font-family: Roboto; */
  font-size: 26rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  /* 30rem */
  transition: all 0.3s;
  min-width: 370rem;
}

.ar .btn-go {
  transform: rotate(180deg);
}

.btn-go:hover {
  color: #010101;
  opacity: 0.9;
}

.en .btn-go {
  /* font-weight: 700; */
}

.pay-session2,
.pay-session3,
.pay-session4,
.pay-session5 {
  margin: 0 auto;
  padding: 80rem 64rem;
  // max-width: 1440rem;
  max-width: 1680rem;
}

.pay-session2-title,
.pay-session3-title,
.pay-session4-title,
.pay-session5-title {
  margin: 0 auto;
  color: #333;
  text-align: center;
  font-size: 56rem;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
}

.en .pay-session2-title,
.en .pay-session3-title,
.en .pay-session4-title,
.en .pay-session5-title {
  font-weight: 400;
}

.pay-session2-subtitle,
.pay-session3-subtitle,
.pay-session4-subtitle {
  margin-top: 20rem;
  color: #666;
  text-align: center;
  font-size: 22rem;
  font-style: normal;
  font-weight: 350;
  line-height: 150%;
}

.pay-session2-list {
  display: flex;
  /* gap: 38rem; */
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 120rem;
}

.pay-session2-li {
  width: calc(20% - 30.4rem);
}

.pay-session2-li-bottom .flex-bewteen span:last-child {
  color: #9f734c;
  font-weight: 500;
}

.hight .pay-session2-li-content-name {
  color: #ff4b4e;
}

.hight .pay-session2-li-bottom .flex-bewteen span:last-child {
  color: #ff4b4e;
}

.pay-session2-li-top {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 72rem;
  color: #5d2333;
  font-size: 36rem;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  background: #ebd9c7;
}

.pay-session2-li-top-hight {
  color: #fff;
  background: #a52933;
}

.pay-session2-li-top .dollar {
  font-size: 26rem;
}

.pay-session2-li-top {
  font-family: Roboto;
}

.pay-session2-li-top-small {
  font-weight: 400;
  font-size: 18rem;
}

.pay-session2-li-top-mini {
  font-weight: 400;
  font-size: 16rem;
  line-height: 1em;
}

.pay-session2-li-top-center {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.pay-session2-li-content {
  background: #fafafa;
  padding: 18rem 0 0 0;
}

.pay-session2-li-content-name {
  display: flex;
  justify-content: center;
  gap: 8rem;

  color: #af8147;
  font-size: 18rem;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  /* 24.943rem */
}

.pay-session2-li-content-name .icon-Vector {
  font-size: 10rem;
}

.pay-session2-li-bottom {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 64rem;
  padding: 14rem 16rem;
  border: 1rem solid #e7dcce;
  color: #000;
  font-size: 17rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.es .pay-session2-li-bottom {
  font-size: 15rem;
}

.pay-session2-li-content-icon {
  display: flex;
  justify-content: center;
  padding-top: 56rem;
  padding-bottom: 36rem;
}

.pay-session2-list {
  display: flex;
  gap: 20rem;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 120rem;
}

.pay-session2-card {
  position: relative;
  width: calc(20% - 16rem);
  height: 300rem;
  border-radius: 8rem;
  /* border: 1px solid #e7dcd3; */
  background: url(../assets/image/pay/session2_card2.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 28rem;
}

.jp .pay-session2-card {
  height: 320rem;
}

.pay-session2-card-content h2{
  padding-right: 70rem;
}

.ar .pay-session2-card-content h2{
  padding-right: 0;
  padding-left: 70rem;
}

.pay-session2-card-content-icon {
  position: absolute;
  right: 28rem;
  top: 20rem;
}

.ar .pay-session2-card-content-icon {
  right: unset;
  left: 28rem;
}

.pay-session2-card-content-icon img {
  width: 64rem;
  height: 64rem;
}

.pay-session2-card-light {
  background: url(../assets/image/pay/session2_card1.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.pay-session2-card h2 {
  color: #333;
  font-size: 20rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.en .pay-session2-card h2 {
  font-size: 18rem;
}

.pay-session2-card-light h2 span {
  background: linear-gradient(270deg, #ebcaa7 0%, #fdfef9 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.pay-session2-card .p1 {
  display: flex;
  align-items: baseline;
  // font-family: DIN;
  font-family: Roboto;
  margin-top: 16rem;
}

.pay-session2-card-content .number {
  font-size: 80rem;
  font-weight: 700;
  line-height: normal;
  color: #ff4b4e;
}

.pay-session2-card-light .pay-session2-card-content .number {
  background: linear-gradient(270deg, #ebcaa7 36.47%, #fdfef9 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.pay-session2-card .unit {
  font-size: 32rem;
  font-weight: 700;
  line-height: normal;
  color: #ff4b4e;
}

.pay-session2-card-light .pay-session2-card-content .unit {
  color: #ebcaa7;
}

.pay-session2-card-content .p2 {
  margin-top: -8rem;
  color: #b2afad;
  font-size: 14rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.pay-session2-card-bottom {
  margin-top: 36rem;
  display: flex;
  justify-content: space-between;
}

.pay-session2-card-bottom-item {
  display: flex;
  flex-direction: column;
  min-width: 35%;
}

.pay-session2-card-bottom-item>span:first-child {
  color: #333;
  // font-family: 'Noto Serif SC';
  font-family: Roboto;
  font-size: 20rem;
  font-weight: bold;
  line-height: normal;
}

.pay-session2-card-light .pay-session2-card-bottom-item>span:first-child {
  background: linear-gradient(270deg, #ebcaa7 36.47%, #fdfef9 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.pay-session2-card-bottom-item>span:last-child {
  color: #6a6967;
  font-size: 14rem;
  font-style: normal;
  font-weight: 400;
  line-height: 26rem;
}

.pay-session2-card-light .pay-session2-card-bottom-item>span:last-child {
  color: #b2afad;
}

.pay-session2-card-light .pay-session2-card-bottom-line {
  background: #653936;
}

.pay-session2-card-bottom-line {
  width: 1px;
  height: 36rem;
  background: #e7dcd3;
  margin: 0 10rem;
  flex-shrink: 0;
  margin-top: 10rem;
}

.pay-session3-list {
  position: relative;
  margin-top: 80rem;
  display: flex;
}

.pay-session3-li {
  width: 25%;
  display: flex;
  flex-direction: column;
}

.pay-session3-li-top {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 67rem;
  background-color: #f4f0ed;
  color: #5d2333;
  font-size: 20rem;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
}

.pay-session3-li-top2 {
  background-color: #eee7e0;
}

.pay-session3-li-top3 {
  background-color: #ebd9c7;
}

.pay-session3-li-top4 {
  background-color: #a52933;
  color: #fff;
}

.pay-session3-li-content {
  position: relative;
  flex: 1;
  padding: 30rem 56rem;
}

.pay-session3-li-content-text {
  position: relative;
  color: #000;
  font-size: 16rem;
  font-style: normal;
  font-weight: 400;
  line-height: 37rem;
  padding-left: 14rem;
  margin-bottom: 2rem;
}

.ar .pay-session3-li-content-text {
  padding-left: 0;
  padding-right: 14rem;
}

.pay-session3-li-content-text-light {
  color: #af8147;
}

.session4_bg {
  width: 536rem;
  height: 512rem;
  z-index: -1;
  flex-shrink: 0;
}

.pay-session3-li-content-text::before {
  content: "";
  width: 4rem;
  height: 4rem;
  position: absolute;
  left: 0;
  top: 18rem;
  background-color: #000;
  border-radius: 50%;
}

.ar .pay-session3-li-content-text::before {
  right: 0;
  left: unset;
}


.pay-session3-li-content-text-light::before {
  content: "";
  width: 4rem;
  height: 4rem;
  position: absolute;
  left: 0;
  top: 12rem;
  background-color: #af8147;
  border-radius: 50%;
}

.pay-session3-li-content-bg1 {
  height: 100%;
  /* background-image: url(../assets/image/pay/session3_bg1.png);
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: auto 243rem; */
}

.pay-session3-li-content-bg2 {
  height: 100%;
  /* background-image: url(../assets/image/pay/session3_bg2.png);
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: auto 315rem; */
}

.pay-session3-li-content-bg3 {
  height: 100%;
  /* background-image: url(../assets/image/pay/session3_bg3.png);
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: auto 350rem; */
}

.pay-session3-li-content-bg4 {
  height: 100%;
  /* background-image: url(../assets/image/pay/session3_bg4.png);
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: auto 386rem; */
}

.pay-session4-content {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* height: 526rem; */
  margin-top: 106rem;
}

.pay-session4-content-row {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.gap-180 {
  gap: 180rem;
}

.mt-165 {
  margin-top: 165rem;
}

.pay-session4-content-item {
  border-radius: 8rem;
  border: 1rem solid #e3e3e3;
  background: #fff;
  box-shadow: 0rem 9rem 15rem 0rem rgba(0, 0, 0, 0.14);
  padding: 20rem 30rem;
}

.pay-session4-content-item-label {
  color: #a52933;
  font-size: 28rem;
  font-style: normal;
  font-weight: 700;
  line-height: 138%;
}

.pay-session4-content-item-info {
  color: #666;
  font-size: 18rem;
  font-style: normal;
  font-weight: 400;
  line-height: 138%;
}

.pay-session4-content-left,
.pay-session4-content-right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.pay-session4-tips {
  margin-top: 106rem;
  color: #999;
  text-align: center;
  font-size: 24rem;
  font-style: normal;
  font-weight: 350;
  line-height: 150%;
  /* 27rem */
}

.pay-session5-title {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24rem;
}

.pay-session5-content {
  margin: 80rem auto 0;
  max-width: 768rem;
}

.tip-title {
  padding: 8rem;
  background: #f8f8f8;
  color: #333;
  font-size: 24rem;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
}

.tip-content {
  margin: 16rem 0 48rem 0;
  color: #666;
  font-size: 21rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  padding: 0 8rem;
}

.tip-content:last-child {
  margin-bottom: 60rem;
}

.tip-content-li {
  position: relative;
  padding-left: 15rem;
  margin-bottom: 6rem;
}

.tip-content-li::before {
  content: "";
  display: block;
  width: 4rem;
  height: 4rem;
  background-color: #666;
  position: absolute;
  left: 3rem;
  top: 15rem;
  border-radius: 50%;
}

.ar .tip-content-li {
  padding-left: 0;
  padding-right: 15rem;
}

.ar .tip-content-li::before {
  left: unset;
  right: 3rem;
}

.no-dot::before {
  width: 0;
  height: 0;
}

@media (max-width: 996px) {
  .mobile {
    display: block;
  }

  .pc {
    display: none;
  }

  .pay-session2-title,
  .pay-session3-title,
  .pay-session4-title,
  .pay-session5-title {
    font-size: 80rem;
    max-width: 700rem;
  }

  .en .pay-session2-title,
  .en .pay-session3-title,
  .en .pay-session4-title,
  .en .pay-session5-title {
    font-size: 70rem;
  }

  .pay-session2-subtitle,
  .pay-session3-subtitle,
  .pay-session4-subtitle {
    margin-top: 40rem;
    font-size: 36rem;
  }

  .en .pay-session2-subtitle,
  .en .pay-session3-subtitle,
  .en .pay-session4-subtitle {
    font-size: 32rem;
  }

  .pay-session1 {
    padding: 66rem 80rem 370rem;
    background-image: url(../assets/image/pay/session1_bg_m.png);
    /* background-size: 100% auto; */
    background-position: bottom;
    background-repeat: no-repeat;
  }

  .pay-session1-title {
    /* text-align: center; */
    font-size: 90rem;
  }

  .en .pay-session1-title {
    font-size: 80rem;
  }

  .pay-session1-detail {
    font-size: 28rem;
    max-width: none;
  }

  .pay-session1-list {
    font-size: 36rem;
  }

  .en .pay-session1-list {
    font-size: 32rem;
  }

  .pay-session1-li :first-child {
    font-size: 26rem;
  }

  .btn-go {
    display: flex;
    padding: 22rem 48rem;
    font-size: 36rem;
  }

  .pay-session2,
  .pay-session3,
  .pay-session4,
  .pay-session5 {
    padding: 100rem 30rem 0;
  }

  .pay-session2-list,
  .pay-session3-list {
    margin: 70rem auto 0;
    max-width: 700rem;
  }

  .pay-session2-li-top {
    height: 136rem;
    font-size: 80rem;
  }

  .pay-session2-li-top .dollar {
    font-size: 40rem;
  }

  .pay-session2-li-top-small {
    font-size: 28rem;
  }

  .pay-session2-li {
    width: 100%;
    padding-bottom: 80rem;
  }

  .pay-session2-li-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 540rem;
  }

  .pay-session2-li-content-name {
    font-size: 32rem;
  }

  .pay-session2-li-bottom {
    font-size: 28rem;
    padding: 20rem 30rem;
  }

  .es .pay-session2-li-bottom {
    font-size: 24rem;
  }

  .pay-session2-li-top-mini {
    font-size: 28rem;
  }

  .pay-session2-li-content-name .icon-Vector {
    font-size: 18rem;
  }

  .copytrading-body .swiper-slide .pay-session3-li {
    width: 100%;
    padding-bottom: 80rem;
    height: 100%;
  }

  .pay-session3-li-content-text {
    font-size: 28rem;
    line-height: 56rem;
  }

  .pay-session3-li-content-text::before {
    top: 26rem;
  }

  .pay-session3-li-top {
    height: 108rem;
    font-size: 32rem;
  }

  .pay-session3-li-content {
    display: flex;
    flex-direction: column;
  }

  .pay-session3-li-content>div {
    flex: 1;
  }

  /* .en .pay-session3-li-content-text {
    font-size: 14rem;
  } */
  .session4_bg_m {
    width: 364rem;
    height: 347rem;
    flex-shrink: 0;
  }

  .pay-session4-content {
    margin: 70rem auto 0;
    height: auto;
    flex-direction: column;
    max-width: 700rem;
  }

  .pay-session4-content-item-m {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .pay-session4-content-item-m-label {
    color: #a52933;
    text-align: center;
    font-size: 28rem;
    font-style: normal;
    font-weight: 500;
    line-height: 138%;
  }

  .pay-session4-content-item-m-info {
    color: #000;
    font-size: 28rem;
    font-style: normal;
    font-weight: 350;
    line-height: 138%;
  }

  .pay-session4-content-center {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
  }

  .pay-session4-content-center img {
    width: 40%;
    flex: 1;
  }

  .pay-session4-content-item-m-left,
  .pay-session4-content-item-m-right {
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    min-width: 74rem;
  }

  .pay-session4-content-card {
    margin: 64rem auto;
    width: 100%;
    padding: 28rem 48rem;
    max-width: 700rem;
    border-radius: 8rem;
    border: 1rem solid #e3e3e3;
    /* opacity: 0.65; */
    background: #fff;
    box-shadow: 0rem 9rem 15rem 0rem rgba(0, 0, 0, 0.14);
  }

  .pay-session4-content-card-item {
    padding: 38rem 0;
  }

  .pay-session4-content-card-item+.pay-session4-content-card-item {
    border-top: 1rem solid #e3e3e3;
  }

  .pay-session4-content-card-item-label {
    color: #a52933;
    text-align: center;
    font-size: 32rem;
    font-style: normal;
    font-weight: 500;
    line-height: 138%;
  }

  .pay-session4-content-card-item-info {
    margin-top: 6rem;
    color: #666;
    text-align: center;
    font-size: 24rem;
    font-style: normal;
    font-weight: 350;
    line-height: 138%;
  }

  .pay-session4-tips {
    margin-top: 32rem;
    font-size: 28rem;
  }

  .pay-session5-content {
    max-width: 700rem;
  }

  .pay-session4-content {
    gap: 10rem;
  }

  .pay-session4-content-row {
    gap: 10rem;
  }

  .gap-90 {
    gap: 180rem;
  }

  .mt-45 {
    margin-top: 94rem;
  }

  .tip-title {
    padding: 16rem;
    font-size: 30rem;
  }

  .tip-content {
    font-size: 26rem;
  }
}

.my-bullet-active {
  background: #666 !important;
  opacity: 1 !important;
}

.copytrading-fth1 {
  background: url(../assets/image/copytrading/ct-1.png) 100% / auto 100% no-repeat;
  height: auto;
  margin-top: 0rem;
  text-align: center;
  padding-top: 90rem;
  padding-bottom: 100rem;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.copytrading-fth1 .p1 {
  font-weight: 900;
  text-align: left;
  font-size: 40rem;
}

.copytrading-fth1 .p2 {
  text-align: left;
  margin-top: 40rem;
  font-size: 18rem;
}

.copytrading-fth1 .copytrading-fth-line {
  background: #FF4B4E;
  width: 77rem;
  height: 2rem;
  flex-shrink: 0;
  margin-top: 50rem;
}

.copytrading-fth2 {
  background: #fff;
  box-shadow: 0rem 2rem 10rem rgba(61, 1, 1, 0.08);
  height: 76rem;
  text-align: center;
  padding-top: 30rem;

  background: #fff;
  position: sticky;
  top: 87rem;
  left: 0;
  z-index: 9;
}

.copytrading-fth2 a {
  padding: 8rem 28rem;
  margin: 0rem 12rem;
  border: 1rem solid #A52933;
  color: #A52933;
  font-size: 16rem;
}

.copytrading-fth2 a.active,
.copytrading-fth2 a:hover {
  background: #A52933;
  color: #fff;
}

.copytrading-public-title {
  font-weight: 900;
  font-size: 40rem;
  color: #A52933;
  margin-top: 70rem;
  margin-bottom: 60rem;
}

.copytrading-public-line {
  width: 70rem;
  border: 1rem solid #A52933;
  margin: 30rem auto;
}

.copytrading-fth3 .p1 {
  font-size: 220rem;
  color: #6A6967;
  font-family: "Financier Display Light";
  font-weight: 500;
}

.copytrading-fth3 .p2 {
  font-size: 24rem;
  line-height: 36rem;
  font-weight: 600;
  letter-spacing: 2rem;
  color: #000;
  margin-top: 82rem;
}

.copytrading-fth3 .p3 {
  color: #666;
  font-size: 14rem;
  font-weight: 400;
  line-height: 26rem;
  margin-top: 20rem;
}

.copytrading-fth3 .row {
  background: linear-gradient(90deg, rgba(148, 150, 153, 0) 0%, rgba(148, 150, 153, 0.06) 92.82%);
  padding: 100rem 0 80rem;
  margin-top: 55rem;
}

.copytrading-public-link {
  background: none;
  font-size: 16rem;
  padding: 14rem 34rem;
  color: #A52933;
  border: 1rem solid #A52933;
  position: relative;
  display: inline-flex;
  align-items: center;
  /* 为右侧图标留出一些空间 */
  padding-right: calc(22rem + 22rem);
}

/* 添加SVG图标作为伪元素 */
.copytrading-public-link::after {
  content: "";
  position: absolute;
  right: 20rem;
  width: 15rem;
  height: 15rem;
  background-image: url('../assets/image/copytrading/ct-2.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  /* 完美垂直居中 */
  top: 52%;
  transform: translateY(-50%);
}

.copytrading-public-link:hover {
  background: #A52933;
  color: #fff;
}

/* 悬停时将SVG变为白色 */
.copytrading-public-link:hover::after {
  filter: brightness(0) invert(1);
  /* 将SVG转换为白色 */
}



.copytrading-pay-session2,
.copytrading-pay-session3 {
  margin: 0 auto;
  padding: 0rem 64rem;
}

.copytrading-fth4 {
  margin-top: 0;
}

.copytrading-fth3,
.copytrading-fth4,
.copytrading-pay-session2,
.copytrading-pay-session3 {
  padding: 30rem 0;
}


.copytrading-fth4 .row .list img {
  width: 100rem;
  height: 100rem;
}

.profile-name {
  color: #5D2333;
  font-size: 40rem;
  top: 76rem;
}

.copytrading-fth4 .row .list .p1 {
  font-size: 18rem;
  color: #000000;
  margin-top: 12rem;
  overflow: hidden;
  padding-bottom: 4rem;
}

.copytrading-fth4 .row .list .p3 {
  color: #FF4B4E;
  font-weight: bold;
  font-family: "Financier Display Bold";
  font-size: 40rem;
}

.copytrading-fth4 .row .list .p4 {
  font-size: 14rem;
}

.copytrading-fth4 .row .list .sp1 {
  font-size: 18rem;
  color: #6A6967;
}

.copytrading-fth4 .row .list .sp2 {
  font-size: 13rem;
  color: #949699;
  line-height: 1.2;
  margin-top: 10rem;
}

.copytrading-fth4 .row .list {
  padding: 28rem 22rem;
}

.copytrading-fth4 .list .p5 a {
  color: #fff;
  font-size: 16rem;
  padding: 10rem 28rem;
  font-family: auto;
}

.copytrading-fth4 .list .p5 a:hover {
  background: #A52933;
  color: #fff;
  border-color: #A52933;
}




.pay-session2-list {
  margin-top: 50rem;
}

.pay-session3-list {
  margin-top: 60rem;
}

.pay-session3-li-top {
  font-size: 20rem;
}

.pay-session3-li-content-text {
  font-size: 16rem;
}

.pay-session2-li-top {
  font-size: 28rem;
}

.pay-session2-li-content-name {
  font-size: 18rem;
}

.pay-session2-li-bottom {
  font-size: 14rem;
}



.copytrading-body .copytrading-link {
  margin-top: 50rem;
}

.copytrading-body .container {
  // max-width: 1400rem;
  max-width: 1680rem;
}
.copytrading-body .fth1 .container {
  max-width: 1400rem;
}



.copytrading-QA {
  background-color: #ffffff;
  position: relative;
  padding-top: 40rem;
  padding-bottom: 60rem;
}

.copytrading-QA .problem-box {
  width: 100%;
  max-width: 882rem;
  margin: 22rem auto 0
}

.copytrading-QA .problem-box .accordion {
  padding: 20rem 0 0rem;
}

.copytrading-QA .problem-box .accordion-item {
  border-radius: 4rem 4rem 4rem 4rem;
  overflow: hidden;
  border: 0 solid #d9d9d9;
  margin-bottom: 20rem;
}

.copytrading-QA .problem-box .accordion-button {
  font-size: 18rem;
  line-height: 1.5;
  color: #000 !important;
  background: #ffffff;
  font-family: auto;
}

.copytrading-QA .problem-box .accordion-button:focus,
.copytrading-QA .problem-box .accordion-button:not(.collapsed) {
  box-shadow: none;
  /* background-color: #fff; */
}



.copytrading-QA .problem-box .accordion .accordion-body {
  line-height: 1.4;
  font-size: 16rem;
  color: #6A6967;
  background: #ffffff;
  font-family: auto;
}

.copytrading-QA .problem-box .accordion .accordion-body ::v-deep p {
  padding-bottom: 13rem
}

.copytrading-QA .problem-box .accordion .accordion-body ::v-deep a {
  color: #2ea3f2
}

.copytrading-QA .problem-box .accordion-item {
  border: none
}

.copytrading-QA .rotate {
  transform: none;
}

.copytrading-QA .rotate .accordion-button:after {
  transform: rotate(180deg);
}

.copytrading-QA .page-title {
  text-align: center
}

.copytrading-QA .leaf-left,
.copytrading-QA .leaf-right {
  position: absolute;
  top: 0;
  right: 0
}

.copytrading-QA .leaf-left img,
.copytrading-QA .leaf-right img {
  width: 650rem;
  display: block
}

.copytrading-QA .leaf-left {
  left: -70rem;
  right: auto
}

.pay-session3-li-content-text {
  line-height: 1.5;
  margin-bottom: 12rem;
}

.pay-session3-li-content-text::before {
  top: 50%;
}

.copytrading-fth3 .row div {
  padding: 0 20rem;
}

.copytrading-fth1 .p1 {
  letter-spacing: 2px;
}

.copytrading-fth1 .p2 {
  letter-spacing: 1px;
}

.copytrading-public-title {
  letter-spacing: 1px;
}


.en-copytrading-body .copytrading-fth1 .p2,
.en-copytrading-body .copytrading-fth3 .p2,
.en-copytrading-body .message-new .p1 {
  letter-spacing: 0 !important;
}

.en-copytrading-body .copytrading-fth1 .p1,
.en-copytrading-body .copytrading-public-title {
  letter-spacing: 1px !important;
}

.en-copytrading-body .copytrading-fth1 .p1,
.en-copytrading-body .copytrading-public-title {
  font-size: 32rem;
}

.en-copytrading-body .copytrading-fth1 .p2 {
  font-family: auto;
}

.en-copytrading-body .copytrading-fth3 .p2 {
  font-size: 20rem;
  line-height: 1.4;
}

.en-copytrading-body .copytrading-fth3 .row div {
  padding: 0 16rem;
}

.en-copytrading-body .copytrading-fth3 .p3 {
  font-size: 14rem;
  font-family: auto;
  line-height: 1.4;
}

.en-copytrading-body .copytrading-fth3 .container {
  max-width: 78%;
}


.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1em 1.25em;
  font-size: 1em;
  color: #212529;
  text-align: left;
  background-color: #fff;
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: color .15sease-in-out, background-color .15sease-in-out, border-color .15sease-in-out, box-shadow .15sease-in-out, border-radius .15sease;
}

.accordion-button::after {
  flex-shrink: 0;
  width: 1.25em;
  height: 1.2em;
  margin-left: auto;
  content: "";
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 1.25em;
  transition: transform .2s ease-in-out;
}

.accordion-body {
  padding: 1em 1.25em;
}

.pay-session3-list {
  position: relative;
  margin-top: 80rem;
  display: flex;
  flex-direction: row-reverse;
  gap: 20rem;
}

.pay-session3-li {
  width: calc(25% - 15rem);
  display: flex;
  flex-direction: column;
  border-radius: 8rem;
  border: 1px solid #e1e6ed;
  background: #fff;
  overflow: hidden;
}

.pay-session3-li-top {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 71rem;
  background: linear-gradient(141deg, #fefaf6 0%, #f9f2ec 100%);
  color: #333;
  font-size: 20rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-bottom: 1px solid #e7dcd3;
}

.pay-session3-li-top4 {
  height: calc(71rem + 1px);
  background: linear-gradient(141deg, #ad0a3a 0%, #360d1b 100%),
    linear-gradient(141deg, #673a37 0%, #180b0b 100%);
  color: #fff;
  border-bottom: none;
}

.pay-session3-li-top4>span {
  background: linear-gradient(270deg, #ebcaa7 0%, #fdfef9 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.pay-session3-li-content {
  position: relative;
  flex: 1;
  padding: 28rem;
}

.pay-session3-li-content-text {
  position: relative;
  color: #000;
  font-size: 14rem;
  font-style: normal;
  font-weight: 400;
  line-height: 20rem;
  padding-right: 28rem;
  margin-bottom: 16rem;
}

.ar .pay-session3-li-content-text {
  padding-left: 28rem;
  padding-right: 0;
}

.pay-li-content-text-light .pay-session3-li-content-text,
.pay-session3-list .pay-session3-li:nth-child(4) .pay-session3-li-content-text {
  color: #9f734c;
}

@media(max-width:995px) {
  .copytrading-body .container {
    width: 100%;
  }

  .copytrading-QA .problem-box .accordion .accordion-body {
    font-size: 28rem;
    padding: 2rem 2.5rem;
  }

  .copytrading-QA .problem-box .accordion {
    padding-top: 0rem;
  }

  .copytrading-QA .problem-box .accordion {
    margin-bottom: 100rem;
  }

  .copytrading-QA {
    padding-bottom: 200rem;
  }

  .en-style2 .copytrading-QA {
    padding-bottom: 140rem;
  }

  .copytrading-QA {
    background: #ffffff;
  }

  .copytrading-QA .problem-box .accordion-button {
    background: #ffffff;
    font-size: 32rem;
  }

  .copytrading-QA .problem-box .accordion .accordion-body {
    background: #ffffff;
  }

  .copytrading-QA {
    padding-top: 120rem;
    padding-bottom: 0;
  }

  .copytrading-fth2 {
    display: none;
  }

  .copytrading-fth1 {
    height: auto;
    background: url(../assets/image/copytrading/ct-1-m.png);
    background-size: cover;
    padding: 120px 8%;
    text-align: center;
  }

  .copytrading-fth1 .p1 {
    text-align: center;
    padding: 0;
    font-size: 72rem;
    line-height: 1.4;
    letter-spacing: 0;
  }

  .copytrading-fth1 .p2 {
    text-align: center;
    margin-top: 80rem;
    font-size: 34rem;
    line-height: 1.4;
  }

  .copytrading-fth1 .copytrading-fth-line {
    margin: 100rem auto 0;
  }

  .copytrading-public-title {
    font-size: 72rem;
    letter-spacing: 0;
  }

  .copytrading-fth3 .row {
    background: none;
    padding-top: 0px;
    padding-bottom: 0px;
    margin-top: 100rem;
  }

  .copytrading-fth3 .p2 {
    font-size: 44rem;
    line-height: 1.2em;
  }

  .copytrading-fth3 .row div {
    margin: 68rem 0;
  }

  .copytrading-fth3,
  .copytrading-fth4,
  .copytrading-pay-session2,
  .copytrading-pay-session3 {
    padding: 60rem 5%;
  }

  .copytrading-fth4 .row .list .sp1 {
    font-size: 32rem;
  }

  .pay-session2-li-content {
    min-height: 350rem;
  }

  .pay-session2-li-top {
    font-size: 40rem;
    height: 80rem;
  }

  .copytrading-body .pay-session3-list .swiper-slide {
    display: flex;
    height: auto;
  }

  .pay-session2-li-top-small {
    font-size: 16rem;
  }

  .pay-session2-li-top .dollar {
    font-size: 26rem;
  }

  .pay-session2-li-top-center {
    font-weight: bold;
  }

  .pay-session2-li-content-name {
    font-size: 20rem;
  }

  .pay-session2-li {
    width: auto;
    padding-bottom: 0rem;
  }


  .pay-session3-li-top {
    height: 80rem;
  }

  .pay-session3-li-content-text {
    line-height: 1.4;
    margin-bottom: 10rem;
  }

  .pay-session3-li-content-text::before {
    top: 50%;
  }

  .copytrading-QA {
    padding: 100rem 5% 0;
  }

  .copytrading-fth3 .p3 {
    font-size: 24rem;
    line-height: 1.2em;
  }

  .message-new .p1 {
    letter-spacing: 0 !important;
  }

  .copytrading-QA .problem-box .accordion .cont-info {
    padding: 0rem 44rem;
  }

  .copytrading-body .swiper-slide .pay-session3-li {
    padding-bottom: 0;
  }


  .en-copytrading-body .copytrading-fth1 .p1,
  .en-copytrading-body .copytrading-public-title {
    font-size: 56rem;
  }

  .en-copytrading-body .copytrading-fth1 .p2 {
    font-family: auto;
    font-size: 32rem;
  }

  .en-copytrading-body .copytrading-fth3 .p2 {
    font-size: 40rem;
    line-height: 1.4;
  }

  .en-copytrading-body .copytrading-fth3 .row div {
    padding: 0 10rem;
  }

  .en-copytrading-body .copytrading-fth3 .p3 {
    font-size: 28rem;
    font-family: auto;
    line-height: 1.4;
  }

  .en-copytrading-body .copytrading-fth3 .container {
    max-width: 100%;
  }

  .en-copytrading-body .pay-session2-li-top-mini {
    font-size: 36rem;
  }

  .pay-session2-li-top-mini {
    font-size: 16rem;
  }

  .pay-session2-list,
  .pay-session3-list {
    margin: 70rem auto 0;
    max-width: 700rem;
  }

  .pay-session2-li-top {
    height: 136rem;
    font-size: 80rem;
  }

  .pay-session2-li-top .dollar {
    font-size: 40rem;
  }

  .pay-session2-li-top-small {
    font-size: 28rem;
  }

  .pay-session2-li {
    width: 100%;
    padding-bottom: 80rem;
  }

  .pay-session2-li-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 540rem;
  }

  .pay-session2-li-content-name {
    font-size: 32rem;
  }

  .pay-session2-li-bottom {
    font-size: 28rem;
    padding: 20rem 30rem;
  }

  .es .pay-session2-li-bottom {
    font-size: 24rem;
  }

  .pay-session2-li-top-mini {
    font-size: 28rem;
  }

  .pay-session2-li-content-name .icon-Vector {
    font-size: 18rem;
  }

  .swiper-slide .pay-session3-li {
    width: auto;
    margin: 0 100rem;
    height: 100%;
  }

  .pay-session3-li-content-text {
    font-size: 28rem;
    line-height: normal;
  }



  .pay-session3-li-top {
    height: 108rem;
    font-size: 32rem;
  }

  .pay-session3-li-content {
    display: flex;
    flex-direction: column;
  }

  .pay-session3-li-content>div {
    flex: 1;
  }

  .pay-session3-list .my-prev::after,
  .pay-session3-list .my-next::after {
    font-size: 48rem;
    color: #9f734c;
  }

  .jp .pay-session2-card,
  .pay-session2-card {
    margin-top: 80rem;
    width: 100%;
    height: auto;
    padding: 126rem 28rem 60rem;
    min-height: 800rem;
    background: url(../assets/image/pay/session2_card2_m.jpg);
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .ar .pay-session2-card-content,
  .pay-session2-card-content {
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .ar .pay-session2-card-content h2,
  .pay-session2-card-content h2{
    padding: 0;
  }

  .en .pay-session2-card h2,
  .pay-session2-card h2 {
    text-align: center;
    font-size: 42rem;
  }

  .pay-session2-card-content .number {
    font-size: 170rem;
  }

  .pay-session2-card-content .unit {
    font-size: 68rem;
  }

  .pay-session2-card-content .p2 {
    font-size: 28rem;
  }

  .pay-session2-card-bottom {
    flex-direction: column;
    gap: 30rem;
    margin-top: 46rem;
  }

  .pay-session2-card-bottom-line {
    display: none;
  }

  .pay-session2-card-bottom-item {
    min-width: auto;
  }

  .pay-session2-card-bottom-item span {
    text-align: center;
  }

  .pay-session2-card-bottom-item span+span {
    margin-top: 10rem;
  }

  .pay-session2-card-bottom-item>span:first-child {
    font-size: 42rem;
  }

  .pay-session2-card-bottom-item>span:last-child {
    font-size: 28rem;
  }

  .ar .pay-session2-card-content-icon,
  .pay-session2-card-content-icon {
    top: -69rem;
    right: calc(50% - 69rem);
  }

  .pay-session2-card-content-icon img {
    width: 138rem;
    height: 138rem;
  }

  .en .pay-session2-card-light,
  .pay-session2-card-light {
    background: url(../assets/image/pay/session2_card1_m.jpg);
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .copytrading-public-link {
    font-size: 32rem;
    padding: 28rem 68rem;
    /* 为右侧图标留出一些空间 */
    padding-right: calc(20rem + 68rem);
  }
  .copytrading-public-link::after{
    right:58rem;
    width: 20rem;
    height: 20rem;
  }
  .copytrading-fth4 .row .list .p1{
    font-size: 34rem;
    margin-top: 24rem;
    padding-bottom: 8rem;
  }
  .copytrading-fth4 .row .list .p3{
    font-size: 80rem;
  }
  .copytrading-fth4 .row .list .p4{
    font-size: 24rem;
  }
  .copytrading-fth4 .row .list .sp2{
    font-size: 24rem;
  }
  .copytrading-fth4 .list .p5 a{
    font-size: 32rem;
    padding: 20rem 56rem;
  }
  .copytrading-fth4 .row .list img{
    width: 200rem;
    height: 200rem;
  }
  .profile-name{
    font-size: 80rem;
    top: 128rem;
  }
}
</style>