<template>
  <div :class="[$t('common.fontFamily'), $t('common.lang'), $t('common.type')]">
    <page-navigate></page-navigate>
    <div>
      <section class="section1">
        <h1 :class="$t('common.fontFamilyTitle')">{{ $t('Dividend.股息调整') }}</h1>
        <p class="p1">{{ $t('Dividend.了解您在股票和指数差价合约交易中的股息调整和分配机制') }}</p>
        <div class="btn-box">
          <a class="btn" :href="signupUrl" target="_blank">{{ $t('Dividend.了解指数差价合约') }}</a>
          <a class="btn btn-light" :href="signupUrl" target="_blank">{{ $t('Dividend.了解股票差价合约') }}</a>
        </div>
      </section>
      <section class="section2">
        <div class="section2-card">
          <div class="section2-card-box ">
            <h2 :class="$t('common.fontFamilyTitle')">{{ $t('Dividend.股息调整') }}</h2>
            <p class="p1">
              {{ $t('Dividend.股息是上市公司从利润或保留资金中定期分配给股东的资金') }}
            </p>
          </div>
          <img class="section2-card-img" src="@/assets/image/dividend/card1.png" alt="" />
        </div>
        <div class="section2-card">
          <div class="section2-card-box ">
            <h2 :class="$t('common.fontFamilyTitle')">{{ $t('Dividend.除息日与交易优化') }}</h2>
            <p class="p1">
              {{ $t('Dividend.指数差价合约的除息日由指数提供商根据成分股设定') }}
            </p>
          </div>
          <img class="section2-card-img" src="@/assets/image/dividend/card2.png" alt="" />
        </div>
      </section>
      <section class="section3">
        <h1 class="" :class="$t('common.fontFamilyTitle')">{{ $t('Dividend.股息调整明细') }}</h1>
        <p class="p1">
          {{ $t('Dividend.差价合约交易中相关产品的股息数据') }}
        </p>
        <div class="section3-content">
          <div class="product-box">
            <div class="product-tab-list cn-font-family">
              <div class="product-tab-item">{{ $t('Dividend.交易产品') }}</div>
              <div class="product-tab-item">{{ $t('Dividend.多头股息') }}</div>
              <div class="product-tab-item">{{ $t('Dividend.空头股息') }}</div>
              <div class="product-tab-item active-product-tab">{{ $t('Dividend.除息日') }}</div>
            </div>

            <div class="product-table-tr" v-for="(item, index) in data" :key="index">
              <div class="product-table-td">{{ item.symbol }}</div>
              <div class="product-table-td">{{ item.long }}</div>
              <div class="product-table-td">{{ item.short }}</div>
              <div class="product-table-td">{{ item.date }}</div>
            </div>

          </div>
        </div>
        <div class="btn-box">
          <a class="btn" :href="signupUrl" rel="nofollow">{{ $t('Dividend.即刻交易') }} </a>
        </div>
      </section>
      <section class="section4">
        <h1 class="" :class="$t('common.fontFamilyTitle')">{{ $t('Dividend.股息的常见问题') }}</h1>
        <qa-list :data="$t('Dividend.qaList')"></qa-list>
      </section>
    </div>
    <ss-contact-us-new></ss-contact-us-new>
    <page-footer></page-footer>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  data() {
    return {
      tab: this.$t('Dividend.list'),
      data: [],
    }
  },
  created() {
    this.$http('/ebc/api/getCfd/run').then(res => {
      console.log("data", res.data.data)
      this.data = res.data.data
    })

  },
  computed: {
    ...mapGetters([
      'signupUrl',
    ])
  },
  methods: {
  }
}
</script>

<style scoped>
.section1 {
  height: 333rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: url("../assets//image/dividend/section1_bg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}

.section1 h1 {
  color: #fff;
  text-align: center;
  font-size: 40rem;
  font-style: normal;
  font-weight: 900;
  line-height: 48rem;
}

.section1 .p1 {
  margin-top: 25rem;
  color: #fff;
  text-align: center;
  font-size: 18rem;
  font-style: normal;
  font-weight: 400;
  line-height: 25rem;
  padding: 0 180rem;
}

.section1 .btn-box {
  width: auto;
  margin: 43rem 0 0;
}

.section2,
.section3,
.section4 {
  padding: 120rem 150rem;
}

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 41rem;
  min-width: 200rem;
  border-radius: 2rem;
  background: #fff;
  padding: 14rem 20rem;
  color: #a52933;
  text-align: center;
  font-size: 18rem;
  font-style: normal;
  font-weight: 400;
  transition: all 0.3s;
}

.btn:hover {
  background: #a52933;
  color: #fff;
}

.btn-light {
  background: #a52933;
  color: #fff;
}

.btn-box {
  display: flex;
  justify-content: center;
  gap: 25rem;
}

.btn-box .btn {
  border: 1px solid #a52933;
}

.btn-box .btn-light:hover {
  background: #9f734c;
  border-color: #9f734c;
}

.section2-card {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  width: 1400rem;
  padding: 28rem 124rem;
  background: linear-gradient(90deg,
      rgba(246, 246, 246, 0.5) 0%,
      rgba(217, 217, 217, 0) 100%);
}

.section2-card+.section2-card {
  margin-top: 45rem;
}

.section2-card-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.section2-card-box h2 {
  color: #a52933;
  font-size: 36rem;
  font-style: normal;
  font-weight: 900;
  line-height: 1em;
}

.section2-card-box .p1 {
  margin-top: 50rem;
  width: 470rem;
  color: #666;
  font-size: 18rem;
  font-style: normal;
  font-weight: 400;
  line-height: 28rem;
}

.section2-card-img {
  width: 471rem;
  height: 340rem;
}

.section3 {
  background-color: #f6f6f6;
}

.section3 h1,
.section4 h1 {
  color: #a52933;
  text-align: center;
  font-size: 36rem;
  font-style: normal;
  font-weight: 900;
  line-height: 1em;
}

.section3 .p1 {
  margin-top: 38rem;
  color: #666;
  text-align: center;
  font-size: 18rem;
  font-style: normal;
  font-weight: 400;
  line-height: 28rem;
  padding: 0 180rem;
}

.detail-list {
  width: 1200rem;
  margin: 110rem auto 0;
}

.product-box {
  width: 1100rem;
  margin: 100rem auto 110rem;
  font-size: 18rem;
  font-family: Gilroy;
}

.product-tab-list {
  display: flex;
  height: 58rem;
  background: #f9f6f2;
}

.product-tab-item {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #333;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 24.99%;
  height: 100%;
  cursor: pointer;
}

.active-product-tab {
  background: #a52933;
  color: #fff;
}

.product-table-tr {
  display: flex;
  height: 48rem;
  background-color: #fff;
}

.product-table-tr .product-table-td {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 24.99%;
  color: #333;
  font-style: normal;
  font-weight: 400;
  line-height: 1em;
}

.product-table-tr:nth-child(2n + 1) {
  background: #f9f9f9;
}


@media (max-width: 996px) {
  .btn-box {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .en .btn {
    min-width: 380rem;
  }

  .btn {
    min-width: 334rem;
    height: 56rem;
    font-size: 22rem;
  }

  .section1 {
    height: 443rem;
    background: url("../assets//image/dividend/section1_bg_m.jpg");
    background-size: cover;
    background-repeat: no-repeat;
  }

  .section2,
  .section3 {
    padding: 100rem 28rem;
  }

  .section4 {
    padding: 100rem 80rem;
  }

  .product-box {
    width: 100%;
  }

  .section2-card {
    width: 100%;
    flex-direction: column;
    gap: 60rem;
    background: linear-gradient(180deg,
        rgba(246, 246, 246, 0.5) -24.96%,
        rgba(217, 217, 217, 0) 98.48%);
    padding: 64rem 48rem;
  }

  .section2-card-box h2 {
    font-size: 40rem;
    text-align: center;
  }

  .section2-card-box .p1 {
    width: 100%;
    text-align: center;
    font-size: 20rem;
  }

  .section2-card-img {
    margin: 0 auto;
    width: 483rem;
    height: 340rem;
  }

  .section2 h1,
  .section3 h1,
  .section4 h1 {
    font-size: 40rem;
  }

  .section1 .p1,
  .section2 .p1,
  .section3 .p1 {
    padding: 0 30rem;
    font-size: 20rem;
    line-height: normal;
    margin-top: 26rem;
  }

}
</style>
