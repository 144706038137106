<template>
	<div>
		<page-navigate></page-navigate>
		<div :class="[$t('common.fontFamily'), $t('common.lang'), $t('common.type')]">
			<div class="pay-wrapper ">
				<div class="pay-session1-box">
					<div class="pay-session1">
						<div class="pay-session1-title " :class="$t('common.fontFamilyTitle')">
							{{ $t('pay.交易领薪计划') }}
						</div>
						<div class="pay-session1-detail">
							{{ $t('pay.为了鼓励交易者提升交易水平') }}<br />
							{{ $t('pay.加入交易社区') }}
						</div>
						<div class="pay-session1-list">
							<div class="pay-session1-li">
								<span class="iconfont icon-Vector"></span><span>{{ $t('pay.即刻发起交易信号') }} </span>
							</div>
							<div class="pay-session1-li">
								<span class="iconfont icon-Vector"></span><span>{{ $t('pay.无限奖励月月拿') }} </span>
							</div>
							<div class="pay-session1-li">
								<span class="iconfont icon-Vector"></span><span>{{ $t('pay.优享11个专属大礼') }} </span>
							</div>
						</div>
						<a :href="signupUrl" class="btn-go">
							{{ $t('pay.即刻加入') }}<span class="iconfont icon-right"></span>
						</a>
					</div>
				</div>
				<div class="pay-session2">
					<div class="pay-session2-title " :class="$t('common.fontFamilyTitle')" v-html="$t('pay.交易员等级奖励机制')">
					</div>
					<div class="pay-session2-subtitle">
						{{ $t('pay.EBC基于跟随人数和资金提供五档奖励') }}
					</div>
					<div class="pc">
						<div class="pay-session2-list">
							<div class="pay-session2-card pay-session2-card-light">
								<div class="pay-session2-card-content">
									<h2>
										<span>{{ $t('pay.传奇交易员') }}</span>
									</h2>
									<p class="p1">
										<span class="number">18</span>
										<span class="unit">%</span>
									</p>
									<p class="p2">{{ $t('pay.年化资金管理费') }}</p>
								</div>
								<div class="pay-session2-card-bottom">
									<div class="pay-session2-card-bottom-item">
										<span>>$1,000,000 </span>
										<span>{{ $t('pay.管理资金量') }} </span>
									</div>
									<div class="pay-session2-card-bottom-line"></div>
									<div class="pay-session2-card-bottom-item">
										<span>>200 </span>
										<span>{{ $t('pay.跟随账户数') }} </span>
									</div>
								</div>

								<div class="pay-session2-card-content-icon">
									<img src="../assets/image/pay/session2_card_icon1.png" alt="" />
								</div>
							</div>
							<div class="pay-session2-card">
								<div class="pay-session2-card-content">
									<h2>
										<span>{{ $t('pay.明星交易员') }}</span>
									</h2>
									<p class="p1">
										<span class="number">15</span>
										<span class="unit">%</span>
									</p>
									<p class="p2">{{ $t('pay.年化资金管理费') }}</p>
								</div>
								<div class="pay-session2-card-bottom">
									<div class="pay-session2-card-bottom-item">
										<span>>$500,000 </span>
										<span>{{ $t('pay.管理资金量') }} </span>
									</div>
									<div class="pay-session2-card-bottom-line"></div>
									<div class="pay-session2-card-bottom-item">
										<span>>100 </span>
										<span>{{ $t('pay.跟随账户数') }} </span>
									</div>
								</div>

								<div class="pay-session2-card-content-icon">
									<img src="../assets/image/pay/session2_card_icon2.png" alt="" />
								</div>
							</div>
							<div class="pay-session2-card">
								<div class="pay-session2-card-content">
									<h2>
										<span>{{ $t('pay.冠军交易员') }}</span>
									</h2>
									<p class="p1">
										<span class="number">10</span>
										<span class="unit">%</span>
									</p>
									<p class="p2">{{ $t('pay.年化资金管理费') }}</p>

								</div>
								<div class="pay-session2-card-bottom">
									<div class="pay-session2-card-bottom-item">
										<span>>$100,000 </span>
										<span>{{ $t('pay.管理资金量') }} </span>


									</div>
									<div class="pay-session2-card-bottom-line"></div>
									<div class="pay-session2-card-bottom-item">
										<span>>50 </span>
										<span>{{ $t('pay.跟随账户数') }} </span>
									</div>
								</div>

								<div class="pay-session2-card-content-icon">
									<img src="../assets/image/pay/session2_card_icon3.png" alt="" />
								</div>
							</div>
							<div class="pay-session2-card">
								<div class="pay-session2-card-content">
									<h2>
										<span>{{ $t('pay.大师交易员') }}</span>
									</h2>
									<p class="p1">
										<span class="number">8</span>
										<span class="unit">%</span>
									</p>
									<p class="p2">{{ $t('pay.年化资金管理费') }}</p>
								</div>
								<div class="pay-session2-card-bottom">
									<div class="pay-session2-card-bottom-item">
										<span>>$50,000 </span>
										<span>{{ $t('pay.管理资金量') }} </span>
									</div>
									<div class="pay-session2-card-bottom-line"></div>
									<div class="pay-session2-card-bottom-item">
										<span>>30 </span>
										<span>{{ $t('pay.跟随账户数') }} </span>
									</div>
								</div>

								<div class="pay-session2-card-content-icon">
									<img src="../assets/image/pay/session2_card_icon4.png" alt="" />
								</div>
							</div>
							<div class="pay-session2-card">
								<div class="pay-session2-card-content">
									<h2>
										<span>{{ $t('pay.精英交易员') }}</span>
									</h2>
									<p class="p1">
										<span class="number">5</span>
										<span class="unit">%</span>
									</p>
									<p class="p2">{{ $t('pay.年化资金管理费') }}</p>
								</div>
								<div class="pay-session2-card-bottom">
									<div class="pay-session2-card-bottom-item">
										<span>>$20,000 </span>
										<span>{{ $t('pay.管理资金量') }} </span>
									</div>
									<div class="pay-session2-card-bottom-line"></div>
									<div class="pay-session2-card-bottom-item">
										<span>≥10</span>
										<span>{{ $t('pay.跟随账户数') }} </span>
									</div>
								</div>

								<div class="pay-session2-card-content-icon">
									<img src="../assets/image/pay/session2_card_icon5.png" alt="" />
								</div>
							</div>
						</div>
					</div>
					<div class="mobile">
						<div class="pay-session2-list">
							<swiper ref="reward-list" :options="options">

								<swiper-slide>
									<div class="pay-session2-card pay-session2-card-light">
										<div class="pay-session2-card-content">
											<h2>
												<span>{{ $t('pay.传奇交易员') }}</span>
											</h2>
											<p class="p1">
												<span class="number">18</span>
												<span class="unit">%</span>
											</p>
											<p class="p2">{{ $t('pay.年化资金管理费') }}</p>
										</div>
										<div class="pay-session2-card-bottom">
											<div class="pay-session2-card-bottom-item">
												<span>>$1,000,000 </span>
												<span>{{ $t('pay.管理资金量') }} </span>
											</div>
											<div class="pay-session2-card-bottom-line"></div>
											<div class="pay-session2-card-bottom-item">
												<span>>200 </span>
												<span>{{ $t('pay.跟随账户数') }} </span>
											</div>
										</div>

										<div class="pay-session2-card-content-icon">
											<img src="../assets/image/pay/session2_card_icon1.png" alt="" />
										</div>
									</div>
								</swiper-slide>
								<swiper-slide>
									<div class="pay-session2-card">
										<div class="pay-session2-card-content">
											<h2>
												<span>{{ $t('pay.明星交易员') }}</span>
											</h2>
											<p class="p1">
												<span class="number">15</span>
												<span class="unit">%</span>
											</p>
											<p class="p2">{{ $t('pay.年化资金管理费') }}</p>
										</div>
										<div class="pay-session2-card-bottom">
											<div class="pay-session2-card-bottom-item">
												<span>>$500,000 </span>
												<span>{{ $t('pay.管理资金量') }} </span>
											</div>
											<div class="pay-session2-card-bottom-line"></div>
											<div class="pay-session2-card-bottom-item">
												<span>>100 </span>
												<span>{{ $t('pay.跟随账户数') }} </span>
											</div>
										</div>

										<div class="pay-session2-card-content-icon">
											<img src="../assets/image/pay/session2_card_icon2.png" alt="" />
										</div>
									</div>
								</swiper-slide>
								<swiper-slide>
									<div class="pay-session2-card">
										<div class="pay-session2-card-content">
											<h2>
												<span>{{ $t('pay.冠军交易员') }}</span>
											</h2>
											<p class="p1">
												<span class="number">10</span>
												<span class="unit">%</span>
											</p>
											<p class="p2">{{ $t('pay.年化资金管理费') }}</p>
										</div>
										<div class="pay-session2-card-bottom">
											<div class="pay-session2-card-bottom-item">
												<span>>$100,000 </span>
												<span>{{ $t('pay.管理资金量') }} </span>
											</div>
											<div class="pay-session2-card-bottom-line"></div>
											<div class="pay-session2-card-bottom-item">
												<span>>50 </span>
												<span>{{ $t('pay.跟随账户数') }} </span>
											</div>
										</div>

										<div class="pay-session2-card-content-icon">
											<img src="../assets/image/pay/session2_card_icon3.png" alt="" />
										</div>
									</div>
								</swiper-slide>
								<swiper-slide>
									<div class="pay-session2-card">
										<div class="pay-session2-card-content">
											<h2>
												<span>{{ $t('pay.大师交易员') }}</span>
											</h2>
											<p class="p1">
												<span class="number">8</span>
												<span class="unit">%</span>
											</p>
											<p class="p2">{{ $t('pay.年化资金管理费') }}</p>
										</div>
										<div class="pay-session2-card-bottom">
											<div class="pay-session2-card-bottom-item">
												<span>>$50,000 </span>
												<span>{{ $t('pay.管理资金量') }} </span>
											</div>
											<div class="pay-session2-card-bottom-line"></div>
											<div class="pay-session2-card-bottom-item">
												<span>>30 </span>
												<span>{{ $t('pay.跟随账户数') }} </span>
											</div>
										</div>

										<div class="pay-session2-card-content-icon">
											<img src="../assets/image/pay/session2_card_icon4.png" alt="" />
										</div>
									</div>
								</swiper-slide>
								<swiper-slide>
									<div class="pay-session2-card">
										<div class="pay-session2-card-content">
											<h2>
												<span>{{ $t('pay.精英交易员') }}</span>
											</h2>
											<p class="p1">
												<span class="number">5</span>
												<span class="unit">%</span>
											</p>
											<p class="p2">{{ $t('pay.年化资金管理费') }}</p>
										</div>
										<div class="pay-session2-card-bottom">
											<div class="pay-session2-card-bottom-item">
												<span>>$20,000 </span>
												<span>{{ $t('pay.管理资金量') }} </span>
											</div>
											<div class="pay-session2-card-bottom-line"></div>
											<div class="pay-session2-card-bottom-item">
												<span>≥10</span>
												<span>{{ $t('pay.跟随账户数') }} </span>
											</div>
										</div>

										<div class="pay-session2-card-content-icon">
											<img src="../assets/image/pay/session2_card_icon5.png" alt="" />
										</div>
									</div>
								</swiper-slide>
							</swiper>
						</div>
					</div>
				</div>
				<div class="pay-session3">
					<div class="pay-session3-title " :class="$t('common.fontFamilyTitle')">
						{{ $t('pay.多层次的增值服务') }}
					</div>
					<div class="pay-session3-subtitle">
						{{ $t('pay.EBC提供多达11项增值服务') }}
					</div>
					<div class="pc">
						<div class="pay-session3-list">
							<div class="pay-session3-li">
								<div class="pay-session3-li-top">{{ $t('pay.精英/大师交易员') }}</div>
								<div class="pay-session3-li-content">
									<div class="pay-session3-li-content-bg1">
										<p class="pay-session3-li-content-text">{{ $t('pay.7×24小时优先客服') }}</p>
										<p class="pay-session3-li-content-text">
											{{ $t('pay.金融投资经典书籍礼遇') }}
										</p>
									</div>
								</div>
							</div>
							<div class="pay-session3-li">
								<div class="pay-session3-li-top pay-session3-li-top2">
									{{ $t('pay.冠军交易员') }}
								</div>
								<div class="pay-session3-li-content">
									<div class="pay-session3-li-content-bg2">
										<p class="pay-session3-li-content-text">{{ $t('pay.7×24小时优先客服') }}</p>
										<p class="pay-session3-li-content-text">
											{{ $t('pay.金融投资经典书籍礼遇') }}
										</p>
										<p class="pay-session3-li-content-text pay-session3-li-content-text-light">
											{{ $t('pay.专属VPS赞助') }}
										</p>
										<p class="pay-session3-li-content-text pay-session3-li-content-text-light">
											{{ $t('pay.订单流软件1年使用权') }}
										</p>
										<p class="pay-session3-li-content-text pay-session3-li-content-text-light">
											{{ $t('pay.EBC定制大礼包') }}
										</p>
									</div>
								</div>
							</div>
							<div class="pay-session3-li">
								<div class="pay-session3-li-top pay-session3-li-top3">
									{{ $t('pay.明星交易员') }}
								</div>
								<div class="pay-session3-li-content">
									<div class="pay-session3-li-content-bg3">
										<p class="pay-session3-li-content-text">{{ $t('pay.7×24小时优先客服') }}</p>
										<p class="pay-session3-li-content-text">
											{{ $t('pay.金融投资经典书籍礼遇') }}
										</p>
										<p class="pay-session3-li-content-text">{{ $t('pay.专属VPS赞助') }}</p>
										<p class="pay-session3-li-content-text">
											{{ $t('pay.订单流软件1年使用权') }}
										</p>
										<p class="pay-session3-li-content-text">{{ $t('pay.EBC定制大礼包') }}</p>
										<p class="pay-session3-li-content-text pay-session3-li-content-text-light">
											{{ $t('pay.信号优先置顶展示') }}
										</p>
										<p class="pay-session3-li-content-text pay-session3-li-content-text-light">
											{{ $t('pay.受邀参观海外办公室') }}
										</p>
										<p class="pay-session3-li-content-text pay-session3-li-content-text-light">
											{{ $t('pay.巴萨球星联名礼包') }}
										</p>
										<p class="pay-session3-li-content-text pay-session3-li-content-text-light">
											{{ $t('pay.巴萨赛场传奇之旅') }}
										</p>
									</div>
								</div>
							</div>
							<div class="pay-session3-li">
								<div class="pay-session3-li-top pay-session3-li-top4">
									<span>{{ $t('pay.传奇交易员') }}</span>

								</div>
								<div class="pay-session3-li-content">
									<div class="pay-session3-li-content-bg4">
										<p class="pay-session3-li-content-text">{{ $t('pay.7×24小时优先客服') }}</p>
										<p class="pay-session3-li-content-text">
											{{ $t('pay.金融投资经典书籍礼遇') }}
										</p>
										<p class="pay-session3-li-content-text">{{ $t('pay.专属VPS赞助') }}</p>
										<p class="pay-session3-li-content-text">
											{{ $t('pay.订单流软件1年使用权') }}
										</p>
										<p class="pay-session3-li-content-text">{{ $t('pay.EBC定制大礼包') }}</p>
										<p class="pay-session3-li-content-text">{{ $t('pay.信号优先置顶展示') }}</p>
										<p class="pay-session3-li-content-text">{{ $t('pay.受邀参观海外办公室') }}</p>
										<p class="pay-session3-li-content-text">{{ $t('pay.巴萨球星联名礼包') }}</p>
										<p class="pay-session3-li-content-text pay-session3-li-content-text-light">
											{{ $t('pay.巴萨赛场传奇之旅') }}
										</p>
										<p class="pay-session3-li-content-text pay-session3-li-content-text-light">
											{{ $t('pay.品牌战略合作') }}
										</p>
										<p class="pay-session3-li-content-text pay-session3-li-content-text-light">
											{{ $t('pay.全球金融资源管家服务') }}
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="mobile">
						<div class="pay-session3-list">
							<swiper ref="reward-list" :options="options1">
								<swiper-slide>
									<div class="pay-session3-li">
										<div class="pay-session3-li-top pay-session3-li-top4">
											<span>{{ $t('pay.传奇交易员') }}</span>
										</div>
										<div class="pay-session3-li-content light">
											<div class="pay-session3-li-content-bg4">
												<p class="pay-session3-li-content-text">
													{{ $t('pay.7×24小时优先客服') }}
												</p>
												<p class="pay-session3-li-content-text">
													{{ $t('pay.金融投资经典书籍礼遇') }}
												</p>
												<p class="pay-session3-li-content-text">{{ $t('pay.专属VPS赞助') }}</p>
												<p class="pay-session3-li-content-text">
													{{ $t('pay.订单流软件1年使用权') }}
												</p>
												<p class="pay-session3-li-content-text">
													{{ $t('pay.EBC定制大礼包') }}
												</p>
												<p class="pay-session3-li-content-text">
													{{ $t('pay.信号优先置顶展示') }}
												</p>
												<p class="pay-session3-li-content-text">
													{{ $t('pay.受邀参观海外办公室') }}
												</p>
												<p class="pay-session3-li-content-text">
													{{ $t('pay.巴萨球星联名礼包') }}
												</p>
												<p
													class="pay-session3-li-content-text pay-session3-li-content-text-light">
													{{ $t('pay.巴萨赛场传奇之旅') }}
												</p>
												<p
													class="pay-session3-li-content-text pay-session3-li-content-text-light">
													{{ $t('pay.品牌战略合作') }}
												</p>
												<p
													class="pay-session3-li-content-text pay-session3-li-content-text-light">
													{{ $t('pay.全球金融资源管家服务') }}
												</p>
											</div>
										</div>
									</div>
								</swiper-slide>
								<swiper-slide>
									<div class="pay-session3-li">
										<div class="pay-session3-li-top pay-session3-li-top3">
											{{ $t('pay.明星交易员') }}
										</div>
										<div class="pay-session3-li-content">
											<div class="pay-session3-li-content-bg3">
												<p class="pay-session3-li-content-text">
													{{ $t('pay.7×24小时优先客服') }}
												</p>
												<p class="pay-session3-li-content-text">
													{{ $t('pay.金融投资经典书籍礼遇') }}
												</p>
												<p class="pay-session3-li-content-text">{{ $t('pay.专属VPS赞助') }}</p>
												<p class="pay-session3-li-content-text">
													{{ $t('pay.订单流软件1年使用权') }}
												</p>
												<p class="pay-session3-li-content-text">
													{{ $t('pay.EBC定制大礼包') }}
												</p>
												<p
													class="pay-session3-li-content-text pay-session3-li-content-text-light">
													{{ $t('pay.信号优先置顶展示') }}
												</p>
												<p
													class="pay-session3-li-content-text pay-session3-li-content-text-light">
													{{ $t('pay.受邀参观海外办公室') }}
												</p>
												<p
													class="pay-session3-li-content-text pay-session3-li-content-text-light">
													{{ $t('pay.巴萨球星联名礼包') }}
												</p>
												<p
													class="pay-session3-li-content-text pay-session3-li-content-text-light">
													{{ $t('pay.巴萨赛场传奇之旅') }}
												</p>
											</div>
										</div>
									</div>
								</swiper-slide>
								<swiper-slide>
									<div class="pay-session3-li">
										<div class="pay-session3-li-top pay-session3-li-top2">
											{{ $t('pay.冠军交易员') }}
										</div>
										<div class="pay-session3-li-content">
											<div class="pay-session3-li-content-bg2">
												<p class="pay-session3-li-content-text">
													{{ $t('pay.7×24小时优先客服') }}
												</p>
												<p class="pay-session3-li-content-text">
													{{ $t('pay.金融投资经典书籍礼遇') }}
												</p>
												<p
													class="pay-session3-li-content-text pay-session3-li-content-text-light">
													{{ $t('pay.专属VPS赞助') }}
												</p>
												<p
													class="pay-session3-li-content-text pay-session3-li-content-text-light">
													{{ $t('pay.订单流软件1年使用权') }}
												</p>
												<p
													class="pay-session3-li-content-text pay-session3-li-content-text-light">
													{{ $t('pay.EBC定制大礼包') }}
												</p>
											</div>
										</div>
									</div>
								</swiper-slide>
								<swiper-slide>
									<div class="pay-session3-li">
										<div class="pay-session3-li-top">{{ $t('pay.精英/大师交易员') }}</div>
										<div class="pay-session3-li-content">
											<div class="pay-session3-li-content-bg1">
												<p class="pay-session3-li-content-text">
													{{ $t('pay.7×24小时优先客服') }}
												</p>
												<p class="pay-session3-li-content-text">
													{{ $t('pay.金融投资经典书籍礼遇') }}
												</p>
											</div>
										</div>
									</div>
								</swiper-slide>
								<!-- 导航按钮 -->
								<div class="swiper-button-prev my-prev" slot="button-prev"></div>
								<div class="swiper-button-next my-next" slot="button-next"></div>
								<!-- 如果需要分页器 -->
								<!-- <div class="swiper-pagination my-pagination" slot="pagination"></div> -->
							</swiper>
						</div>
					</div>
				</div>

				<div class="pay-session4">
					<div class="pay-session4-title " :class="$t('common.fontFamilyTitle')">
						{{ $t('pay.五维指标评价系统') }}
					</div>
					<div class="pay-session4-subtitle">
						{{ $t('pay.为交易员信号提供公开透明的评估') }}

					</div>
					<div class="pc">
						<div class="pay-session4-content">
							<div class="pay-session4-content-row">
								<div class="pay-session4-content-item">
									<div class="pay-session4-content-item-label">{{ $t('pay.收益率') }} 900.99%</div>
									<div class="pay-session4-content-item-info">
										{{ $t('pay.历史收益率') }}
									</div>
								</div>
							</div>
							<div class="pay-session4-content-row">
								<div class="pay-session4-content-item mt-165">
									<div class="pay-session4-content-item-label">{{ $t('pay.最大回撤') }} 34%</div>
									<div class="pay-session4-content-item-info">
										{{ $t('pay.衡量风控能力') }}
									</div>
								</div>
								<img class="session4_bg" src="../assets/image/pay/session4_bg.jpg" alt="" />
								<div class="pay-session4-content-item mt-165">
									<div class="pay-session4-content-item-label">{{ $t('pay.获利系数') }} 0.66</div>
									<div class="pay-session4-content-item-info">
										{{ $t('pay.利润因子') }}
									</div>
								</div>
							</div>
							<div class="pay-session4-content-row gap-180">
								<div class="pay-session4-content-item">
									<div class="pay-session4-content-item-label">{{ $t('pay.夏普比率') }} 87%</div>
									<div class="pay-session4-content-item-info">
										{{ $t('pay.每份风险的收益') }}
									</div>
								</div>
								<div class="pay-session4-content-item">
									<div class="pay-session4-content-item-label">{{ $t('pay.标准差') }} 0.66</div>
									<div class="pay-session4-content-item-info">
										{{ $t('pay.收益波动率') }}
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="mobile">
						<div class="pay-session4-content">
							<div class="pay-session4-content-row">
								<div class="pay-session4-content-item-m">
									<div class="pay-session4-content-item-m-label">{{ $t('pay.收益率') }}</div>
									<div class="pay-session4-content-item-m-info">406.06%</div>
								</div>
							</div>
							<div class="pay-session4-content-row">
								<div class="pay-session4-content-item-m mt-45">
									<div class="pay-session4-content-item-m-label">{{ $t('pay.最大回撤') }}</div>
									<div class="pay-session4-content-item-m-info">34%</div>
								</div>
								<img class="session4_bg_m" src="../assets/image/pay/session4_bg_m.jpg" alt="" />
								<div class="pay-session4-content-item-m mt-45">
									<div class="pay-session4-content-item-m-label">{{ $t('pay.获利系数') }}</div>
									<div class="pay-session4-content-item-m-info">6.19</div>
								</div>
							</div>
							<div class="pay-session4-content-row gap-90">
								<div class="pay-session4-content-item-m">
									<div class="pay-session4-content-item-m-label">{{ $t('pay.夏普比率') }}</div>
									<div class="pay-session4-content-item-m-info">44.46</div>
								</div>
								<div class="pay-session4-content-item-m">
									<div class="pay-session4-content-item-m-label">{{ $t('pay.标准差') }}</div>
									<div class="pay-session4-content-item-m-info">1.59</div>
								</div>
							</div>
							<div class="pay-session4-content-card">
								<div class="pay-session4-content-card-item">
									<div class="pay-session4-content-card-item-label">
										{{ $t('pay.收益率') }}900.99%
									</div>
									<div class="pay-session4-content-card-item-info">
										{{ $t('pay.历史收益率') }}
									</div>
								</div>
								<div class="pay-session4-content-card-item">
									<div class="pay-session4-content-card-item-label">
										{{ $t('pay.最大回撤') }}34%
									</div>
									<div class="pay-session4-content-card-item-info">
										{{ $t('pay.衡量风控能力') }}
									</div>
								</div>
								<div class="pay-session4-content-card-item">
									<div class="pay-session4-content-card-item-label">
										{{ $t('pay.获利系数') }}0.66
									</div>
									<div class="pay-session4-content-card-item-info">
										{{ $t('pay.利润因子') }}
									</div>
								</div>
								<div class="pay-session4-content-card-item">
									<div class="pay-session4-content-card-item-label">
										{{ $t('pay.夏普比率') }}87%
									</div>
									<div class="pay-session4-content-card-item-info">
										{{ $t('pay.每份风险的收益') }}
									</div>
								</div>
								<div class="pay-session4-content-card-item">
									<div class="pay-session4-content-card-item-label">
										{{ $t('pay.标准差') }}0.66
									</div>
									<div class="pay-session4-content-card-item-info">
										{{ $t('pay.收益波动率') }}
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="pay-session4-tips">{{ $t('pay.以上数据仅供参考') }}</div>
				</div>

				<div class="pay-session5">
					<div class="pay-session5-title " :class="$t('common.fontFamilyTitle')">
						<img height="44" src="../assets/image/No1/img/Icon-star-left.svg" alt="" class="pc" /><img
							height="30" src="../assets/image/No1/img/Icon-star-left.svg" alt="" class="mobile" />{{
								$t('pay.活动须知') }}<img height="44" src="../assets/image/No1/img/Icon-star-right.svg" alt=""
							class="pc" />
						<img height="30" src="../assets/image/No1/img/Icon-star-right.svg" alt="" class="mobile" />
					</div>

					<div class="pay-session5-content">
						<div class="tip-list">
							<div class="tip-item" v-for="(item, index) in $t('pay.list')" :key="index">
								<div class="tip-title">{{ item.label }}</div>
								<div class="tip-content">
									<div class="tip-content-li" v-for="(el, i) in item.value" :key="i">
										{{ el }}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<ss-contact-us-new mode="all"></ss-contact-us-new>
		<page-footer></page-footer>
	</div>

</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import { mapGetters } from 'vuex';
export default {
	components: {
		Swiper,
		SwiperSlide
	},
	data() {
		return {
			options: {
				slidesPerView: 1.8,
				spaceBetween: 15,
				freeMode: true,
			},
			options1: {
				// direction: "vertical", // 垂直切换选项
				loop: true, // 循环模式选项

				// 如果需要分页器
				pagination: {
					el: ".swiper-pagination",
					bulletActiveClass: "my-bullet-active",
					clickable: true,
				},

				// 如果需要前进后退按钮
				navigation: {
					nextEl: ".swiper-button-next",
					prevEl: ".swiper-button-prev",
				},
			}
		}
	},
	mounted() {
		// window.dataLayer = window.dataLayer || [];
		// window.__lc = window.__lc || {};
		// window.__lc.license = 12340599;
		// (function (n, t, c) {
		// 	function i(n) {
		// 		return e._h ? e._h.apply(null, n) : e._q.push(n)
		// 	}
		// 	var e = {
		// 		_q: [],
		// 		_h: null,
		// 		_v: "2.0",
		// 		on: function () {
		// 			i(["on", c.call(arguments)])
		// 		},
		// 		once: function () {
		// 			i(["once", c.call(arguments)])
		// 		},
		// 		off: function () {
		// 			i(["off", c.call(arguments)])
		// 		},
		// 		get: function () {
		// 			if (!e._h) throw new Error("[LiveChatWidget] You can't use getters before load.");
		// 			return i(["get", c.call(arguments)])
		// 		},
		// 		call: function () {
		// 			i(["call", c.call(arguments)])
		// 		},
		// 		init: function () {
		// 			var n = t.createElement("script");
		// 			n.async = !0, n.type = "text/javascript", n.src =
		// 				"https://cdn.livechatinc.com/tracking.js", t.head.appendChild(n)
		// 		}
		// 	};
		// 	!n.__lc.asyncInit && e.init(), n.LiveChatWidget = n.LiveChatWidget || e
		// }(window, document, [].slice))
		// let nowDate = new Date();


	},
	created() { },
	methods: {

	},
	computed: {

		...mapGetters([
			'language',
			'FCAUrl',
			'signupUrl',
			'loginUrl',
		])
	},
}
</script>
<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

::v-deep .my-bullet-active {
	opacity: 0.6;
}

.flex-bewteen {
  display: flex;
  justify-content: space-between;
}
.pc {
  display: block;
}
.mobile {
  display: none;
}
.pay-session1-box {
  /* margin-top: 30rem; */
  background: #0a0202;
  background-image: url(../assets/image/pay/session1_bg.png);
  background-size: auto 100%;
  background-position: right;
  background-repeat: no-repeat;
}
.pay-session1 {
  margin: 0 auto;
  min-height: 500rem;
  max-width: 1440rem;
 
  padding: 100rem 600rem 100rem 64rem;
}
.pay-session1-title {
  font-size: 40rem;
  font-style: normal;
  font-weight: 900;
  line-height: 150%;
  background: linear-gradient(90deg, #e8d6b0 0%, #d1ad77 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.pay-session1-detail {
  margin-top: 30rem;
  color: #fff;
  font-size: 20rem;
  font-style: normal;
  font-weight: 350;
  line-height: 150%;
  max-width: 80%;
}
.pay-session1-list {
  margin-top: 40rem;
  color: #fff;
  font-size: 22rem;
  font-style: normal;
  font-weight: 400;
  line-height: 235%;
}
.pay-session1-li {
  display: flex;
  align-items: center;
}
.pay-session1-li > span + span {
  margin-left: 16rem;
}
.pay-session1-li :first-child {
  font-size: 18rem;
  color: #af8147;
  flex-shrink: 0;
}

.btn-go {
  margin-top: 35rem;
  display: inline-flex;
  padding: 10rem 24rem;
  justify-content: center;
  align-items: center;
  gap: 8rem;
  flex-shrink: 0;
  border-radius: 4rem;
  background: linear-gradient(90deg, #e8d7b1 0%, #d0ab75 100%);
  min-width: 230rem;
  color: #010101;
  /* font-family: Roboto; */
  font-size: 18rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal; /* 30rem */
  transition: all 0.3s;
  min-width: 280rem;
}
.btn-go .icon-right{
  font-size: 16rem;
  transform: translateY(1rem);
}
.cn .btn-go .icon-right{
  transform: translateY(2rem);
}
.ar .btn-go {
  transform: rotate(180deg);
}
.btn-go:hover {
  color: #010101;
  opacity: 0.9;
}

.pay-session2,
.pay-session3,
.pay-session4,
.pay-session5 {
  margin: 0 auto;
  padding: 80rem 64rem;
  max-width: 1440rem;
}
.pay-session2,
.pay-session3 {
  max-width: 1680rem;
}
.pay-session2-title,
.pay-session3-title,
.pay-session4-title,
.pay-session5-title {
  margin: 0 auto;
  color: #333;
  text-align: center;
  font-size: 36rem;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
}
.en-font-family .pay-session2-title,
.en-font-family .pay-session3-title,
.en-font-family .pay-session4-title,
.en-font-family .pay-session5-title {
  font-weight: 400;
}

.pay-session2-subtitle,
.pay-session3-subtitle,
.pay-session4-subtitle {
  margin-top: 20rem;
  color: #666;
  text-align: center;
  font-size: 18rem;
  font-style: normal;
  font-weight: 350;
  line-height: 150%;
}

.pay-session2-list {
  display: flex;
  gap: 20rem;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 120rem;
}
/* 修改开始 */
@font-face {
  font-family: "DIN Alternate";
  src: url("/ebc-static/fonts/din-alternate-bold.ttf");
  font-style: normal;
  font-display: swap;
}
.pay-session2-card {
  position: relative;
  width: calc(20% - 16rem);
  height: 290rem;
  border-radius: 8rem;
  /* border: 1px solid #e7dcd3; */
  background: url(../assets/image/pay/session2_card2.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 28rem;
}
.es .pay-session2-card ,
.jp .pay-session2-card {
	height: 320rem;
}

.pay-session2-card-content h2{
  padding-right: 70rem;
}
.ar .pay-session2-card-content h2{
  padding-right: 0;
  padding-left: 70rem;
}
.pay-session2-card-content-icon {
  position: absolute;
  right: 28rem;
  top: 20rem;
}
.ar .pay-session2-card-content-icon {
  right: unset;
  left: 28rem;
}
.pay-session2-card-content-icon img {
  width: 64rem;
  height: 64rem;
}
.pay-session2-card-light {
  background: url(../assets/image/pay/session2_card1.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.pay-session2-card h2 {
  color: #333;
  font-size: 20rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.pay-session2-card-light h2 span {
  background: linear-gradient(270deg, #ebcaa7 0%, #fdfef9 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.pay-session2-card .p1 {
  display: flex;
  align-items: baseline;
  /* font-family: DIN; */
  font-family: Roboto;
  margin-top: 16rem;
}

.pay-session2-card-content .number {
  font-size: 74rem;
  font-weight: 700;
  line-height: normal;
  color: #ff4b4e;
}
.pay-session2-card-light .pay-session2-card-content .number {
  background: linear-gradient(270deg, #ebcaa7 36.47%, #fdfef9 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.pay-session2-card .unit {
  font-size: 30rem;
  font-weight: 700;
  line-height: normal;
  color: #ff4b4e;
}

.pay-session2-card-light .pay-session2-card-content .unit {
  color: #ebcaa7;
}


.pay-session2-card-content .p2 {
  margin-top: -8rem;
  color: #b2afad;
  font-size: 14rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.pay-session2-card-bottom {
  margin-top: 36rem;
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
}
.pay-session2-card-bottom-item {
  display: flex;
  flex-direction: column;
  min-width: 35%;
}
.pay-session2-card-bottom-item > span:first-child {
  color: #333;
  /* font-family: "DIN"; */
  font-family: Roboto;
  font-size: 20rem;
  font-weight: bold;
  line-height: normal;
}
.pay-session2-card-light .pay-session2-card-bottom-item > span:first-child {
  background: linear-gradient(270deg, #ebcaa7 36.47%, #fdfef9 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.pay-session2-card-bottom-item > span:last-child {
  color: #6a6967;
  font-size: 14rem;
  font-style: normal;
  font-weight: 400;
  line-height: 26rem;
}
.pay-session2-card-light .pay-session2-card-bottom-item > span:last-child {
  color: #b2afad;
}

.pay-session2-card-light .pay-session2-card-bottom-line {
  background: #653936;
}
.pay-session2-card-bottom-line {
  width: 1px;
  height: 36rem;
  background: #e7dcd3;
  margin: 0 10rem;
  flex-shrink: 0;
  margin-top: 10rem;
}

/* 结束 */

.pay-session3-list {
  position: relative;
  margin-top: 80rem;
  display: flex;
  flex-direction: row-reverse;
  gap: 20rem;
}
.pay-session3-li {
  width: calc(25% - 15rem);
  display: flex;
  flex-direction: column;
  border-radius: 8rem;
  border: 1px solid #e1e6ed;
  background: #fff;
  overflow: hidden;
}

.pay-session3-li-top {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 71rem;
  background: linear-gradient(141deg, #fefaf6 0%, #f9f2ec 100%);
  color: #333;
  font-size: 20rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-bottom: 1px solid #e7dcd3;
}

.pay-session3-li-top4 {
  height: calc(71rem + 1px);
  background: linear-gradient(141deg, #ad0a3a 0%, #360d1b 100%),
    linear-gradient(141deg, #673a37 0%, #180b0b 100%);
  color: #fff;
  border-bottom: none;
}
.pay-session3-li-top4 > span {
  background: linear-gradient(270deg, #ebcaa7 0%, #fdfef9 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.pay-session3-li-content {
  position: relative;
  flex: 1;
  padding: 28rem;
}

.pay-session3-li-content-text {
	position: relative;
	color: #000;
	font-size: 14rem;
	font-style: normal;
	font-weight: 400;
	line-height: 20rem;
	padding-right: 28rem;
	margin-bottom: 16rem;
}
.ar .pay-session3-li-content-text {
  padding-left: 28rem;
  padding-right: 0;
}
.light .pay-session3-li-content-text,
.pay-li-content-text-light .pay-session3-li-content-text,
.pay-session3-list .pay-session3-li:nth-child(4) .pay-session3-li-content-text {
  color: #9f734c;
}


.session4_bg {
  width: 536rem;
  height: 512rem;
  z-index: -1;
  flex-shrink: 0;
}
.pay-session3-li-content-text::before {
  font-family: "iconfont" !important;
  content: "\e881";
  font-size: 16rem;
  color: #9f734c;
  position: absolute;
  right: 0;
}
.ar .pay-session3-li-content-text::before {
  right: unset;
  left: 0;
}

.pay-session3-li-content-bg1 {
  height: 100%;
}

.pay-session3-li-content-bg2 {
  height: 100%;
}

.pay-session3-li-content-bg3 {
  height: 100%;
}
.pay-session3-li-content-bg4 {
  height: 100%;
}

.pay-session4-content {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* height: 526rem; */
  margin-top: 106rem;
}
.pay-session4-content-row {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.gap-180 {
  gap: 180rem;
}
.mt-165 {
  margin-top: 165rem;
}

.pay-session4-content-item {
  border-radius: 8rem;
  border: 1rem solid #e3e3e3;
  background: #fff;
  box-shadow: 0rem 9rem 15rem 0rem rgba(0, 0, 0, 0.14);
  padding: 20rem 30rem;
}
.pay-session4-content-item-label {
  color: #a52933;
  font-size: 22rem;
  font-style: normal;
  font-weight: 700;
  line-height: 138%;
  text-align: center;
}
.pay-session4-content-item-info {
  margin-top: 7rem;
  color: #666;
  font-size: 14rem;
  font-style: normal;
  font-weight: 400;
  line-height: 138%;
  text-align: center;
}
.pay-session4-content-left,
.pay-session4-content-right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.pay-session4-tips {
  margin-top: 106rem;
  color: #999;
  text-align: center;
  font-size: 20rem;
  font-style: normal;
  font-weight: 350;
  line-height: 150%; /* 27rem */
}
.pay-session5-title {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24rem;
}
.pay-session5-content {
  margin: 80rem auto 0;
  max-width: 768rem;
}

.tip-title {
  padding: 8rem;
  background: #f8f8f8;
  color: #333;
  font-size: 20rem;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
}

.tip-content {
  margin: 16rem 0 48rem 0;
  color: #666;
  font-size: 18rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  padding: 0 8rem;
}

.tip-content:last-child {
  margin-bottom: 60rem;
}

.tip-content-li {
  position: relative;
  padding-left: 15rem;
  margin-bottom: 6rem;
}

.tip-content-li::before {
  content: "";
  display: block;
  width: 4rem;
  height: 4rem;
  background-color: #666;
  position: absolute;
  left: 3rem;
  top: 12rem;
  border-radius: 50%;
}
.ar .tip-content-li {
  padding-left: 0;
  padding-right: 15rem;
}
.ar .tip-content-li::before {
  left: unset;
  right: 3rem;
}

.no-dot::before {
  width: 0;
  height: 0;
}

@media (max-width: 996px) {
  .mobile {
    display: block;
  }
  .pc {
    display: none;
  }
  .pay-session2-title,
  .pay-session3-title,
  .pay-session4-title,
  .pay-session5-title {
    font-size: 44rem;
    max-width: 700rem;
  }
 
  .pay-session2-subtitle,
  .pay-session3-subtitle,
  .pay-session4-subtitle {
    margin-top: 40rem;
    font-size: 24rem;
  }

  .pay-session1 {
    padding: 127rem 80rem 370rem;
    background-image: url(../assets/image/pay/session1_bg_m.png?v=1.0.0);
    background-size: contain;
    /* background-position: bottom; */
    background-repeat: no-repeat;
    min-height: 1270rem;
  }
  .pay-session1-title {
    /* text-align: center; */
    font-size: 46rem;
    line-height: normal;
  }

  .pay-session1-detail {
    font-size: 24rem;
    max-width: none;
    margin-top: 50rem;
  }
  .pay-session1-list {
    font-size: 26rem;
  }
  
  .pay-session1-li :first-child {
    font-size: 26rem;
  }
  .btn-go {
    display: inline-flex;
    align-items: center;
    height: 68rem;
    /* padding: 0 48rem; */
    font-size: 24rem;
    line-height: 1em;
  }
  .btn-go .icon-right{
    font-size: 24rem;
    transform: translateY(2rem);
  }
  .cn .btn-go .icon-right{
    transform: translateY(3rem);
  }
  .pay-session2,
  .pay-session3,
  .pay-session4,
  .pay-session5 {
    padding: 100rem 30rem 0;
  }
  .pay-session2-list,
  .pay-session3-list {
    margin: 70rem auto 0;
    max-width: 700rem;
  }
  .pay-session2-li-top {
    height: 136rem;
    font-size: 80rem;
  }
  .pay-session2-li-top .dollar {
    font-size: 40rem;
  }
  .pay-session2-li-top-small {
    font-size: 28rem;
  }
  .pay-session2-li {
    width: 100%;
    padding-bottom: 80rem;
  }
  .pay-session2-li-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 540rem;
  }
  .pay-session2-li-content-name {
    font-size: 32rem;
  }
  .pay-session2-li-bottom {
    font-size: 28rem;
    padding: 20rem 30rem;
  }
  .es .pay-session2-li-bottom {
    font-size: 24rem;
  }
  .pay-session2-li-top-mini {
    font-size: 28rem;
  }
  .pay-session2-li-content-name .icon-Vector {
    font-size: 18rem;
  }

  .swiper-slide .pay-session3-li {
    width: auto;
    margin: 0 100rem;
    height: 100%;
    border-radius: 14rem;
  }
  .pay-session3-li-content-text {
    font-size: 26rem;
    line-height: normal;
  }

  .pay-session3-li-top {
    height: 108rem;
    font-size: 28rem;
  }
  .pay-session3-li-content {
    display: flex;
    flex-direction: column;
  }
  .pay-session3-li-content > div {
    flex: 1;
  }
  .session4_bg_m {
    width: 364rem;
    height: 347rem;
    flex-shrink: 0;
  }
  .pay-session4-content {
    margin: 70rem auto 0;
    height: auto;
    flex-direction: column;
    max-width: 700rem;
  }
  .pay-session4-content-item-m {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .pay-session4-content-item-m-label {
    color: #a52933;
    text-align: center;
    font-size: 26rem;
    font-style: normal;
    font-weight: 500;
    line-height: 138%;
  }
  .pay-session4-content-item-m-info {
    color: #000;
    font-size: 26rem;
    font-style: normal;
    font-weight: 350;
    line-height: 138%;
  }
  .pay-session4-content-center {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
  }
  .pay-session4-content-center img {
    width: 40%;
    flex: 1;
  }

  .pay-session4-content-item-m-left,
  .pay-session4-content-item-m-right {
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    min-width: 74rem;
  }
  .pay-session4-content-card {
    margin: 64rem auto;
    width: 85%;
    padding: 28rem 48rem;
    max-width: 700rem;
    border-radius: 8rem;
    border: 1rem solid #e3e3e3;
    /* opacity: 0.65; */
    background: #fff;
    box-shadow: 0rem 9rem 15rem 0rem rgba(0, 0, 0, 0.07);
  }
  .pay-session4-content-card-item {
    padding: 38rem 0;
  }
  .pay-session4-content-card-item + .pay-session4-content-card-item {
    border-top: 1rem solid #e3e3e3;
  }
  .pay-session4-content-card-item-label {
    color: #a52933;
    text-align: center;
    font-size: 28rem;
    font-style: normal;
    font-weight: 500;
    line-height: 138%;
  }
  .pay-session4-content-card-item-info {
    margin-top: 6rem;
    color: #666;
    text-align: center;
    font-size: 22rem;
    font-style: normal;
    font-weight: 350;
    line-height: 138%;
  }
  .pay-session4-tips {
    margin-top: 32rem;
    font-size: 28rem;
  }
  .pay-session5-content {
    max-width: 95%;
  }
  .pay-session4-content {
    gap: 10rem;
  }
  .pay-session4-content-row {
    gap: 10rem;
  }
  .gap-90 {
    gap: 180rem;
  }
  .mt-45 {
    margin-top: 94rem;
  }
  .tip-title {
    padding: 16rem;
    font-size: 26rem;
  }
  .tip-content {
    font-size: 24rem;
  }
  .pay-session3-li-content-text::before {
    font-size: 24rem;
  }
  .pay-session3-list .my-prev::after,
  .pay-session3-list .my-next::after {
    font-size: 48rem;
    color: #9f734c;
  }
  .jp .pay-session2-card,
  .es .pay-session2-card,
  .pay-session2-card {
    margin-top: 80rem;
    width: 100%;
    height: auto;
    padding: 126rem 28rem 60rem;
    min-height: 630rem;
    background: url(../assets/image/pay/session2_card2_m.jpg);
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  .ar .pay-session2-card-content,
  .pay-session2-card-content {
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .ar .pay-session2-card-content h2,
  .pay-session2-card-content h2{
    padding: 0;
  }
  .pay-session2-card h2 {
    font-size: 28rem;
    text-align: center;
  }
  .pay-session2-card-content .number {
    font-size: 112rem;
  }
  .pay-session2-card-content .unit {
    font-size: 44rem;
  }
  .pay-session2-card-content .p2 {
    font-size: 24rem;
    text-align: center;
  }
  .pay-session2-card-bottom {
    flex-direction: column;
    gap: 30rem;
    margin-top: 46rem;
  }
  .pay-session2-card-bottom-line {
    display: none;
  }
  .pay-session2-card-bottom-item {
    min-width: auto;
  }
  .pay-session2-card-bottom-item span {
    text-align: center;
  }
  .pay-session2-card-bottom-item span + span {
    margin-top: 10rem;
  }
  .pay-session2-card-bottom-item > span:first-child {
    font-size: 28rem;
  }
  .pay-session2-card-bottom-item > span:last-child {
    font-size: 24rem;
  }
  .ar .pay-session2-card-content-icon,
  .pay-session2-card-content-icon {
    top: -69rem;
    right: calc(50% - 69rem);
  }
  .pay-session2-card-content-icon img {
    width: 138rem;
    height: 138rem;
  }
  .pay-session2-card-light {
    background: url(../assets/image/pay/session2_card1_m.jpg);
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  
}
.my-bullet-active {
  background: #666 !important;
  opacity: 1 !important;
}
</style>
