<template>
	<div class="learning-center-info" :class="$t('Home.pageClass')">
		<page-navigate></page-navigate>
		<div class="learning-center-info-new">
    <div class="container">
        <ul class="breadcrumb-info">
            <li><router-link to="/">{{$t('CFooters.className') == 'zh-box' ? '首页' : 'Home'}}</router-link></li>
            <li><router-link to="/LearningCenter">{{$t('CFooters.className') == 'zh-box' ? 'EBC研究院' : 'EBC Institute'}}</router-link></li>
            <li class="active">{{newsInfo.tit}}</li>
        </ul>
    </div>
    <div class="news-content container">
        <h1>{{newsInfo.tit}}</h1>
        <span class="time">{{newsInfo.time}}</span>
        <div class="abstract">
            <img class="bag_ar" src="../assets/image/LearningCenter/summary.png" alt="">
            <span class="abstract_name">{{$t('CFooters.className') == 'zh-box' ? '摘要' : 'Summary'}}:</span>
            <p class="abstract_text">{{newsInfo.tips}}</p>
        </div>
        <div class="info" v-html="newsInfo.desc">
        </div>
    </div>
	</div>
		<page-footer></page-footer>
	</div>
</template>

<script>
	export default {
		name: '',
		data() {
			return {
				newsInfo: '',
				lang:'',
				seoInfo: ""
			}
		},
		metaInfo() {
			return {
				title: this.seoInfo.title || '',
				meta: [ // set meta
					{
						name: 'keyWords',
						content: this.seoInfo.keyWords || ''
					},
					{
						name: 'description',
						content: this.seoInfo.description || ''
					}
				]
			}
		},
		created() {
            // if(this.$t("common.requestLang")//this.$store.state.Lan == "vn"){
            //     this.$t("common.requestLang")//this.$store.state.Lan = "en_US"
            // }
			this.$getSeoInfo({
				lang: this.$t("common.requestLang"),//this.$store.state.Lan,
				path: "/LearningCenter"
			}).then(res=>{
				this.seoInfo = res
			})
			this.lang = this.$route.query.lang
			this.getNewInfo(this.$route.query.id)
            $('html,body').animate({
				scrollTop: 0
			}, 0);
		},
		methods: {
			getNewInfo(id){
				let langs = ''
				let parms;
				// if(!this.lang){
				// 	langs = localStorage.getItem('language')
				// 	if (langs !== 'zh') {
				// 		parms = 'en_US'
				// 	} else {
				// 		parms = 'zh_CN'
				// 	}
				// }else{
				// 	parms = this.lang
				// }
				parms = this.$t("common.requestLang")//this.$store.state.Lan
				this.$http('/ebc/api/getLearnArticle/run',{
					lang:parms,
					id: id
				}).then(res=>{
                    console.log("res",res)
					if(res.data.data.tit == '' && parms != "en_US"){
						this.$http('/ebc/api/getLearnArticle/run',{
							lang:'en_US',
							id: id
						}).then(ress=>{
							this.newsInfo = ress.data.data
						})
					}else{
						this.newsInfo = res.data.data
					}
				})
			}
		}
	}
</script>

<style>
	@charset "UTF-8";
	.c-footer .fp-overflow{
		display: unset !important;
	}
    .learning-center-info-new{
        padding: 24px 0 48px 0;
    }
.learning-center-info-new .advantage-box {
    width: 1500px;
    margin: 0 auto
}

.learning-center-info-new .advantage-box .advantage-title {
    display: flex;
    align-items: center;
    padding: 100px 0
}

.learning-center-info-new .advantage-box .advantage-title__line {
    flex: 1;
    border-top: 2px solid #d1d1d1
}

.learning-center-info-new .advantage-box .advantage-title .title {
    padding: 0 24px;
    font-size: 36px;
    font-weight: 700;
    line-height: 1.44;
    color: #333
}

.learning-center-info-new .advantage-box .advantage-box {
    justify-content: space-between;
    align-items: center
}

.learning-center-info-new .nav-tabs a{
    padding: 26px 40px;
    float: left;
    font-size: 18px;
    color: #000;
    font-family: auto;
}

.learning-center-info-new .nav-tabs .active a{
    border-bottom: 2px #9f565c solid;
}

.learning-center-info-new .nav-tabs{
    margin: 20px auto;
}

.learning-center-info-new .tab-content .news-li{
    padding: 0;
    padding-bottom: 20px;
    border-radius: 5px;
    box-shadow: 6px 6px 18px 0 rgb(0 0 0 / 30%);
    margin-bottom: 30px;
    width: 30%;
    margin-right: 5%;
}
.learning-center-info-new .tab-content .news-li:nth-child(3){
    margin-right: 0%;
}
.learning-center-info-new .tab-content .news-li:nth-child(6){
    margin-right: 0%;
}
.learning-center-info-new .tab-content .news-li img{
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    transform: none;
    object-position: left;
    width: 100%;
    height: 226px;
}
.learning-center-info-new .tab-content .news-li h3 a{
    margin: 14px 16px 10px;
    font-size: 20px;
    font-weight: 600;
    line-height: 1.5;
    display: block;
    color: #333;
    overflow:hidden; 
    text-overflow:ellipsis;
    display:-webkit-box; 
    -webkit-box-orient:vertical;
    -webkit-line-clamp:2;
    font-family: 'Noto Serif SC';
}
.learning-center-info-new .tab-content .news-li p{
    margin: 0 16px 10px;
    color: #666;
    font-size: 16px;
    line-height: 1.5;
    font-family: auto;
    overflow:hidden; 
    text-overflow:ellipsis;
    display:-webkit-box; 
    -webkit-box-orient:vertical;
    -webkit-line-clamp:2; 
}
.learning-center-info-new .tab-content .news-li span{
    padding: 0 16px 10px;
    color: #666;
    font-size: 14px;
}
.learning-center-info-new .c-btn{
    margin: 30px auto 60px;
    font-size: 20px;
    max-width: 10px;
}



.learning-center-info-new table {
      border-collapse: collapse;
      width: 100%;
      caption-side: top;
      margin: 10px auto;
    }
    
    .learning-center-info-new th, .learning-center-info-new td {
      border: 1px solid #ddd;
      padding: 8px;
      text-align: left;
      font-size: 16px;
    }
    
    .learning-center-info-new th {
      background-color: #f2f2f2;
    }
    
    .learning-center-info-new caption {
      font-weight: bold;
      font-size: 20px;
      margin-bottom: 10px;
      text-align: center;
    }
    
    /* 响应式样式 */
    /*@media only screen and (max-width: 600px) {
      table {
        display: block;
        overflow-x: auto;
        white-space: nowrap;
      }
      
      th, td {
        display: inline-block;
        font-size: 16px;
      }
      
      th {
        position: sticky;
        top: 0;
        background-color: #f2f2f2;
      }
      caption {
      text-align: left;
    }
    }*/


.info li{
    list-style: auto !important;
}


@media(max-width:767px) {
    
}


.learning-center-info-new .tab-content .news-li:nth-child(9) {
    margin-right: 0%;
}
.learning-center-info-new .tab-content .news-li:nth-child(12) {
    margin-right: 0%;
}

.pagination {
    display: inline-block;
    padding-left: 0;
    margin: 20px 0;
    border-radius: 4px;
    float: right;
    margin-bottom: 60px;
  }
  .pagination > li {
    display: inline;
  }
  .pagination > li > a,
  .pagination > li > span {
    position: relative;
    float: left;
    padding: 12px 18px;
    line-height: 1.42857143;
    text-decoration: none;
    color: #404040;
    background-color: #fff;
    border: 1px solid #ddd;
    margin-left: -1px;
    font-size: 16px;
  }
  .pagination > li:first-child > a,
  .pagination > li:first-child > span {
    margin-left: 0;
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
  }
  .pagination > li:last-child > a,
  .pagination > li:last-child > span {
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
  }
  .pagination > li > a:hover,
  .pagination > li > span:hover,
  .pagination > li > a:focus,
  .pagination > li > span:focus {
    z-index: 2;
    color: #23527c;
    background-color: #eeeeee;
    border-color: #ddd;
  }
  .pagination > .active > a,
  .pagination > .active > span,
  .pagination > .active > a:hover,
  .pagination > .active > span:hover,
  .pagination > .active > a:focus,
  .pagination > .active > span:focus {
    z-index: 3;
    color: #fff;
    background-color: #9A2122;
    border-color: #9A2122;
    cursor: default;
  }
  .pagination > .disabled > span,
  .pagination > .disabled > span:hover,
  .pagination > .disabled > span:focus,
  .pagination > .disabled > a,
  .pagination > .disabled > a:hover,
  .pagination > .disabled > a:focus {
    color: #777777;
    background-color: #fff;
    border-color: #ddd;
    cursor: not-allowed;
  }
  .pagination-lg > li > a,
  .pagination-lg > li > span {
    padding: 10px 16px;
    font-size: 18px;
    line-height: 1.3333333;
  }
  .pagination-lg > li:first-child > a,
  .pagination-lg > li:first-child > span {
    border-bottom-left-radius: 6px;
    border-top-left-radius: 6px;
  }
  .pagination-lg > li:last-child > a,
  .pagination-lg > li:last-child > span {
    border-bottom-right-radius: 6px;
    border-top-right-radius: 6px;
  }
  .pagination-sm > li > a,
  .pagination-sm > li > span {
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
  }
  .pagination-sm > li:first-child > a,
  .pagination-sm > li:first-child > span {
    border-bottom-left-radius: 3px;
    border-top-left-radius: 3px;
  }
  .pagination-sm > li:last-child > a,
  .pagination-sm > li:last-child > span {
    border-bottom-right-radius: 3px;
    border-top-right-radius: 3px;
  }

  .c-footer .c-footer-hd[data-v-73eb7d57]{
    clear: both;
  }



@media(max-height:375px) {
    .justify-content-around {
        height: 200px;
        overflow-y: auto
    }
}

@media screen and (max-width:1024px)and (min-width:769px) {
    .c-header-bd .nav-item .nav-link {
        padding: 6px 12px !important;
        white-space: nowrap
    }

    .c-header-bd .nav-item .nav-link.en-box,
    .c-header-bd .nav-item .nav-link.th-box,
    .c-header-bd .nav-item .nav-link.vi-box {
        padding: 6px 0 !important
    }

    .c-header-bd .nav-item .nav-link.ja-box {
        padding: 6px 5px !important
    }

    .c-header-bd .navbar-brand {
        padding-left: 15px !important;
        margin-right: 10px
    }

    .c-header-bd .navbar-brand.en-box,
    .c-header-bd .navbar-brand.th-box,
    .c-header-bd .navbar-brand.vi-box {
        padding-left: 10px !important
    }
}

@media(width:768px) {
    .dropdown-menu .dropdown-menu {
        position: static !important
    }
}





.learning-center-info-new .nav-tabs li{
    position: static;
}
.learn-son{
    position: absolute;
    display: none;
    top: 153px;
    width: auto;
    border-radius: 5px;
    background: #fafafa;
    height: 53px;
}
.learn-son a{
    font-size: 16px !important;
    padding: 16px 40px !important;
}
.learn-son a:hover{
    color: rgba(117, 13, 13, 1);
}
.learning-center-info-new .nav-tabs a:hover{
    color: rgba(117, 13, 13, 1);
}
.learning-center-info-new .nav-tabs li.active a{
    color: rgba(117, 13, 13, 1);
}
.breadcrumb-info {
    padding: 18px 15px;
    margin-bottom: 24px;
    list-style: none;
    /* background-color: #f5f5f5; */
    /* border-radius: 4px; */
    font-size: 16px;
    border-bottom: 1px #d6d6d6 solid;
    /* margin-top: 92px; */
    font-family: auto;
}
.breadcrumb-info>li {
    display: inline-block;
    line-height: 28px;
}
.breadcrumb-info>li+li:before {
    padding: 0 5px;
    color: #ccc;
    content: "/\00a0";
}
.breadcrumb-info>.active {
    color: #777;
}
.breadcrumb-info a{
    color: #777;
}
.breadcrumb-info>.active{
    /* color: rgb(109 48 48); */
}

.news-content h1{
    font-size: 34px;
    line-height: 48px;
    margin-bottom: 14px;
    font-family: 'Noto Serif SC';
    font-weight: 700;
}
.news-content .time{
    font-size: 16px;
    color: #847d7d;
    display: inline-block;
}
.news-content .info{
    font-size: 20px;
    padding-top: 36px;
}
.news-content .info p{
    font-size: 18px;
    font-family: "Microsoft Yahei";
    line-height: 34px;
    color: #000;
}
.news-content .info span{
    font-size: 18px !important;
    font-family: auto !important;
    color: #000;
    line-height: 22px;
}
.news-content .info img{
    width: 100% !important;
    height: auto !important;
    margin: 20px 0 12px;
}
.learning-center-info-new .container{
    max-width: 1100px;
}

.learning-center-info-new .nav-tabs{
    margin-top: 34px;
}
.tab-pane{
    margin-bottom: 30px;
}
.learning-center-info-new .nav-tabs span {
    padding: 26px 40px;
    float: left;
    font-size: 18px;
    color: #000;
    font-family: auto;
}
.learning-center-info-new .nav-tabs .active span {
    border-bottom: 2px #9f565c solid;
}
.learning-center-info-new .nav-tabs li.active span {
    color: rgba(117, 13, 13, 1);
}

.abstract {
  padding: 24px 32px;
  border: 1px dashed #979797;
  margin: 45px 0 0px;
  border-radius: 4px;
  position: relative;
  font-size: 16px;
  line-height: 24px;
}
.abstract .abstract_name {
  position: absolute;
  left: 22px;
  top: -14px;
  background: #fff;
  padding: 0 12px 0 10px;
  color: #888795;
  font-size: 18px;
}
.abstract_text{
    font-size: 18px;
    line-height: 28px;
}
.abstract .bag_ar{
    display: none;
}
.previous-next{
    font-size: 18px;
    margin-top: 36px;
}
.previous-next div{
    margin: 22px 0;
}
.previous-next span{
    color: #640005;
}
.previous-next a{
    color: #727272;
    line-height: 24px;
}
.previous-next a:hover{
    color: #000;
}

.previous-next p{
    color: #727272;
    display: inline;
}


.news-content .info strong{
    font-weight: bold !important;
}



@media(max-width:1400px) {
    .learning-center-info-new .tab-content .news-li img{
        height: 230px;
    }
}
@media(max-width:1200px) {
    .learning-center-info-new .tab-content .news-li img{
        height: 200px;
    }
    .learning-center-info-new .container{
        padding: 0 8%;
    }
}
@media(max-width:996px) {
    .learning-center-info-new .tab-content .news-li{
        width: 100%;
    }
    .learning-center-info-new .tab-content .news-li img{
        height: auto;
    }
    .breadcrumb-info{
        display: block;
        /* margin-top: 92px; */
    }
    .pagination{
        float: none;
    }
    .learning-center-info-new .nav-tabs{
        padding-left: 0px !important;
        padding-right: 0px !important;
        margin-left: 3%;
        margin-right: 0%;
        width: 93%;
    }
    .learning-center-info-new .container{
        padding: 0 4%;
    }
    .news-content .info img{
        width: 100% !important;
        height: auto !important;
    }
    .news-content .info span{
        font-size: 16px !important;
    }
    .news-content .info p{
        font-size: 18px !important;
    }
    .news-content h1{
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 10px;
    }
    .news-content .time{
        font-size: 14px;
        color: #847d7d;
        display: inline-block;
    }
    .breadcrumb-info{
        display: none;
    }
    .learning-center-info-new{
        /* margin-top: 100px !important; */
    }
    .tab-pane{
        padding: 0 6% !important;
    }
    .learning-center-info-new .nav-tabs span{
        padding: 14px 20px;
        font-size: 16px;
    }
    .abstract {
        margin: 36px 0 20px 10px;
        position: relative;
        font-size: 16px;
        line-height: 24px;
        color: #8a8f99;
        padding: 0;
        border: none;
        border-radius: 0px;
    }
    .abstract .bag_ar{
        display: block;
    }
    .abstract .abstract_name {
        position: relative;
        z-index: 2;
        left: inherit;
        top: inherit;
        background: none;
        padding: 0;
        color: #888795;
        font-size: 19px;
    }
    .abstract img {
        position: absolute;
        left: -10px;
        top: -15px;
        height: 30px;
    }
    .abstract_text{
        display: initial;
    }
    .news-content .info{
        padding-top: 0px;
    }
    .previous-next {
        font-size: 16px;
    }
    .previous-next div {
        margin: 16px 0;
    }
    .previous-next a{
        line-height: 28px;
    }
    .news-content iframe{
        width: 100% !important;
        height: 230px !important;
    }
}

@media(max-width:640px) {
    .learning-center-info-new{
        padding-left: 3%;
        padding-right: 3%;
    }
}

@media(max-width:400px) {
    .pagination > li > a, .pagination > li > span{
        padding: 10px 16px;
    }
}

@media(max-width:360px) {
    .pagination > li > a, .pagination > li > span{
        padding: 8px 14px;
    }
}
</style>
